import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  BottomBar,
  Switch
} from 'uniqore-components'
import { changeBulkOrder } from 'features/BulkOrder'
import { AppState } from 'store'
import { useLogOut } from 'components/BulkOrder/LoginPage'

const InvoiceDetails: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  const {
    email,
    eInvoice,
    ovtId,
    operatorId,
    additionalInfoActive,
    additionalInfo
  } = useSelector((state: AppState) => state.bulkOrder.billing)

  const { email: personEmailAddress } = useSelector(
    (state: AppState) => state.bulkOrder.personContact
  )

  const { campaign } = useSelector((state: AppState) => state.bulkOrder)

  const [emailAddress, setEmailAddress] = useState<string>('')
  const [ovtIdValid, setOvtIdValid] = useState<string>('')
  const [operatorID, setOperatorID] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 5, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (campaign.id) {
      dispatch(changeBulkOrder({ value: 5, stateKey: 'currentView' }))
      setEmailAddress(email ? email : personEmailAddress)
    } else {
      dispatch(changeBulkOrder({ value: 1, stateKey: 'currentView' }))
      history.push('/bulkorder-select-campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, personEmailAddress, campaign])

  const goBack = () => {
    history.push('/bulkorder-contact-person')
  }

  const validateFields = () => {
    let valid = true
    if (eInvoice) {
      if (ovtId === '') {
        setOvtIdValid('Kenttä ei voi olla tyhjä.')
        valid = false
      } else {
        setOvtIdValid('')
      }
      if (operatorId === '') {
        setOperatorID('Kenttä ei voi olla tyhjä.')
        valid = false
      } else {
        setOperatorID('')
      }
    }
    if (!eInvoice) {
      if (!emailAddress.includes('@')) {
        setEmailValid('Tarkista antamasi sähköpostiosoite.')
        valid = false
      } else {
        setEmailValid('')
      }
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validateFields()) {
      dispatch(
        changeBulkOrder({
          value: emailAddress,
          stateKey: 'billing',
          stateKeyExtra: 'email'
        })
      )
      history.push('/bulkorder-summary')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
        actions={[
          {
            icon: 'ExitToApp',
            onClick: logOut
          }
        ]}
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Laskutustiedot"
            body="Laskutamme tilaukset seuraavana arkipäivänä ensisijaisesti verkkolaskuna."
          />
          <Switch
            color="primary"
            label="Haluan verkkolaskun"
            subtitles={['Muussa tapauksessa sähköpostitse']}
            checked={eInvoice}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.checked,
                  stateKey: 'billing',
                  stateKeyExtra: 'eInvoice'
                })
              )
            }
          />
          <Collapse in={eInvoice} timeout="auto" unmountOnExit>
            <>
              <TextField
                value={ovtId}
                onChange={(e) =>
                  dispatch(
                    changeBulkOrder({
                      value: e.target.value,
                      stateKey: 'billing',
                      stateKeyExtra: 'ovtId'
                    })
                  )
                }
                fullWidth
                label="Verkkolaskuosoite (OVT-tunnus)"
                errorText={ovtIdValid}
              />
              <TextField
                value={operatorId}
                onChange={(e) =>
                  dispatch(
                    changeBulkOrder({
                      value: e.target.value,
                      stateKey: 'billing',
                      stateKeyExtra: 'operatorId'
                    })
                  )
                }
                fullWidth
                label="Operaattoritunnus"
                errorText={operatorID}
              />
            </>
          </Collapse>
          <Collapse in={!eInvoice} timeout="auto" unmountOnExit>
            <TextField
              value={emailAddress}
              onChange={(e) => {
                dispatch(
                  changeBulkOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'email'
                  })
                )
                setEmailAddress(e.target.value)
              }}
              fullWidth
              label="Sähköposti"
              errorText={emailValid}
            />
          </Collapse>
          <Switch
            color="primary"
            label="Haluan lisätä lisätietoja laskutukseen"
            checked={additionalInfoActive}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.checked,
                  stateKey: 'billing',
                  stateKeyExtra: 'additionalInfoActive'
                })
              )
            }
          />
          <Collapse in={additionalInfoActive} timeout="auto" unmountOnExit>
            <TextField
              value={additionalInfo}
              onChange={(e) =>
                dispatch(
                  changeBulkOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'additionalInfo'
                  })
                )
              }
              fullWidth
              label="Lisätiedot"
              rows={4}
            />
          </Collapse>
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default InvoiceDetails
