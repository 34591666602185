import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
  component?: any
}

const isLoggedIn = () => {
  return localStorage.getItem('uq_api_token')
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /venner-login page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/venner-login" />
        )
      }
    />
  )
}

export default PrivateRoute
