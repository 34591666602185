import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppBar, Container, Heading, ListItem } from 'uniqore-components'
import { changeOrder, updateProducts } from 'features/order'
import { changeUtils } from 'features/utils'
import { Product } from 'types/types'
import { AppState } from 'store'

const Order: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [hasDynamicProducts, setHasDynamicProducts] = useState<boolean>(false)
  const { products } = useSelector((state: AppState) => state.airtableData)
  const {
    currentView,
    newOrder,
    personContactFirstName,
    personContactLastName,
    personContactEmail,
    personContactPhone,
    companyName,
    companyBusinessId,
    companyAddress,
    companyZip,
    companyCity,
    companyOvtId,
    companyOperatorId
  } = useSelector((state: AppState) => state.utils)

  useEffect(() => {
    if (hasDynamicProducts) {
      window.scrollTo(0, 0)
      dispatch(changeUtils({ value: 1, stateKey: 'currentView' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDynamicProducts])

  // Check if there are dynamic products and if they are active
  useEffect(() => {
    let isDynamic = false
    let isActive = false
    products.forEach((product) => {
      if (product.isDynamic) {
        isDynamic = true
        if (product.isActive) {
          isActive = true
        }
      }
    })
    setHasDynamicProducts(isDynamic)
    if (!isDynamic || !isActive) {
      // automatically changes view if airtable products-table does not have dynamic or active products
      changeView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  const changeView = () => {
    if (currentView === 0) {
      goForward()
    }
    if (currentView === 1) {
      goBack()
    }
  }

  const goBack = () => {
    dispatch(changeUtils({ value: 0, stateKey: 'currentView' }))
    if (newOrder) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  const goForward = () => {
    dispatch(changeUtils({ value: 2, stateKey: 'currentView' }))
    history.push('/order-delivery-method')
  }

  // Fill company and personal details if creating a new order
  useEffect(() => {
    if (newOrder) {
      dispatch(
        changeOrder({
          value: companyName,
          stateKey: 'companyContact',
          stateKeyExtra: 'companyName'
        })
      )
      dispatch(
        changeOrder({
          value: companyBusinessId,
          stateKey: 'companyContact',
          stateKeyExtra: 'businessId'
        })
      )
      dispatch(
        changeOrder({
          value: companyAddress,
          stateKey: 'companyContact',
          stateKeyExtra: 'address'
        })
      )
      dispatch(
        changeOrder({
          value: companyZip,
          stateKey: 'companyContact',
          stateKeyExtra: 'zip'
        })
      )
      dispatch(
        changeOrder({
          value: companyCity,
          stateKey: 'companyContact',
          stateKeyExtra: 'city'
        })
      )
      dispatch(
        changeOrder({
          value: personContactFirstName,
          stateKey: 'personContact',
          stateKeyExtra: 'firstName'
        })
      )
      dispatch(
        changeOrder({
          value: personContactLastName,
          stateKey: 'personContact',
          stateKeyExtra: 'lastName'
        })
      )
      dispatch(
        changeOrder({
          value: personContactEmail,
          stateKey: 'personContact',
          stateKeyExtra: 'email'
        })
      )
      dispatch(
        changeOrder({
          value: personContactPhone,
          stateKey: 'personContact',
          stateKeyExtra: 'phone'
        })
      )
      dispatch(
        changeOrder({
          value: companyOvtId,
          stateKey: 'billing',
          stateKeyExtra: 'ovtId'
        })
      )
      dispatch(
        changeOrder({
          value: companyOperatorId,
          stateKey: 'billing',
          stateKeyExtra: 'operatorId'
        })
      )
    }
  })

  const handleSelect = (isSeasonBox: boolean, sku?: string) => {
    if (isSeasonBox) {
      const seasonBoxes = products.filter((product) => !product.isDynamic)
      if (seasonBoxes) {
        dispatch(updateProducts({ products: seasonBoxes }))
        dispatch(changeOrder({ value: false, stateKey: 'isDynamicProduct' }))
        goForward()
      }
    } else {
      const findDynamicProduct = products.find((product) => product.sku === sku)
      if (findDynamicProduct) {
        dispatch(updateProducts({ products: [findDynamicProduct] }))
        dispatch(changeOrder({ value: true, stateKey: 'isDynamicProduct' }))
        goForward()
      }
    }
  }

  return (
    <>
      {hasDynamicProducts && (
        <Container>
          <AppBar
            text="Takaisin"
            mainAction={{ icon: 'ArrowBack', onClick: goBack }}
          />
          <Heading title="Valitse tuote" />
          <ListItem
            image={
              products.find((p) => p.sku === 'ruokalaatikko-norm')?.image || ''
            }
            text="Sesonkiruokalaatikko & Venner-laatikko"
            subtitles={[
              'Sesonkiruokalaatikko on täynnä kauden ravitsevia raaka-aineita resepteineen. Jokainen ostettu Sesonkilaatikko mahdollistaa kolme ravitsevaa ateriaa vähävaraiselle perheelle.',
              '',
              'Vähävaraisille perheille kotiin toimitettava, tutkitusti vaikuttava Venner-ruokalaatikko on täynnä hyvää, ravitsevaa ruokaa sekä uusia taitoja! Jokainen ostettu Venner-ruokalaatikko sisältää jopa 42 ateria-annosta vähävaraisille perheille.'
            ]}
            onClick={() => handleSelect(true)}
            metaIcon="ArrowForward"
            hightlightMeta
            dense
          />
          {products.map((product: Product) =>
            product.isDynamic && product.isActive ? (
              <ListItem
                key={product.id}
                image={product.image}
                text={product.text}
                subtitles={product.subtitles}
                onClick={() => handleSelect(false, product.sku)}
                metaIcon="ArrowForward"
                hightlightMeta
                dense
              />
            ) : null
          )}
        </Container>
      )}
    </>
  )
}

export default Order
