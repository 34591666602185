import { createSlice } from '@reduxjs/toolkit'
import { Product } from 'types/types'

export interface RedeemContactDetails {
  firstName: string
  lastName: string
  phone: string
  email: string
  mailingList: boolean
}

export interface RedeemDeliveryDetails {
  recipient: string
  address: string
  zip: string
  city: string
  notes: string
}

export interface Redeem {
  code: string
  engagementId: string
  redeemId: string
  boxType: string
  boxTypeForEmail: string
  redeemedProduct: string
  productGtin: string
  productSku: string
  contactDetails: RedeemContactDetails
  deliveryDetails: RedeemDeliveryDetails
  deliveryDate: string
  delivery: {
    method: string
    startTime: string
    endTime: string
    schenkerDeliveryDate: string
  }
  codeRedeemed: Date | string
  currentView: number
  isDynamicProduct: boolean
  dynamicProduct?: Product
}

interface ChangeRedeemAction {
  payload: {
    value: number | string | boolean
    stateKey: keyof Redeem
    stateKeyExtra?: string
  }
}

interface AddDynamicProduct {
  payload: {
    dynamicProduct: Product
  }
}

const initialState: Redeem = {
  code: '',
  engagementId: '',
  redeemId: '',
  boxType: '',
  boxTypeForEmail: '',
  redeemedProduct: '',
  productGtin: '',
  productSku: '',
  contactDetails: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    mailingList: false
  },
  deliveryDetails: {
    recipient: '',
    address: '',
    zip: '',
    city: '',
    notes: ''
  },
  deliveryDate: '',
  delivery: {
    method: '',
    startTime: '',
    endTime: '',
    schenkerDeliveryDate: ''
  },
  codeRedeemed: '',
  currentView: 0,
  isDynamicProduct: false
}

const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    changeRedeem: (state, { payload }: ChangeRedeemAction) => {
      if (payload.stateKeyExtra) {
        // @ts-ignore
        state[payload.stateKey][payload.stateKeyExtra] = payload.value
      } else {
        // @ts-ignore
        state[payload.stateKey] = payload.value
      }
    },
    addDynamicProduct: (state, { payload }: AddDynamicProduct) => {
      state.dynamicProduct = payload.dynamicProduct
    }
  }
})

export const { changeRedeem, addDynamicProduct } = redeemSlice.actions
export default redeemSlice.reducer
