import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: 'relative',
    zIndex: theme.zIndex.tooltip + 1
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip
  }
}))

const SpinnerMui: React.FC<{
  color?: string
  size?: string | number
  backdrop?: boolean
  style?: React.CSSProperties
  className?: string
}> = ({
  color = 'rgba(0, 0, 0, 0.87)',
  size = 40,
  backdrop = false,
  style,
  className
}) => {
  const classes = useStyles()

  return (
    <div style={style} className={className}>
      <CircularProgress
        style={{ color }}
        size={size}
        className={classes.spinner}
      />
      {backdrop && <Backdrop className={classes.backdrop} open />}
    </div>
  )
}

export default SpinnerMui
