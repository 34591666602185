import { combineReducers, configureStore } from '@reduxjs/toolkit'
import orderSlice from 'features/order'
import ordersSlice from 'features/orders'
import redeemSlice from 'features/redeem'
import campaignRedeemSlice from 'features/campaignRedeem'
import campaignAirtableDataSlice from 'features/campaignsAirtableData'
import airtableDataSlice from 'features/airtableData'
import utilsSlice from 'features/utils'
import bulkOrderSlice from 'features/BulkOrder'

const rootReducer = combineReducers({
  redeem: redeemSlice,
  campaignRedeem: campaignRedeemSlice,
  campaignAirtableData: campaignAirtableDataSlice,
  order: orderSlice,
  orders: ordersSlice,
  bulkOrder: bulkOrderSlice,
  airtableData: airtableDataSlice,
  utils: utilsSlice
})

const store = configureStore({
  reducer: rootReducer
})

export type AppState = ReturnType<typeof store.getState>
export default store
