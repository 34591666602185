import React from 'react'
import { useDispatch } from 'react-redux'
import { default as MaterialSnackbar } from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { changeUtils } from 'features/utils'

const Snackbar: React.FC<{
  snackbarOpen: boolean
  text: string
}> = ({ snackbarOpen, text }) => {
  const dispatch = useDispatch()

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(changeUtils({ value: false, stateKey: 'snackbarOpen' }))
  }

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      message={text}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

export default Snackbar
