import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  BottomBar
} from 'uniqore-components'
import { changeBulkOrder } from 'features/BulkOrder'
import { useLogOut } from 'components/BulkOrder/LoginPage'
import { AppState } from 'store'

const CompanyDetails: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  const { businessId, companyName, address, zip, city } = useSelector(
    (state: AppState) => state.bulkOrder.companyContact
  )

  const { campaign } = useSelector((state: AppState) => state.bulkOrder)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 3, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!campaign.id) {
      history.push('/bulkorder-select-campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const [companyNameValid, setCompanyNameValid] = useState<string>('')
  const [businessIdValid, setBusinessIdValid] = useState<string>('')
  const [addressValid, setAddressValid] = useState<string>('')
  const [zipValid, setZipValid] = useState<string>('')
  const [cityValid, setCityValid] = useState<string>('')

  const goBack = () => {
    history.push('/bulkorder-select-email-list')
  }

  const validateFields = () => {
    let valid = true
    if (businessId.length < 7) {
      setBusinessIdValid('Tarkista antamasi Y-tunnus.')
      valid = false
    } else {
      setBusinessIdValid('')
    }
    if (companyName === '') {
      setCompanyNameValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setCompanyNameValid('')
    }
    if (address === '') {
      setAddressValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setAddressValid('')
    }
    if (zip.length !== 5) {
      setZipValid(
        'Postiosoitteen tulee olla 5 merkkiä pitkä, huomio tyhjät välilyönnit alussa tai lopussa.'
      )
      valid = false
    } else {
      setZipValid('')
    }
    if (city === '') {
      setCityValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setCityValid('')
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validateFields()) {
      history.push('/bulkorder-contact-person')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
        actions={[
          {
            icon: 'ExitToApp',
            onClick: logOut
          }
        ]}
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Yrityksen yhteystiedot"
            body="Lisää tilaavan yrityksen yhteystiedot"
          />
          <TextField
            value={companyName}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'companyName'
                })
              )
            }
            fullWidth
            label="Yrityksen nimi"
            errorText={companyNameValid}
          />
          <TextField
            value={businessId}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'businessId'
                })
              )
            }
            fullWidth
            label="Y-tunnus"
            errorText={businessIdValid}
          />
          <TextField
            name="ship-address"
            autoComplete="shipping street-address"
            value={address}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'address'
                })
              )
            }
            fullWidth
            label="Osoite"
            errorText={addressValid}
          />
          <TextField
            name="ship-zip"
            autoComplete="shipping postal-code"
            value={zip}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value.trim(),
                  stateKey: 'companyContact',
                  stateKeyExtra: 'zip'
                })
              )
            }
            fullWidth
            label="Postinumero"
            errorText={zipValid}
          />
          <TextField
            name="ship-city"
            autoComplete="shipping locality"
            value={city}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value.trim(),
                  stateKey: 'companyContact',
                  stateKeyExtra: 'city'
                })
              )
            }
            fullWidth
            label="Kaupunki"
            errorText={cityValid}
          />
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default CompanyDetails
