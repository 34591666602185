import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Container,
  Heading,
  i18n,
  BottomBar,
  Button,
  Dialog
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { formatISO, format, addDays } from 'date-fns'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { getDeliveryDates } from 'utils/common'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/types'

const searchZip = gql`
  query searchZip($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

const RedeemContact: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    delivery,
    language,
    redemptionCode,
    deliveryDate,
    deliveryDetails,
    selectedProduct,
    redeemSelectableWeeksCount,
    daysAheadBuffer,
    daysAheadBufferSeasonBox,
    deliveryValidTo,
    deliveryValidToSeasonBox
  } = useSelector((state: AppState) => state.campaignRedeem)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openMatkahuoltoDialog, setOpenMatkahuoltoDialog] =
    useState<boolean>(false)
  const [dates, setDates] = useState<string[]>([])
  const [deliveryDateSelected, setDeliveryDateSelected] =
    useState<boolean>(false)
  const [zip, setZip] = useState<string>('')
  const [fetchZipData, { data, loading, error }] = useLazyQuery(searchZip, {
    variables: {
      id: process.env.REACT_APP_SEARCH_ZIP_ID,
      parameters: { zip },
      fetchPolicy: 'network-only'
    }
  })

  // When this page renders, remove deliveryDate from campaignRedeem-reducer
  // to prevent any wrong delivery dates for different products
  useEffect(() => {
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'deliveryDate'
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      i18n.changeLanguage(language)
      dispatch(changeCampaignRedeem({ value: 5, stateKey: 'currentView' })) //TODO: DISCONTINUED CONTRY SELECTION, CHANGE VALUE TO 6 IF COUNTRY SELECTION IS RETURNED
    }
  }, [language, redemptionCode, history, dispatch])

  // set zip code
  useEffect(() => {
    const trimZip = deliveryDetails.zip.trim()
    setZip(trimZip)
  }, [deliveryDetails.zip])

  // when zip is set, useLazyQuery
  useEffect(() => {
    if (zip) {
      fetchZipData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip])

  useEffect(() => {
    if (delivery.method) {
      // Matkahuolto replaces DbSchenkerB2C but we have no reason to remove DbSchenkerB2C
      if (
        delivery.method === 'DbSchenkerB2C' ||
        delivery.method === 'Matkahuolto'
      ) {
        setOpenMatkahuoltoDialog(true)
      }
      // If no dates found, show dialog -> Venner contacts redeemer about the dates
      if (delivery.method === 'none') {
        setOpenDialog(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery.method])

  // set dates
  useEffect(() => {
    if (data) {
      let getDates: string[] = []
      if (data.fetchDataview.data.records.length !== 0) {
        const fields = data.fetchDataview.data.records[0].fields
        getDates = getDeliveryDates(
          fields.HomeDeliveryDay,
          selectedProduct === 'Sesonkiruokalaatikko' ||
            selectedProduct === 'Seasonal Box'
            ? daysAheadBufferSeasonBox
            : daysAheadBuffer,
          redeemSelectableWeeksCount,
          fields.HomeDeliveryTime,
          // getDeliveryDates-function requires Product as its 'dynamicProduct' parameter.
          // this is why 'deliveryValidTo' -value is given as Product-type
          selectedProduct === 'Sesonkiruokalaatikko' ||
            selectedProduct === 'Seasonal Box'
            ? ({
                deliveryValidTo: deliveryValidToSeasonBox || undefined
              } as Product)
            : ({ deliveryValidTo: deliveryValidTo } as Product)
        )
      }
      setDates(getDates)
      // Check if the delivery date has already been selected, otherwise stop the user from going forward
      getDates.forEach(
        (date) => date === deliveryDate && setDeliveryDateSelected(true)
      )
      // if no dates found, show dialog -> Venner contacts redeemer about the dates
      if (getDates.length < 1) {
        setOpenDialog(true)
      } else {
        dispatch(
          changeCampaignRedeem({
            value: data.fetchDataview.data.records[0].fields.HomeDeliveryMethod,
            stateKey: 'delivery',
            stateKeyExtra: 'method'
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formatDeliveryDate = (deliveryDate: string) => {
    const dateAndTime = deliveryDate.split(' klo ')
    const day: number = Number(dateAndTime[0].split('.')[0])
    const month: number = Number(dateAndTime[0].split('.')[1]) - 1
    const year: number = Number(dateAndTime[0].split('.')[2])

    const startAndEnd = dateAndTime[1].split('-')
    const start = startAndEnd[0].split(':')
    const end = startAndEnd[1].split(':')

    const startTime = formatISO(
      new Date(year, month, day, Number(start[0]), 0, 0)
    )
    const endTime = formatISO(new Date(year, month, day, Number(end[0]), 0, 0))
    return { startTime, endTime }
  }

  const getSchenkerDate = (deliveryDate: string, method: string) => {
    // Matkahuolto replaces DbSchenkerB2C but we have no reason to remove DbSchenkerB2C
    if (
      method === 'DbSchenkerB2C' ||
      method === 'Matkahuolto' ||
      method === 'DbSchenker'
    ) {
      const dateAndTime = deliveryDate.split(' klo ')
      const day: number = Number(dateAndTime[0].split('.')[0])
      const month: number = Number(dateAndTime[0].split('.')[1]) - 1
      const year: number = Number(dateAndTime[0].split('.')[2])
      return format(addDays(new Date(year, month, day), -1), 'yyyy-MM-dd')
    } else {
      return ''
    }
  }

  const goBack = () => {
    dispatch(changeCampaignRedeem({ value: 3, stateKey: 'currentView' }))
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'method'
      })
    )
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'startTime'
      })
    )
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'endTime'
      })
    )
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'schenkerDeliveryDate'
      })
    )
    history.push('/campaign-delivery-information')
  }

  const goForward = (fromDialog: boolean) => {
    if (fromDialog) {
      dispatch(
        changeCampaignRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'method'
        })
      )
      dispatch(
        changeCampaignRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'startTime'
        })
      )
      dispatch(
        changeCampaignRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'endTime'
        })
      )
      dispatch(
        changeCampaignRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'schenkerDeliveryDate'
        })
      )
      dispatch(
        changeCampaignRedeem({
          value: 'Olemme sinuun yhteydessä',
          stateKey: 'deliveryDate'
        })
      )
      dispatch(changeCampaignRedeem({ value: 7, stateKey: 'currentView' }))
      history.push('/campaign-redeem-summary')
    } else {
      if (deliveryDate && deliveryDateSelected) {
        const { startTime, endTime } = formatDeliveryDate(deliveryDate)
        dispatch(
          changeCampaignRedeem({
            value: startTime,
            stateKey: 'delivery',
            stateKeyExtra: 'startTime'
          })
        )
        dispatch(
          changeCampaignRedeem({
            value: endTime,
            stateKey: 'delivery',
            stateKeyExtra: 'endTime'
          })
        )
        dispatch(
          changeCampaignRedeem({
            value: getSchenkerDate(deliveryDate, delivery.method),
            stateKey: 'delivery',
            stateKeyExtra: 'schenkerDeliveryDate'
          })
        )
        dispatch(changeCampaignRedeem({ value: 7, stateKey: 'currentView' }))
        history.push('/campaign-redeem-summary')
      }
    }
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        />
      )}
      <Container>
        <AppBar
          mainAction={{
            icon: 'ArrowBack',
            onClick: goBack
          }}
          text={t('button-back')}
        />
        <div style={{ marginBottom: 120 }}>
          <Heading
            title={t('delivery-date-title')}
            body={
              !loading && delivery.method === 'Collico'
                ? t('delivery-date-subtitle')
                : ''
            }
          />
          {dates.map((date, i) => {
            return (
              <Button
                key={i}
                checked={deliveryDate === date}
                customColor={{
                  color: deliveryDate === date ? 'black' : '',
                  backgroundColor: deliveryDate === date ? '#CEE3DF' : ''
                }}
                role="radio"
                label={
                  i18n.language === 'fi' ? date : date.replace('klo', 'at')
                }
                value={date}
                onClick={() => {
                  dispatch(
                    changeCampaignRedeem({
                      value: date,
                      stateKey: 'deliveryDate'
                    })
                  )
                  setDeliveryDateSelected(true)
                }}
              />
            )
          })}
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                variant="default"
                border
                disablePadding
                fullWidth
                label={t('button-previous')}
                onClick={goBack}
              />,
              <Button
                disabled={deliveryDate === ''}
                color="primary"
                disablePadding
                fullWidth
                label={t('button-forward')}
                onClick={() => goForward(false)}
              />
            ]}
          />
        </div>
      </Container>
      {/* Dialog if no delivery dates found */}
      <Dialog
        open={openDialog}
        onClose={() => {}}
        title={
          i18n.language === 'fi'
            ? `Huomio tilauksestasi, postinumerolla ${zip}`
            : `Attention to your order, zip code ${zip}`
        }
        //title={t('no-delivery-date-title')}
        subtitle={
          i18n.language === 'fi'
            ? `Olemme sinuun henkilökohtaisesti yhteyksissä tarkemman toimituspäivän osalta. Voit viedä tilauksen normaalisti loppuun. Tarkistathan vielä, että syöttämäsi postinumero on varmasti oikein. `
            : `We will contact you personally for a more specific delivery date. You can complete your order normally. Please make sure that the zip code you entered is correct.`
        }
        //subtitle={t('no-delivery-date-subtitle')}
        actionComponents={[
          <Button
            label={t('button-previous')}
            variant="text"
            disablePadding
            border
            onClick={goBack}
          />,
          <Button
            label={t('button-forward')}
            color="primary"
            disablePadding
            onClick={() => goForward(true)}
          />
        ]}
      />
      {/* Dialog if delivery method is Matkahuolto (home delivery) */}
      <Dialog
        open={openMatkahuoltoDialog}
        onClose={() => setOpenMatkahuoltoDialog(false)}
        title={t('matkahuolto-delivery-date-title')}
        subtitle={t('matkahuolto-delivery-date-subtitle')}
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenMatkahuoltoDialog(false)}
          />
        ]}
      />
      {/* Dialog if any error occurs */}
      {error && (
        <Dialog
          open={true}
          onClose={() => {}}
          title="Huomio tilauksestasi"
          subtitle="Jokin meni toimituspäivän haussa pieleen. Palaa takaisin ja yritä uudelleen."
          actionComponents={[
            <Button
              label="Takaisin"
              color="primary"
              disablePadding
              onClick={goBack}
            />
          ]}
        />
      )}
    </>
  )
}

export default RedeemContact
