import { createTheme, ThemeOptions } from '@material-ui/core/styles'
import { defaultTheme } from 'uniqore-components'

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#FFCA0F'
    },
    secondary: {
      //main: '#CEE3DF'
      main: '#525252'
    }
  },
  typography: {
    ...defaultTheme.typography
  }
} as ThemeOptions)

export default theme
