import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Onboarding, Button } from 'uniqore-components'
import { changeRedeem } from 'features/redeem'
import VennerLogo from 'assets/venner-logo.svg'
import Receipt from 'assets/receipt.svg'
import Nature from 'assets/nature.svg'
import { AppState } from 'store'

const RedeemOnboarding: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { code, isDynamicProduct, dynamicProduct } = useSelector(
    (state: AppState) => state.redeem
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 0, stateKey: 'currentView' }))
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goForward = () => {
    dispatch(changeRedeem({ value: 1, stateKey: 'currentView' }))
    history.push('/redeem-product')
  }

  return (
    <Onboarding
      screens={
        isDynamicProduct && dynamicProduct
          ? [
              {
                image: VennerLogo,
                title: 'Syödään ja tehdään hyvää!',
                subtitle: `Lunastaessasi Venner tuotteita, mahdollistat ravitsevia ruoka-annoksia vähävaraisille perheille.`
              }
            ]
          : [
              {
                image: VennerLogo,
                title: 'Syödään ja tehdään hyvää!',
                subtitle:
                  'Lunastaessasi Sesonkiruokalaatikon mahdollistat ravitsevia ruoka-annoksia vähävaraisille perheille.'
              },
              {
                image: Receipt,
                title: 'Uusia herkullisia reseptejä',
                subtitle:
                  'Helppojen reseptien avulla opit tekemään ympäristöystävällistä, herkullista ja kauden mukaista kasvisruokaa.'
              },
              {
                image: Nature,
                title: 'Huomioidaan kestävä kehitys',
                subtitle:
                  'Käytät raaka-aineet kokonaan, sillä viimeinen resepti muodostuu kekseliäästi muiden reseptien hävikkiaineksista.'
              }
            ]
      }
      actionComponents={[
        <Button
          fullWidth
          label="Eteenpäin"
          color="primary"
          disablePadding
          onClick={goForward}
        />
      ]}
      showIndicators={!isDynamicProduct}
    />
  )
}

export default RedeemOnboarding
