import { createSlice } from '@reduxjs/toolkit'
import { PersonContact, CompanyContact } from 'features/order'
import { Product } from 'types/types'

export interface Campaign {
  id: string
  name: string
  expirationDate: string
  url: string
  emailLogoUrl: string
  emailCompanyName: string
  emailTitleFi: string
  emailTitleEn: string
  emailContentFi: string
  emailContentEn: string
  foreignDeliveryDate: string
  deliveryValidTo: string
  deliveryValidToSeasonBox: string
  daysAheadBuffer?: number
  daysAheadBufferSeasonBox?: number
  selectableWeeksCount?: number
  products: Product[]
}

export interface SelectedCampaigns {
  id: string
  fields: {
    Name: string
    'Slug (url)': string
    Products: string[]
    'Name (from Products)': string[]
    'Type (from Products)': string[]
    'Redeem Expiration Date': string
    'Email-company-name': string
    'Email-logo': any[]
    'Email-title-FI': string
    'Email-title-EN': string
    'Email-content-FI': string
    'Email-content-EN': string
    'Foreign-delivery-date': string
    'Delivery Valid to'?: string
    'Delivery Valid to-sesonki'?: string
    DaysAheadBuffer?: number
    'DaysAheadBuffer-sesonki'?: number
    SelectableWeeksCount?: number
  }
  createdTime: string
}

export interface BulkOrderState {
  token: string
  currentView: number
  companyContact: CompanyContact
  personContact: PersonContact
  billing: {
    email: string
    eInvoice: boolean
    ovtId: string
    operatorId: string
    additionalInfoActive: boolean
    additionalInfo: string
  }
  campaign: Campaign
  emailList: string
  selectedCampaignID: string
  selectedCampaign: SelectedCampaigns
}

const initialState: BulkOrderState = {
  token: '',
  currentView: 0,
  companyContact: {
    companyName: '',
    businessId: '',
    address: '',
    zip: '',
    city: '',
    deliveryAddress: true
  },
  personContact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mailingList: false
  },
  billing: {
    email: '',
    eInvoice: true,
    ovtId: '',
    operatorId: '',
    additionalInfoActive: false,
    additionalInfo: ''
  },
  campaign: {
    id: '',
    name: '',
    expirationDate: '',
    url: '',
    emailLogoUrl: '',
    emailCompanyName: '',
    emailTitleFi: '',
    emailTitleEn: '',
    emailContentFi: '',
    emailContentEn: '',
    foreignDeliveryDate: '',
    deliveryValidTo: '',
    deliveryValidToSeasonBox: '',
    daysAheadBuffer: 0,
    daysAheadBufferSeasonBox: 0,
    products: []
  },
  emailList: '',
  selectedCampaignID: '',
  selectedCampaign: {
    id: '',
    fields: {
      Name: '',
      'Slug (url)': '',
      Products: [],
      'Name (from Products)': [],
      'Type (from Products)': [],
      'Redeem Expiration Date': '',
      'Email-company-name': '',
      'Email-logo': [],
      'Email-title-FI': '',
      'Email-title-EN': '',
      'Email-content-FI': '',
      'Email-content-EN': '',
      'Foreign-delivery-date': '',
      'Delivery Valid to': '',
      'Delivery Valid to-sesonki': '',
      'DaysAheadBuffer-sesonki': 0,
      DaysAheadBuffer: 0,
      SelectableWeeksCount: 0
    },
    createdTime: ''
  }
}

interface ChangeBulkOrderAction {
  payload: {
    value: number | string | boolean | Campaign | SelectedCampaigns
    stateKey: keyof BulkOrderState
    stateKeyExtra?: string
  }
}

const BulkOrderSlice = createSlice({
  name: 'BulkOrder',
  initialState,
  reducers: {
    changeBulkOrder: (state, { payload }: ChangeBulkOrderAction) => {
      if (payload.stateKeyExtra) {
        // @ts-ignore
        state[payload.stateKey][payload.stateKeyExtra] = payload.value
      } else {
        // @ts-ignore
        state[payload.stateKey] = payload.value
      }
    },
    resetBulkOrder: () => {
      return initialState
    }
  }
})

export const { changeBulkOrder, resetBulkOrder } = BulkOrderSlice.actions
export default BulkOrderSlice.reducer
