import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  Switch,
  BottomBar
} from 'uniqore-components'
import { changeOrder } from 'features/order'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'

const OrderBilling: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { billing, personContact, orderType } = useSelector(
    (state: AppState) => state.order
  )
  const [ovtIdValid, setOvtIdValid] = useState<string>('')
  const [operatorId, setOperatorId] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 8, stateKey: 'currentView' }))
    } else {
      dispatch(changeUtils({ value: 7, stateKey: 'currentView' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateFields = () => {
    let valid = true
    if (billing.eInvoice) {
      if (billing.ovtId === '') {
        setOvtIdValid('Kenttä ei voi olla tyhjä')
        valid = false
      } else {
        setOvtIdValid('')
      }
      if (billing.operatorId === '') {
        setOperatorId('Kenttä ei voi olla tyhjä')
        valid = false
      } else {
        setOperatorId('')
      }
    }
    if (!billing.eInvoice) {
      if (!billing.email.includes('@')) {
        setEmailValid('Tarkista antamasi sähköpostiosoite')
        valid = false
      } else {
        setEmailValid('')
      }
    }
    return valid
  }

  // if billing email not yet set, set it as the contact person's email
  useEffect(() => {
    if (billing.email === '') {
      dispatch(
        changeOrder({
          value: personContact.email,
          stateKey: 'billing',
          stateKeyExtra: 'email'
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 7, stateKey: 'currentView' }))
    } else {
      dispatch(changeUtils({ value: 6, stateKey: 'currentView' }))
    }
    history.push('/order-contact-person')
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      if (orderType === 'Cargo') {
        dispatch(changeUtils({ value: 9, stateKey: 'currentView' }))
      } else {
        dispatch(changeUtils({ value: 8, stateKey: 'currentView' }))
      }
      history.push('/order-summary')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Laskutustiedot"
            body="Laskutamme tilaukset seuraavana arkipäivänä ensisijaisesti verkkolaskuna."
          />
          <Switch
            color="primary"
            label="Haluan verkkolaskun"
            subtitles={['Muussa tapauksessa sähköpostitse']}
            checked={billing.eInvoice}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.checked,
                  stateKey: 'billing',
                  stateKeyExtra: 'eInvoice'
                })
              )
            }
          />
          <Collapse in={billing.eInvoice} timeout="auto" unmountOnExit>
            <TextField
              value={billing.ovtId}
              onChange={(e) =>
                dispatch(
                  changeOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'ovtId'
                  })
                )
              }
              fullWidth
              label="Verkkolaskutusosoite (OVT-tunnus)"
              errorText={ovtIdValid}
            />
            <TextField
              value={billing.operatorId}
              onChange={(e) =>
                dispatch(
                  changeOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'operatorId'
                  })
                )
              }
              fullWidth
              label="Operaattoritunnus"
              errorText={operatorId}
            />
          </Collapse>
          <Collapse in={!billing.eInvoice} timeout="auto" unmountOnExit>
            <TextField
              value={billing.email}
              onChange={(e) =>
                dispatch(
                  changeOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'email'
                  })
                )
              }
              fullWidth
              label="Sähköposti"
              errorText={emailValid}
            />
          </Collapse>
          <Switch
            color="primary"
            label="Haluan lisätä lisätietoja laskutukseen"
            checked={billing.additionalInfoActive}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.checked,
                  stateKey: 'billing',
                  stateKeyExtra: 'additionalInfoActive'
                })
              )
            }
          />
          <Collapse
            in={billing.additionalInfoActive}
            timeout="auto"
            unmountOnExit
          >
            <TextField
              value={billing.additionalInfo}
              onChange={(e) =>
                dispatch(
                  changeOrder({
                    value: e.target.value,
                    stateKey: 'billing',
                    stateKeyExtra: 'additionalInfo'
                  })
                )
              }
              fullWidth
              label="Lisätiedot"
              rows={4}
            />
          </Collapse>
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default OrderBilling
