import { Whitelabel } from 'uniqore-components'

const Layout: React.FunctionComponent = (props) => {
  return (
    <>
      {props.children}
      <Whitelabel
        style={{ position: 'absolute', bottom: 0, width: '100%', left: '0' }}
      />
    </>
  )
}

export default Layout
