import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  Switch,
  BottomBar
} from 'uniqore-components'
import { changeRedeem } from 'features/redeem'
import { AppState } from 'store'
import { validateEmail } from 'api/cloudFunctions'

const RedeemContact: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { code } = useSelector((state: AppState) => state.redeem)
  const { fromSummary } = useSelector((state: AppState) => state.utils)
  const { firstName, lastName, phone, email, mailingList } = useSelector(
    (state: AppState) => state.redeem.contactDetails
  )
  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setPhoneValid('')
    }
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 2, stateKey: 'currentView' }))
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (fromSummary) {
      dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
      history.push('/redeem-summary')
    } else {
      dispatch(changeRedeem({ value: 1, stateKey: 'currentView' }))
      history.push('/redeem-product')
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      if (fromSummary) {
        dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
        history.push('/redeem-summary')
      } else {
        dispatch(changeRedeem({ value: 3, stateKey: 'currentView' }))
        history.push('/redeem-delivery')
      }
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text={fromSummary ? 'Yhteenveto' : 'Ruokalaatikon valinta'}
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading title="Yhteystietosi" />
          <TextField
            name="fname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) =>
              dispatch(
                changeRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'firstName'
                })
              )
            }
            fullWidth
            label="Etunimi"
            errorText={firstNameValid}
          />
          <TextField
            name="lname"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) =>
              dispatch(
                changeRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'lastName'
                })
              )
            }
            fullWidth
            label="Sukunimi"
            errorText={lastNameValid}
          />
          <TextField
            type="tel"
            name="phone"
            autoComplete="tel"
            value={phone}
            onChange={(e) =>
              dispatch(
                changeRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'phone'
                })
              )
            }
            fullWidth
            label="Puhelinnumero"
            errorText={phoneValid}
          />
          <TextField
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) =>
              dispatch(
                changeRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'email'
                })
              )
            }
            fullWidth
            label="Sähköposti"
            errorText={emailValid}
          />
          <Switch
            color="primary"
            label="Haluan liittyä Vennerin sähköpostilistalle"
            checked={mailingList}
            onChange={(e) =>
              dispatch(
                changeRedeem({
                  value: e.target.checked,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'mailingList'
                })
              )
            }
          />
        </div>
        {fromSummary ? (
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                color="primary"
                disablePadding
                fullWidth
                label="Yhteenvetoon"
                type="submit"
              />
            ]}
          />
        ) : (
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                variant="default"
                border
                disablePadding
                fullWidth
                label="Edellinen"
                onClick={goBack}
              />,
              <Button
                color="primary"
                disablePadding
                fullWidth
                label="Seuraava"
                type="submit"
              />
            ]}
          />
        )}
      </form>
    </Container>
  )
}

export default RedeemContact
