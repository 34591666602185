import React, { useState, useEffect } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import {
  Login,
  TextField,
  Button,
  Container,
  ErrorPage,
  i18n
} from 'uniqore-components'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import SpinnerMui from 'components/Common/SpinnerMui'
import { ReactComponent as VennerLogo } from 'assets/venner-logo.svg'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/types'
import { checkBulkOrders } from 'api/cloudFunctions'
import { defaultDaysAheadBuffer } from 'utils/common'

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 700px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 700px)': {
      height: 'calc(100vh - 56px)'
    }
  },
  languageBar: {
    position: 'absolute',
    width: '100%',
    padding: '24px',
    textAlign: 'right',
    '& a': {
      margin: '3px'
    }
  }
}))

const searchCampaign = gql`
  query searchCampaign($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

const serviceEngagement = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        key
        value
      }
    }
  }
`

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const formResultQuery = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      result
    }
  }
`

const RedeemCode: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { products } = useSelector((state: AppState) => state.airtableData)
  const { redemptionEmail } = useSelector(
    (state: AppState) => state.campaignRedeem
  )
  const [serviceEngagementError, setServiceEngagementError] =
    useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [redemptionCodeValid, setRedemptionCodeValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')
  const [campaignId, setCampaignId] = useState<string>('')
  const [checkingCode, setCheckingCode] = useState<boolean>(false)
  // Products that are found in the order engagement metadata
  const [orderProducts, setOrderProducts] = useState<Product[]>()

  // Check engagement status
  const [
    resultCall,
    { data, loading: queryLoading, error: queryError, stopPolling }
  ] = useLazyQuery(formResultQuery, {
    pollInterval: 500
  })
  const [checkRedeemFormCall, { loading: mutationLoading }] = useMutation(
    formQuery,
    {
      onCompleted(mutationResult) {
        if (mutationResult && !mutationResult.ingestForm.errors) {
          resultCall({ variables: { id: mutationResult.ingestForm.result } })
        } else {
          setErrorText(
            i18n.language === 'fi' ? 'Väärä lahjakoodi' : 'Wrong gift card code'
          )
        }
      },
      onError(mutationError) {
        if (mutationError) {
          setError(true)
        }
      }
    }
  )

  // Fetch campaign
  const [
    findCampaign,
    { data: campaignsData, loading: campaignsLoading, error: campaignsError }
  ] = useLazyQuery(searchCampaign, {
    variables: {
      id: process.env.REACT_APP_SEARCH_CAMPAIGN,
      parameters: { campaignId }
    },
    fetchPolicy: 'network-only'
  })

  // Fetch service engagement, id in url
  const {
    data: seData,
    loading: seLoading,
    error: seError
  } = useQuery(serviceEngagement, {
    variables: { id },
    fetchPolicy: 'network-only'
  })

  const changeLanguage = (e: string) => {
    i18n.changeLanguage(e)
    dispatch(
      changeCampaignRedeem({
        value: e,
        stateKey: 'language'
      })
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    i18n.changeLanguage('fi')
    dispatch(
      changeCampaignRedeem({
        value: 'fi',
        stateKey: 'language'
      })
    )
    dispatch(changeCampaignRedeem({ value: 0, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Find Campaign based on id
  useEffect(() => {
    if (campaignId) {
      findCampaign()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId])

  // Fetched serviceEngagement, find campaign id and set products found in metadata
  useEffect(() => {
    if (seData && products) {
      if (!seData.serviceEngagement) {
        setServiceEngagementError(true)
      } else {
        const engagementProducts: Product[] = []
        seData.serviceEngagement.metadata.forEach(
          (metadata: { key: string; value: unknown }) => {
            //Campaign ID
            if (metadata.key === 'campaignId') {
              setCampaignId(metadata.value as string)
            }

            // Set foreign delivery date for reducer
            if (metadata.key === 'foreignDeliveryDate') {
              dispatch(
                changeCampaignRedeem({
                  value: metadata.value as string,
                  stateKey: 'foreignDeliveryDate'
                })
              )
            }

            // Find metadata value with product ids
            if (metadata.key === 'productIds') {
              let metadataValue: { id: string }[] = []
              if (Array.isArray(metadata.value)) {
                metadataValue = metadata.value
              } else if (
                Object.prototype.hasOwnProperty.call(metadata.value, 'id')
              ) {
                metadataValue[0] = metadata.value as { id: string }
              }
              metadataValue.forEach((productId) => {
                // Products fetched from airtable and stored in Redux
                products.forEach((product: Product) => {
                  // Product with key 'types' means that all the Seasonal boxes are there
                  if (Object.prototype.hasOwnProperty.call(product, 'types')) {
                    product.types?.forEach((type) => {
                      if (type.id === productId.id) {
                        engagementProducts.push(type)
                      }
                    })
                  }
                  // other products (mainly dynamic products)
                  else if (product.id === productId.id) {
                    engagementProducts.push(product)
                  }
                })
              })
            }
          }
        )
        setOrderProducts(engagementProducts)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seData, products])
  // Fetch latest Campaign data from Airtable
  useEffect(() => {
    if (campaignsData && orderProducts) {
      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields[
              'Delivery Valid to-sesonki'
            ] || '',
          stateKey: 'deliveryValidToSeasonBox'
        })
      )

      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields[
              'Delivery Valid to'
            ] || '',
          stateKey: 'deliveryValidTo'
        })
      )

      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields[
              'DaysAheadBuffer-sesonki'
            ] || defaultDaysAheadBuffer,
          stateKey: 'daysAheadBufferSeasonBox'
        })
      )

      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields[
              'DaysAheadBuffer'
            ] || defaultDaysAheadBuffer,
          stateKey: 'daysAheadBuffer'
        })
      )

      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields[
              'SelectableWeeksCount'
            ] || 4,
          stateKey: 'redeemSelectableWeeksCount'
        })
      )

      dispatch(
        changeCampaignRedeem({
          value:
            campaignsData.fetchDataview.data.records[0].fields['ShowCharity'] ||
            false,
          stateKey: 'showCharity'
        })
      )

      // Find current products in campaign and store them in campaignRedeem reducer
      const redeemableProducts: Product[] = []
      campaignsData.fetchDataview.data.records[0].fields['Products'].forEach(
        (productId: string) => {
          orderProducts.forEach((product) => {
            if (product.id === productId) {
              redeemableProducts.push(product)
            }
          })
        }
      )
      dispatch(
        changeCampaignRedeem({
          value: redeemableProducts,
          stateKey: 'products'
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsData, orderProducts])

  // If ServiceEngagement query gives error, (wrong id), go back
  useEffect(() => {
    if (seError || serviceEngagementError) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seError, serviceEngagementError])

  const validateFields = () => {
    let valid = true
    if (code === '') {
      setRedemptionCodeValid(t('empty-field'))
      valid = false
    } else {
      setRedemptionCodeValid('')
    }
    if (!redemptionEmail.includes('@')) {
      setEmailValid(t('invalid-email'))
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const handleFormResult = (isStatusOpened: boolean) => {
    stopPolling()
    if (isStatusOpened) {
      checkBulkOrders({
        email: redemptionEmail,
        engagementId: id,
        giftCode: code
      })
        .then((result) => {
          if (result.data.isOk) {
            dispatch(
              changeCampaignRedeem({
                value: code,
                stateKey: 'redemptionCode'
              })
            )
            dispatch(
              changeCampaignRedeem({ value: id, stateKey: 'engagementId' })
            )
            history.push('/campaign-redeem-onboarding')
          } else {
            setRedemptionCodeValid(
              i18n.language === 'fi'
                ? 'Virheellinen lahjakoodi / sähköposti, tai lahjakoodi on jo lunastettu.'
                : 'Incorrect email, giftCode or gift is already redeemed.'
            )
          }
        })
        .catch((err) => {
          setRedemptionCodeValid(
            i18n.language === 'fi'
              ? 'Jokin meni palvelussamme pieleen. Yritä myöhemmin uudelleen.'
              : 'Error happened. Try again later.'
          )
        })
        .finally(() => setCheckingCode(false))
      // history.push('/campaign-redeem-onboarding')
    } else {
      setErrorText(
        i18n.language === 'fi' ? 'Väärä lahjakoodi' : 'Wrong gift card code'
      )
    }
  }

  // If Engagements status is 'Opened', proceed
  useEffect(() => {
    if (data) {
      if (data.formResult.result.hasOwnProperty('isStatusOpened')) {
        handleFormResult(data.formResult.result.isStatusOpened)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validateFields()) {
      setCheckingCode(true)
      checkRedeemFormCall({
        variables: {
          id: process.env.REACT_APP_CHECK_CAMPAIGN_REDEEM,
          form: {
            engagementId: id
          }
        }
      })
    }
  }

  if (campaignsError) {
    return (
      <ErrorPage.Status500
        text="Jokin meni palvelussamme pieleen. / Something went wrong."
        errorText="Yritä myöhemmin uudelleen. / Please try again later."
      />
    )
  }

  if (error || queryError) {
    return (
      <ErrorPage.Status500
        text="Jokin meni palvelussamme pieleen."
        errorText="Yritä myöhemmin uudelleen."
      />
    )
  }

  return (
    <>
      <Container style={{ position: 'relative' }}>
        <div className={classes.languageBar}>
          <Typography variant="caption" style={{ fontSize: '20px' }}>
            <Link
              onClick={() => changeLanguage('fi')}
              color="textPrimary"
              style={{
                cursor: 'pointer',
                fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
              }}
            >
              FI
            </Link>
            /
            <Link
              href="#"
              onClick={() => changeLanguage('en')}
              color="textPrimary"
              style={{
                cursor: 'pointer',
                fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
              }}
            >
              EN
            </Link>
          </Typography>
        </div>
      </Container>
      <div onSubmit={submit} className={classes.contentArea}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <VennerLogo style={{ width: '130px' }} />
          <Login
            maxWidth="sm"
            headline={t('gift-code')}
            subtitle={t('gift-code-text')}
            contentComponents={[
              <TextField
                value={redemptionEmail.toString()}
                onChange={(e) =>
                  dispatch(
                    changeCampaignRedeem({
                      value: e.target.value,
                      stateKey: 'redemptionEmail'
                    })
                  )
                }
                fullWidth
                label={t('email-address')}
                name="redemptionEmail"
                errorText={emailValid}
              />,
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                fullWidth
                label={t('gift-code')}
                name="redemptionCode"
                errorText={errorText || redemptionCodeValid}
              />
            ]}
            actionComponents={[
              <Button
                color="primary"
                label={t('button-proceed')}
                fullWidth
                disabled={
                  seLoading ||
                  campaignsLoading ||
                  queryLoading ||
                  mutationLoading ||
                  checkingCode
                }
                type="submit"
              />
            ]}
          />
          {seLoading ||
          campaignsLoading ||
          queryLoading ||
          mutationLoading ||
          checkingCode ? (
            <SpinnerMui
              style={{
                position: 'absolute',
                bottom: '36px',
                width: '100%',
                textAlign: 'center'
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default RedeemCode
