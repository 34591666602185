import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'

const useStyles = makeStyles((theme) => ({
  drawer: {
    '@media (min-width: 600px)': {
      width: '600px',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  container: {
    backgroundColor: theme.palette.secondary.main,
    padding: '8px 16px'
  },
  shareIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px'
  },
  singleShareIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const Share: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showShare } = useSelector((state: AppState) => state.utils)

  const url = 'https://www.venner.fi/'
  const title =
    'Syö ja tee hyvää kanssamme! Koska ravitseva ruoka kuuluu kaikille.'

  return (
    <Drawer
      classes={{ paperAnchorBottom: classes.drawer }}
      anchor="bottom"
      open={showShare}
      onClose={() =>
        dispatch(changeUtils({ value: false, stateKey: 'showShare' }))
      }
    >
      <div className={classes.container}>
        <Typography
          style={{
            textAlign: 'center',
            color: '#eeeeee',
            paddingBottom: '16px'
          }}
          variant="h5"
        >
          Haasta mukaan
        </Typography>
        <div className={classes.shareIcons}>
          <div className={classes.singleShareIcon}>
            <WhatsappShareButton url={url} title={title} separator=" ">
              <WhatsappIcon size={35} round />
            </WhatsappShareButton>
            <Typography variant="caption" style={{ color: '#eeeeee' }}>
              Whatsapp
            </Typography>
          </div>
          <div className={classes.singleShareIcon}>
            <FacebookShareButton url={url} quote={title}>
              <FacebookIcon size={35} round />
            </FacebookShareButton>
            <Typography variant="caption" style={{ color: '#eeeeee' }}>
              Facebook
            </Typography>
          </div>
          <div className={classes.singleShareIcon}>
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon size={35} round />
            </TwitterShareButton>
            <Typography variant="caption" style={{ color: '#eeeeee' }}>
              Twitter
            </Typography>
          </div>
          <div className={classes.singleShareIcon}>
            <LinkedinShareButton url={url} title={title}>
              <LinkedinIcon size={35} round />
            </LinkedinShareButton>
            <Typography variant="caption" style={{ color: '#eeeeee' }}>
              LinkedIn
            </Typography>
          </div>
          <div className={classes.singleShareIcon}>
            <EmailShareButton url={url} subject={title}>
              <EmailIcon size={35} round />
            </EmailShareButton>
            <Typography variant="caption" style={{ color: '#eeeeee' }}>
              E-mail
            </Typography>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default Share
