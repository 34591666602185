import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Heading, Login, TextField, Button } from 'uniqore-components'
import { useLocation, useHistory } from 'react-router-dom'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 700px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 700px)': {
      height: 'calc(100vh - 56px)'
    }
  }
}))

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      error
    }
  }
`

const SetNewPassword: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [passwordValid, setPasswordValid] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const query = useQuery()
  const token = query.get('token')

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted(result) {
      if (result.resetPassword.error) {
        setError(true)
      } else {
        history.push('/venner-login')
      }
    },
    onError(error) {
      if (error) {
        setError(true)
      }
    }
  })

  const validatePasswords = () => {
    let valid = true
    if (!token) {
      setPasswordValid('Olethan saapunut sivulle sähköpostin kautta')
      valid = false
    } else if (password.length >= 8 && password === passwordRepeat) {
      setPasswordValid('')
    } else {
      setPasswordValid(
        'Tarkista tarkista että salasanat täsmäävät ja ovat vähintään 8 merkkiä pitkät'
      )
      valid = false
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validatePasswords()) {
      // validatePasswords checks for token null
      // so we can assume they are strings here
      resetPassword({
        variables: {
          token: token,
          newPassword: password
        }
      })
    }
  }

  return (
    <div onSubmit={submit} className={classes.contentArea}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Login
          maxWidth="sm"
          headline="Aseta uusi salasana"
          subtitle=" " /* Replace the default subtitle string */
          contentComponents={[
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              type="password"
              placeholder="Uusi salasana"
              name="password"
              prefix={{
                icon: 'MailOutline'
              }}
            />,
            <TextField
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              errorText={passwordValid}
              fullWidth
              type="password"
              placeholder="Toista uusi salasana"
              name="emailRepeat"
              prefix={{
                icon: 'MailOutline'
              }}
            />
          ]}
          actionComponents={[
            <Button
              color="primary"
              label="Tallenna uusi salasana"
              fullWidth
              disabled={loading}
              type="submit"
              onClick={submit}
            />
          ]}
        />
        {error && <Heading body="Jokin meni pieleen. Yritä uudelleen." />}
      </div>
    </div>
  )
}

export default SetNewPassword
