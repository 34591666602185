import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import {
  AppBar,
  Button,
  FeedTitle,
  StatusHero,
  SpeedDial,
  FeedCard,
  i18n
} from 'uniqore-components'
import VennerLogo from 'assets/venner-logo.svg'
import { changeUtils } from 'features/utils'
import { getTime } from 'utils/common'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'

const RedeemConfirm: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const { products } = useSelector((state: AppState) => state.airtableData)
  const {
    language,
    forCharity,
    selectedProduct,
    selectedProductType,
    redeemedProduct,
    selectedCountry
  } = useSelector((state: AppState) => state.campaignRedeem)

  const [nutrituousAmount, setNutrituousAmount] = useState<number>(0)

  const { t } = useTranslation()

  useEffect(() => {
    if (redeemedProduct) {
      if (
        selectedProduct === 'Sesonkiruokalaatikko' ||
        selectedProduct === 'Seasonal Box' ||
        selectedProduct === '' ||
        selectedCountry === 'Ei valintaa'
      ) {
        setNutrituousAmount(3)
      } else {
        const product = products.find(
          (product) => product.id === redeemedProduct
        )
        product && setNutrituousAmount(product.support / 2)
      }
      i18n.changeLanguage(language)
    } else {
      history.push('/')
    }
  }, [
    language,
    redeemedProduct,
    selectedProduct,
    setNutrituousAmount,
    history,
    products,
    selectedCountry
  ])

  return (
    <div>
      <AppBar
        maxWidth="sm"
        backgroundColor="#CEE3DF"
        logo={VennerLogo}
        logoSize={60}
      />
      <StatusHero
        maxWidth="sm"
        backgroundColor="#CEE3DF"
        color="#525252"
        video={'https://player.vimeo.com/video/556264772'}
        headline={
          forCharity
            ? t('charity-order-confirmed-title')
            : t('thank-you-message')
        }
        subtitle={t('thank-you-message-subtitle')}
        actionComponent={
          <Button
            role="status"
            icon="ArrowForward"
            label={t('button-discover-recipes')}
            onClick={() => {
              window.open(
                'https://app.seidat.com/presentation/shared/zAFTYFiWCWTFpnELL/0/0'
              )
            }}
          />
        }
      />
      <Button
        style={{ textAlign: 'center' }}
        label={t('button-share')}
        underline
        color="primary"
        variant="default"
        icon="Share"
        onClick={() =>
          dispatch(changeUtils({ value: true, stateKey: 'showShare' }))
        }
      />
      <div style={{ marginBottom: '120px' }}>
        <FeedTitle text={t('events-text')} />
        <FeedCard
          avatarBackgroundColor={theme.palette.primary.main}
          avatarText=""
          user="VENNER OY"
          date={`${t('events-date-title')} ${getTime()}`}
          subtitle={t('order-confirmed-title')}
          htmlText={t('order-confirmed-text')}
        />
        <FeedCard
          avatarBackgroundColor={theme.palette.secondary.main}
          avatarImage=""
          user={t('new-order-card-recipient-title')}
          date={`${t('events-date-title')} ${getTime()}`}
          subtitle={t('new-order-card-title')}
          htmlText={
            forCharity
              ? i18n.language === 'en'
                ? 'Thank you for donating your food box for a family in need!'
                : 'Kiitos että lahjoitit ruokalaatikkosi vähävaraiselle perheelle!'
              : i18n.language === 'en'
              ? `You ordered a <b>${selectedProduct} ${selectedProductType}</b>, which makes it possible for us to provide <b>${nutrituousAmount} nutritious meals</b> for families in need of help.`
              : `Tilasit tuotteen <b>${selectedProduct} ${selectedProductType}</b>, jonka myötä mahdollistit <b>${nutrituousAmount} ravitsevaa annosta</b> apua tarvitseville perheille.`
          }
        />
      </div>
      <SpeedDial
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        actions={[
          {
            icon: 'ArrowForward',
            label: t('button-know-recipes'),
            onClick: () => {
              window.open(
                'https://app.seidat.com/presentation/shared/zAFTYFiWCWTFpnELL/0/0'
              )
            }
          },
          {
            icon: 'Send',
            label: t('button-leave-comment'),
            onClick: () =>
              dispatch(
                changeUtils({ value: true, stateKey: 'showFeedbackDialog' })
              )
          },
          {
            icon: 'Share',
            label: t('button-share'),
            onClick: () =>
              dispatch(changeUtils({ value: true, stateKey: 'showShare' }))
          }
        ]}
      />
    </div>
  )
}

export default RedeemConfirm
