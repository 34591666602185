import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Container,
  Heading,
  BottomBar,
  AppBar,
  TextField
} from 'uniqore-components'
import { AppState } from 'store'
import { parseEmailString } from 'api/cloudFunctions'
import { changeBulkOrder } from 'features/BulkOrder'
import { useLogOut } from 'components/BulkOrder/LoginPage'

const SelectEmailList: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  const { campaign, emailList } = useSelector(
    (state: AppState) => state.bulkOrder
  )

  const [emails, setEmails] = useState<string>(emailList)
  const [emailsValid, setEmailsValid] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 2, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!campaign.id) {
      history.push('/bulkorder-select-campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const goBack = () => {
    dispatch(changeBulkOrder({ value: 1, stateKey: 'currentView' }))
    history.push('/bulkorder-select-campaign')
  }

  const validateFields = () => {
    let valid = true
    if (emails === '') {
      setEmailsValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      try {
        parseEmailString(emails)
      } catch (error) {
        const err = error as Error
        setEmailsValid(err.message)
        valid = false
      }

      if (valid) {
        setEmailsValid('')
      }
    }
    return valid
  }

  const goForward = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validateFields()) {
      dispatch(changeBulkOrder({ value: emails, stateKey: 'emailList' }))
      history.push('/bulkorder-company-details')
    }
  }

  return (
    <Container>
      <AppBar
        text="Takaisin"
        mainAction={{ icon: 'ArrowBack', onClick: goBack }}
        actions={[
          {
            icon: 'ExitToApp',
            onClick: logOut
          }
        ]}
      />
      <Heading
        title="Sähköpostilista"
        subtitle="Lisää sähköpostiosoitteet tähän listaan"
      />
      <Heading
        subtitle="Erottele toisistaan joko pilkulla, välillä taikka puolipisteellä."
        style={{ marginTop: '-30px' }}
      />
      <TextField
        value={emails}
        onChange={(e) => setEmails(e.target.value)}
        placeholder="Sähköposti 1, Sähköposti 2 jne."
        rows={15}
        fullWidth
        errorText={emailsValid}
      />
      <div style={{ marginBottom: '120px' }}></div>
      <BottomBar
        style={{ position: 'absolute', bottom: 30, width: '100%' }}
        maxWidth="sm"
        components={[
          <Button
            variant="default"
            border
            disablePadding
            fullWidth
            label="Edellinen"
            onClick={goBack}
          />,
          <Button
            color="primary"
            disablePadding
            fullWidth
            label="Seuraava"
            onClick={goForward}
          />
        ]}
      />
    </Container>
  )
}

export default SelectEmailList
