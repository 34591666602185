import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  AppBar,
  Button,
  Checkbox,
  Container,
  Heading,
  ListItem
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import {
  Order,
  emptyOrderDetails,
  CompanyContact,
  DeliveryContact,
  PersonContact
} from 'features/order'
import { addToOrders } from 'features/orders'
import { changeUtils, addToPersonalFeed } from 'features/utils'
import { getDate, getTime, createOrderFeed, round } from 'utils/common'
import { AppState } from 'store'
import { Product, Shipping } from 'types/types'
import { format, parse, addDays } from 'date-fns'
import { gql, useMutation } from '@apollo/client'

const useStyles = makeStyles(() => ({
  checkboxCustom: {
    '& a': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
}))

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`
const setProductPayloadData = (product: Product) => {
  if (product.isDynamic && product.type && product.deliveryProducts) {
    product.shippingPrice = product.deliveryProducts[product.type].price
  }
  delete product.minQuantity
  delete product.showOrder
  delete product.isActive
  delete product.salesValidFrom
  delete product.salesValidTo
  delete product.redeemValidFrom
  delete product.deliveryValidFrom
  delete product.deliveryValidTo
  delete product.selectableWeeksCount
  delete product.daysAheadBuffer
  delete product.deliveryProducts
  return product
}

// Collico API does not like white spaces, remove them
const trimCompanyContact = (companyContact: CompanyContact) => {
  const fixAddress = companyContact.address.trim()
  companyContact.address = fixAddress
  const fixZip = companyContact.zip.trim()
  companyContact.zip = fixZip
  const fixCity = companyContact.city.trim()
  companyContact.city = fixCity
}
const trimDeliveryContact = (deliveryContact: DeliveryContact) => {
  const fixAddress = deliveryContact.address.trim()
  deliveryContact.address = fixAddress
  const fixZip = deliveryContact.zip.trim()
  deliveryContact.zip = fixZip
  const fixCity = deliveryContact.city.trim()
  deliveryContact.city = fixCity
}
const trimPersonContent = (personContact: PersonContact) => {
  const fixFirstName = personContact.firstName.trim()
  personContact.firstName = fixFirstName
  const fixLastName = personContact.lastName.trim()
  personContact.lastName = fixLastName
  const fixEmail = personContact.email.trim()
  personContact.email = fixEmail
  const trimPhoneNumber = personContact.phone.trim()
  const removePossibleParenthesesPhone = trimPhoneNumber.replace(/([()])/g, '')
  personContact.phone = removePossibleParenthesesPhone
}

const OrderSummary: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { shippingGiftcard, shippingCargo } = useSelector(
    (state: AppState) => state.airtableData
  )
  const order = useSelector((state: AppState) => state.order)
  const {
    isDynamicProduct,
    products,
    deliveryDate,
    companyContact,
    deliveryContact,
    personContact,
    //supportCard,
    billing,
    cartTotals,
    orderType
  } = useSelector((state: AppState) => state.order)
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [createdOrder, setCreatedOrder] = useState<Order>()
  const [formCall, { loading: mutationLoading, error: mutationError }] =
    useMutation(formQuery, {
      onCompleted(mutationResult) {
        if (mutationResult && !mutationResult.ingestForm.errors) {
          dispatch(
            changeUtils({
              value: mutationResult.ingestForm.result,
              stateKey: 'orderIngestFormResult'
            })
          )
          setSuccess(true)
        } else {
          setError(true)
        }
      },
      onError(mutationError) {
        if (mutationError) {
          setError(true)
        }
      }
    })

  useEffect(() => {
    window.scrollTo(0, 0)
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 9, stateKey: 'currentView' }))
    } else {
      dispatch(changeUtils({ value: 8, stateKey: 'currentView' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if formQuery successful, create order and feed and go to the next page
  useEffect(() => {
    if (success) {
      setSuccess(false)
      dispatch(emptyOrderDetails())
      dispatch(
        changeUtils({ value: createdOrder!.orderId, stateKey: 'latestOrderId' })
      )
      dispatch(addToOrders({ order: createdOrder as Order }))
      dispatch(
        addToPersonalFeed({ value: createOrderFeed(createdOrder as Order) })
      )
      // set values for the new order and for feedback and contact us (Ota yhteyttä) -dialog
      dispatch(
        changeUtils({
          value: createdOrder!.personContact.firstName,
          stateKey: 'personContactFirstName'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.personContact.lastName,
          stateKey: 'personContactLastName'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.personContact.email,
          stateKey: 'personContactEmail'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.personContact.phone,
          stateKey: 'personContactPhone'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.companyContact.companyName,
          stateKey: 'companyName'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.companyContact.businessId,
          stateKey: 'companyBusinessId'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.companyContact.address,
          stateKey: 'companyAddress'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.companyContact.zip,
          stateKey: 'companyZip'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.companyContact.city,
          stateKey: 'companyCity'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.billing.ovtId,
          stateKey: 'companyOvtId'
        })
      )
      dispatch(
        changeUtils({
          value: createdOrder!.billing.operatorId,
          stateKey: 'companyOperatorId'
        })
      )
      dispatch(changeUtils({ value: 0, stateKey: 'currentView' }))
      history.push('/status-orders')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const goBack = () => {
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 8, stateKey: 'currentView' }))
    } else {
      dispatch(changeUtils({ value: 7, stateKey: 'currentView' }))
    }
    history.push('/order-billing')
  }

  const goToView = (route: string) => {
    history.push(route)
  }

  const getProductSummaryDetails = () => {
    let result: string[] = []
    // Sesonkiruokalaatikko
    if (!isDynamicProduct) {
      products.forEach((product: Product) => {
        if (product.quantity > 0) {
          result.push(
            product.type === 'Charity'
              ? `${product.quantity} x ${product.text} (á ${product.price} € / kpl)`
              : product.type === 'Cargo'
              ? `${product.quantity} x ${product.text} (á ${
                  product.price + shippingCargo.price
                } € / kpl)`
              : `${product.quantity} x ${product.text} (á ${
                  product.price + shippingGiftcard.price
                } € / kpl)`
          )
        }
      })
    }
    // Dynamic Product
    else {
      result.push(
        `${products[0].quantity} x ${products[0].text} (á ${products[0].price} € / kpl)`
      )
      if (products[0].type === 'Cargo') {
        result.push('Kertatoimitus toimipisteeseen')
      }
      if (products[0].type === 'Home') {
        result.push('Kotiintoimitus yksittäin')
        result.push('Lunastaminen lahjakortilla')
      }
      if (products[0].type === 'Pickup') {
        result.push('Toimitus noutopisteeseen yksittäin')
        result.push('Lunastaminen lahjakortilla')
      }
    }

    return result
  }

  // const getSupportText = () => {
  //   let result: string[] = []
  //   const { showCompanyName, showQuantity } = supportCard
  //   if (showCompanyName && showQuantity) {
  //     result.push('Yrityksen nimi, tilausmäärä')
  //   }
  //   if (!showCompanyName && showQuantity) {
  //     result.push('Ei yrityksen nimeä, vain tilausmäärä')
  //   }
  //   if (showCompanyName && !showQuantity) {
  //     result.push('Yrityksen nimi, ei tilausmäärää')
  //   }
  //   if (!showCompanyName && !showQuantity) {
  //     result.push('Ei yrityksen nimeä, ei tilausmäärää')
  //   }
  //   return result
  // }

  const handleOrder = () => {
    setError(false)
    const sendOrder: Order = JSON.parse(JSON.stringify(order))
    sendOrder.orderId = uuid()
    sendOrder.orderDate = getDate()
    sendOrder.orderTime = getTime()
    sendOrder.status = 'Processing'

    if (sendOrder.billing.eInvoice) {
      sendOrder.billing.email = ''
    } else {
      sendOrder.billing.ovtId = ''
      sendOrder.billing.operatorId = ''
    }
    // if companyContact is also the delivery address, empty deliveryContact
    if (sendOrder.companyContact.deliveryAddress) {
      sendOrder.deliveryContact.companyName = ''
      sendOrder.deliveryContact.address = ''
      sendOrder.deliveryContact.zip = ''
      sendOrder.deliveryContact.city = ''
    }

    // if Cargo-order, set format deliveryDate to match Airtable field
    if (sendOrder.orderType === 'Cargo') {
      if (sendOrder.deliveryDate !== 'Olemme sinuun yhteydessä') {
        const parsedDate = parse(
          sendOrder.deliveryDate,
          'dd.MM.yyyy',
          new Date()
        )
        sendOrder.deliveryDate = format(parsedDate, 'yyyy-MM-dd')
        const year: number = Number(order.deliveryDate.split('.')[2])
        const month: number = Number(order.deliveryDate.split('.')[1]) - 1
        const day: number = Number(order.deliveryDate.split('.')[0])
        sendOrder.collicoCargoDeliveryDate = format(
          addDays(new Date(year, month, day), -1),
          'yyyy-MM-dd'
        )
      } else {
        sendOrder.deliveryDate = ''
      }
    } else {
      sendOrder.deliveryDate = ''
    }

    const setProducts: Product[] = []
    // Sesonkiruokalaatikot
    if (!isDynamicProduct) {
      sendOrder.products.forEach((product: Product) => {
        // If product has a key 'types', this means that it is a Cargo-delivery
        // and those different SeasonBoxes (vegan, glutenfree etc.) and their
        // quantities are stored in this 'types' array.
        if (Object.prototype.hasOwnProperty.call(product, 'types')) {
          product.types?.forEach((type: Product) => {
            if (type.quantity !== 0) {
              setProductPayloadData(type)
              setProducts.push(setProductPayloadData(type))
            }
          })
        } else {
          if (product.quantity !== 0) {
            setProducts.push(setProductPayloadData(product))
          }
        }
      })
    }
    // Dynamic Product
    else {
      setProducts.push(setProductPayloadData(sendOrder.products[0]))
      if (sendOrder.orderType === 'Giftcard') {
        sendOrder.giftCardDeliveryType = sendOrder.products[0].type as
          | 'Home'
          | 'Pickup'
      }
    }
    sendOrder.products = setProducts

    // Set shipping array
    if (!isDynamicProduct) {
      sendOrder.products.forEach((product: Product) => {
        if (product.type === 'Giftcard') {
          const giftcardShipping: Shipping = JSON.parse(
            JSON.stringify(shippingGiftcard)
          )
          giftcardShipping.quantity = product.quantity
          sendOrder.shipping.push(giftcardShipping)
        }
        if (product.type === 'Cargo') {
          const cargoShipping: Shipping = JSON.parse(
            JSON.stringify(shippingCargo)
          )
          cargoShipping.quantity = product.quantity
          sendOrder.shipping.push(cargoShipping)
        }
      })
    } else {
      products.forEach((product: Product) => {
        let shipping
        if (product.type === 'Pickup') {
          shipping = JSON.parse(
            JSON.stringify(product.deliveryProducts!['Pickup'])
          )
        }
        if (product.type === 'Home') {
          shipping = JSON.parse(
            JSON.stringify(product.deliveryProducts!['Home'])
          )
        }
        if (product.type === 'Cargo') {
          shipping = JSON.parse(
            JSON.stringify(product.deliveryProducts!['Cargo'])
          )
        }
        if (shipping) {
          // If Dynamic product, shipping-objects have a key 'type'.
          // This type is used to recognize that the
          // shipping details come from another Airtable-table (Delivery Products)
          // and is therefore required.
          delete shipping.sku
          delete shipping.notes
          shipping.quantity = product.quantity
          sendOrder.shipping.push(shipping)
        }
      })
    }

    // Collico API does not like white spaces, remove them
    trimCompanyContact(sendOrder.companyContact)
    trimDeliveryContact(sendOrder.deliveryContact)
    trimPersonContent(sendOrder.personContact)

    setCreatedOrder(sendOrder)
    formCall({
      variables: {
        id: process.env.REACT_APP_SEND_ORDER_KEY,
        form: sendOrder
      }
    })
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
      />
      <div style={{ marginBottom: error ? 220 : 180 }}>
        <Heading title="Yhteenveto" />
        <ListItem
          text="Yhteenveto tilattavista palveluista"
          subtitles={getProductSummaryDetails()}
          metaIcon="Edit"
          onClick={() => goToView('/order-cart')}
        />
        <ListItem
          text="Kustannus- ja tukijakauma"
          subtitles={[
            `Kustannukset (alv 0%) ${round(cartTotals.totalNoTaxes)}`,
            `Verot (14 %) ${round(cartTotals.productTaxes)}`,
            cartTotals.shippingTaxes === 0
              ? ''
              : `Verot 25,5 %) ${round(cartTotals.shippingTaxes)}`,
            `Kustannukset yhteensä ${round(cartTotals.totalWithTaxes)}`,
            `Tuen osuus ${round(cartTotals.totalAid)}`
          ]}
          metaIcon="Edit"
          onClick={() => goToView('/order-cart')}
        />
        <ListItem
          text="Yhteyshenkilön tiedot"
          subtitles={[
            `${personContact.firstName} ${personContact.lastName}`,
            personContact.phone,
            personContact.email,
            personContact.mailingList
              ? 'Liityn Vennerin yrityspalveluiden postituslistalle'
              : ''
          ]}
          metaIcon="Edit"
          onClick={() => goToView('/order-contact-person')}
        />
        <ListItem
          text="Yrityksen yhteystiedot"
          subtitles={[
            companyContact.companyName,
            companyContact.address,
            `${companyContact.zip} ${companyContact.city}`
          ]}
          metaIcon="Edit"
          onClick={() => goToView('/order-contact-company')}
        />
        {!companyContact.deliveryAddress && (
          <ListItem
            text="Toimitusosoite"
            subtitles={[
              deliveryContact.companyName,
              deliveryContact.address,
              `${deliveryContact.zip} ${deliveryContact.city}`
            ]}
            metaIcon="Edit"
            onClick={() => goToView('/order-contact-company')}
          />
        )}
        {deliveryDate && (
          <ListItem
            text="Toimituspäivä toimipisteelle"
            subtitles={[deliveryDate]}
            metaIcon="Edit"
            onClick={() => goToView('/order-delivery-date')}
          />
        )}
        {/* <ListItem
          text="Sivustonäkyvyys"
          subtitles={getSupportText()}
          metaIcon="Edit"
          onClick={() => goToView('/order-support')}
        /> */}
        <ListItem
          text="Laskutustiedot"
          subtitles={[
            !billing.eInvoice
              ? `Lasku sähköpostiin ${billing.email}`
              : `Verkkolasku ${billing.ovtId} / ${billing.operatorId}`,
            billing.additionalInfoActive
              ? `Lisätiedot: "${billing.additionalInfo}"`
              : ''
          ]}
          metaIcon="Edit"
          onClick={() => goToView('/order-billing')}
        />
      </div>
      <Container style={{ position: 'absolute', bottom: 40, width: '100%' }}>
        <Checkbox
          className={classes.checkboxCustom}
          checked={acceptTerms}
          htmlLabel={`Hyväksyn Vennerin <a href="https://www.venner.fi/pages/kayttoehdot" target="blank">yleiset käyttö- ja toimitusehdot.</a>`}
          onChange={() => setAcceptTerms(!acceptTerms)}
        />
        {error || mutationError ? (
          <Heading body="Jokin meni pieleen. Yritä uudelleen." />
        ) : null}
        {mutationLoading && (
          <SpinnerMui
            size={30}
            style={{
              position: 'absolute',
              bottom: '13px',
              width: '100%',
              textAlign: 'center'
            }}
          />
        )}
        <Button
          maxWidth="sm"
          label={`Tee tilaus (${round(cartTotals.totalWithTaxes)})`}
          color="primary"
          fullWidth
          onClick={handleOrder}
          disabled={!acceptTerms || mutationLoading}
        />
      </Container>
    </Container>
  )
}

export default OrderSummary
