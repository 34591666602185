import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Container, Onboarding } from 'uniqore-components'
import { ReactComponent as OrderReady } from 'assets/orderReady.svg'
import { resetBulkOrder } from 'features/BulkOrder'
import { useLogOut } from 'components/BulkOrder/LoginPage'

const Confirmation: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  return (
    <Container>
      <Onboarding
        screens={[
          {
            image: <OrderReady />,
            title: 'Tilaus on tehty.'
          }
        ]}
        showIndicators={false}
        actionDirection="column"
        actionComponents={[
          <Button
            label="Luo uusi tilaus"
            color="primary"
            fullWidth
            onClick={() => {
              dispatch(resetBulkOrder())
              history.push('bulkorder-select-campaign')
            }}
          />,
          <Button
            customColor={{
              color: 'rgba(0, 44, 56, 0.87)'
            }}
            label="Kirjaudu ulos"
            variant="default"
            color="primary"
            fullWidth
            onClick={() => logOut()}
          />
        ]}
      />
    </Container>
  )
}

export default Confirmation
