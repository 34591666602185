import { authLink } from './authLink'
import { analyticsMiddleware } from './analyticsMiddleware'
import { errorLink } from './errorLink'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL_SERVICE as string
})

const ServiceClient = new ApolloClient({
  link: from([analyticsMiddleware, authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default ServiceClient
