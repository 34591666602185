import {
  gql,
  LazyQueryExecFunction,
  OperationVariables,
  useQuery
} from '@apollo/client'
import ServiceClient from 'api/serviceClient'
import { useLogOut } from 'components/BulkOrder/LoginPage'

const orgUser = gql`
  query orgUser($id: ID!) {
    orgUser(id: $id) {
      id
    }
  }
`

export const useCheckTokenExpiration = (
  getCampaigns?: LazyQueryExecFunction<any, OperationVariables>
) => {
  const logOut = useLogOut()
  const { loading } = useQuery(orgUser, {
    client: ServiceClient,
    variables: { id: localStorage.getItem('uq_user_id') || '' },
    fetchPolicy: 'network-only',
    onCompleted() {
      if (getCampaigns) {
        getCampaigns()
      }
    },
    onError() {
      logOut()
    }
  })

  return {
    loading
  }
}
