import React from 'react'
import { ErrorPage } from 'uniqore-components'

const NotFound: React.FC = () => {
  return (
    <ErrorPage
      text="Jokin meni pieleen."
      errorText="Valitettavasti emme löydä etsimääsi sivua."
      errorType="404: Sivua ei löytynyt"
    />
  )
}

export default NotFound
