import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Container,
  Heading,
  BottomBar,
  Button,
  Dialog
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { formatISO, format, addDays } from 'date-fns'
import { getDeliveryDates } from 'utils/common'
import { changeRedeem } from 'features/redeem'
import { AppState } from 'store'

const searchZip = gql`
  query searchZip($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

const RedeemDate: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    delivery,
    deliveryDate,
    deliveryDetails,
    code,
    isDynamicProduct,
    dynamicProduct
  } = useSelector((state: AppState) => state.redeem)
  const [dates, setDates] = useState<string[]>([])
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openSchenkerB2CDialog, setOpenSchenkerB2CDialog] =
    useState<boolean>(false)
  const [
    openSchenkerB2CDynamicProductDialog,
    setOpenSchenkerB2CDynamicProductDialog
  ] = useState<boolean>(false)
  const [openSchenkerDialog, setOpenSchenkerDialog] = useState<boolean>(false)
  const [zip, setZip] = useState<string>('')
  const [fetchZipData, { data, loading, error }] = useLazyQuery(searchZip, {
    variables: {
      id: process.env.REACT_APP_SEARCH_ZIP_ID,
      parameters: { zip }
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 4, stateKey: 'currentView' }))
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (delivery.method) {
      // DbSchenkerB2C dialog for Sesonkiruokalaatikot
      // Matkahuolto replaces DbSchenkerB2C but we have no reason to remove DbSchenkerB2C
      if (
        (delivery.method === 'DbSchenkerB2C' ||
          delivery.method === 'Matkahuolto') &&
        !isDynamicProduct
      ) {
        setOpenSchenkerB2CDialog(true)
      }
      // Dialog for dynamic product (home delivery)
      // Matkahuolto replaces DbSchenkerB2C but we have no reason to remove DbSchenkerB2C
      if (
        (delivery.method === 'DbSchenkerB2C' ||
          delivery.method === 'Matkahuolto') &&
        dynamicProduct &&
        dynamicProduct.deliveryProducts
      ) {
        if (dynamicProduct.deliveryProducts['Home'].notes) {
          setOpenSchenkerB2CDynamicProductDialog(true)
        }
      }
      // DbSchenker dialog for dynamic product (pickup delivery)
      if (
        delivery.method === 'DbSchenker' &&
        dynamicProduct &&
        dynamicProduct.deliveryProducts
      ) {
        if (dynamicProduct.deliveryProducts['Pickup'].notes) {
          setOpenSchenkerDialog(true)
        }
      }
      // If no dates found, show dialog -> Venner contacts redeemer about the dates
      if (delivery.method === 'none') {
        setOpenDialog(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery.method])

  // set zip code
  useEffect(() => {
    const trimZip = deliveryDetails.zip.trim()
    setZip(trimZip)
  }, [deliveryDetails.zip])

  // when zip is set, useLazyQuery
  useEffect(() => {
    if (zip) {
      fetchZipData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip])

  // set dates
  useEffect(() => {
    if (data) {
      let getDates: string[] = []
      if (data.fetchDataview.data.records.length !== 0) {
        const fields = data.fetchDataview.data.records[0].fields
        if (isDynamicProduct && dynamicProduct) {
          // Dynamic product
          getDates = getDeliveryDates(
            fields.HomeDeliveryDay,
            dynamicProduct.daysAheadBuffer || fields.DaysAheadBuffer,
            dynamicProduct.selectableWeeksCount || fields.SelectableWeeksCount,
            fields.HomeDeliveryTime,
            dynamicProduct
          )
        } else {
          // Sesonkiruokalaatikot
          getDates = getDeliveryDates(
            fields.HomeDeliveryDay,
            fields.DaysAheadBuffer,
            fields.SelectableWeeksCount,
            fields.HomeDeliveryTime
          )
        }
      }
      setDates(getDates)
      // if no dates found, show dialog -> Venner contacts redeemer about the dates
      if (getDates.length < 1) {
        setOpenDialog(true)
      } else {
        if (isDynamicProduct && dynamicProduct) {
          const homeDeliveryMethod =
            data.fetchDataview.data.records[0].fields.HomeDeliveryMethod
          const pickupDeliveryMethod =
            data.fetchDataview.data.records[0].fields.PickupDeliveryMethod
          dispatch(
            changeRedeem({
              value:
                dynamicProduct.type === 'Home'
                  ? homeDeliveryMethod
                  : dynamicProduct.type === 'Pickup'
                  ? pickupDeliveryMethod
                  : 'none',
              stateKey: 'delivery',
              stateKeyExtra: 'method'
            })
          )
        }
        // For Sesonkiruokalaatikko
        else {
          dispatch(
            changeRedeem({
              value:
                data.fetchDataview.data.records[0].fields.HomeDeliveryMethod,
              stateKey: 'delivery',
              stateKeyExtra: 'method'
            })
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formatDeliveryDate = (deliveryDate: string) => {
    const dateAndTime = deliveryDate.split(' klo ')
    const day: number = Number(dateAndTime[0].split('.')[0])
    const month: number = Number(dateAndTime[0].split('.')[1]) - 1
    const year: number = Number(dateAndTime[0].split('.')[2])

    const startAndEnd = dateAndTime[1].split('-')
    const start = startAndEnd[0].split(':')
    const end = startAndEnd[1].split(':')

    const startTime = formatISO(
      new Date(year, month, day, Number(start[0]), 0, 0)
    )
    const endTime = formatISO(new Date(year, month, day, Number(end[0]), 0, 0))
    return { startTime, endTime }
  }

  const getSchenkerDate = (deliveryDate: string, method: string) => {
    // Matkahuolto replaces DbSchenkerB2C but we have no reason to remove DbSchenkerB2C
    if (
      method === 'DbSchenkerB2C' ||
      method === 'Matkahuolto' ||
      method === 'DbSchenker'
    ) {
      const dateAndTime = deliveryDate.split(' klo ')
      const day: number = Number(dateAndTime[0].split('.')[0])
      const month: number = Number(dateAndTime[0].split('.')[1]) - 1
      const year: number = Number(dateAndTime[0].split('.')[2])
      return format(addDays(new Date(year, month, day), -1), 'yyyy-MM-dd')
    } else {
      return ''
    }
  }

  const goBack = () => {
    dispatch(changeRedeem({ value: 3, stateKey: 'currentView' }))
    dispatch(
      changeRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'method'
      })
    )
    dispatch(
      changeRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'startTime'
      })
    )
    dispatch(
      changeRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'endTime'
      })
    )
    dispatch(
      changeRedeem({
        value: '',
        stateKey: 'delivery',
        stateKeyExtra: 'schenkerDeliveryDate'
      })
    )
    history.push('/redeem-delivery')
  }

  const goForward = (fromDialog: boolean) => {
    if (fromDialog) {
      dispatch(
        changeRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'method'
        })
      )
      dispatch(
        changeRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'startTime'
        })
      )
      dispatch(
        changeRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'endTime'
        })
      )
      dispatch(
        changeRedeem({
          value: '',
          stateKey: 'delivery',
          stateKeyExtra: 'schenkerDeliveryDate'
        })
      )
      dispatch(
        changeRedeem({
          value: 'Olemme sinuun yhteydessä',
          stateKey: 'deliveryDate'
        })
      )
    } else {
      const { startTime, endTime } = formatDeliveryDate(deliveryDate)
      dispatch(
        changeRedeem({
          value: startTime,
          stateKey: 'delivery',
          stateKeyExtra: 'startTime'
        })
      )
      dispatch(
        changeRedeem({
          value: endTime,
          stateKey: 'delivery',
          stateKeyExtra: 'endTime'
        })
      )
      dispatch(
        changeRedeem({
          value: getSchenkerDate(deliveryDate, delivery.method),
          stateKey: 'delivery',
          stateKeyExtra: 'schenkerDeliveryDate'
        })
      )
    }
    dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
    history.push('/redeem-summary')
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        />
      )}
      <Container>
        <AppBar
          mainAction={{
            icon: 'ArrowBack',
            onClick: goBack
          }}
          text="Toimitustiedot"
        />
        <div style={{ marginBottom: 120 }}>
          <Heading
            title={
              dates.length === 0
                ? 'Toimituspäiviä haetaan'
                : 'Valitse toimituspäivä'
            }
            body={
              !loading && delivery.method === 'Collico'
                ? 'Saat toimitusta edeltävänä iltana klo 20 tarkemman toimitusajan tekstiviestitse.'
                : ''
            }
          />
          {dates.map((date, i) => (
            <Button
              key={i}
              checked={deliveryDate === date}
              customColor={{
                color: deliveryDate === date ? 'black' : '',
                backgroundColor: deliveryDate === date ? '#CEE3DF' : ''
              }}
              role="radio"
              label={date}
              value={date}
              onClick={() =>
                dispatch(
                  changeRedeem({
                    value: date,
                    stateKey: 'deliveryDate'
                  })
                )
              }
            />
          ))}
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              onClick={() => goForward(false)}
              disabled={deliveryDate === ''}
            />
          ]}
        />
      </Container>
      {/* Dialog if no delivery dates found */}
      <Dialog
        open={openDialog}
        onClose={() => {}}
        title="Huomio tilauksestasi"
        subtitle="Olemme sinuun henkilökohtaisesti yhteyksissä tarkemman toimituspäivän osalta. Voit viedä tilauksen normaalisti loppuun."
        actionComponents={[
          <Button
            label="Edellinen"
            variant="text"
            disablePadding
            border
            onClick={goBack}
          />,
          <Button
            label="Seuraava"
            color="primary"
            disablePadding
            onClick={() => goForward(true)}
          />
        ]}
      />
      {/* Dialog if Sesonkilaatikko delivery method is DbSchenkerB2C or Matkahuolto (home delivery) */}
      <Dialog
        open={openSchenkerB2CDialog}
        onClose={() => setOpenSchenkerB2CDialog(false)}
        title="Huomio tilauksestasi"
        subtitle="Valitse alta toimituspäivä. Tämä päivä on arvio, sillä saat kyseisenä päivänä, tai viimeistään seuraavana päivänä Matkahuollolta tekstiviestin, jolla voit valita sinulle sopivan kotiintoimitusajan."
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenSchenkerB2CDialog(false)}
          />
        ]}
      />
      {/* Dialog if dynamic product delivery method is DbSchenkerB2C or Matkahuolto (home delivery) */}
      <Dialog
        open={openSchenkerB2CDynamicProductDialog}
        onClose={() => setOpenSchenkerB2CDynamicProductDialog(false)}
        title="Huomio tilauksestasi"
        subtitle={
          dynamicProduct?.deliveryProducts!['Home']
            ? dynamicProduct?.deliveryProducts!['Home'].notes
            : ''
        }
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenSchenkerB2CDynamicProductDialog(false)}
          />
        ]}
      />
      {/* Dialog if dynamic product delivery method is DbSchenker (pickup delivery) */}
      <Dialog
        open={openSchenkerDialog}
        onClose={() => setOpenSchenkerDialog(false)}
        title="Huomio tilauksestasi"
        subtitle={
          dynamicProduct?.deliveryProducts!['Pickup']
            ? dynamicProduct?.deliveryProducts!['Pickup'].notes
            : ''
        }
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenSchenkerDialog(false)}
          />
        ]}
      />
      {/* Dialog if any error occurs */}
      {error && (
        <Dialog
          open={true}
          onClose={() => {}}
          title="Huomio tilauksestasi"
          subtitle="Jokin meni toimituspäivän haussa pieleen. Palaa takaisin ja yritä uudelleen."
          actionComponents={[
            <Button
              label="Takaisin"
              color="primary"
              disablePadding
              onClick={goBack}
            />
          ]}
        />
      )}
    </>
  )
}

export default RedeemDate
