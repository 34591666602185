import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { isBefore, isAfter, addDays } from 'date-fns'
import { formatDate } from 'utils/common'
import { Login, TextField, Button, ErrorPage, Dialog } from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { ReactComponent as VennerLogo } from 'assets/venner-logo.svg'
import { changeRedeem, addDynamicProduct } from 'features/redeem'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { AppState } from 'store'

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 500px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 500px)': {
      height: 'calc(100vh - 56px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}))

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const formResultQuery = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      result
    }
  }
`

const serviceEngagement = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      metadata {
        key
        value
      }
    }
  }
`

const Redeem: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { products } = useSelector((state: AppState) => state.airtableData)
  const { isDynamicProduct, dynamicProduct } = useSelector(
    (state: AppState) => state.redeem
  )
  const [dynamicProductRedeem, setDynamicProductRedeem] = useState<string>()
  const [code, setCode] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const {
    data: seData,
    loading: seLoading,
    error: seError
  } = useQuery(serviceEngagement, {
    variables: {
      id
    }
  })
  const [
    resultCall,
    { data, loading: queryLoading, error: queryError, stopPolling }
  ] = useLazyQuery(formResultQuery, {
    pollInterval: 500
  })
  const [formCall, { loading: mutationLoading }] = useMutation(formQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.ingestForm.errors) {
        resultCall({ variables: { id: mutationResult.ingestForm.result } })
      } else {
        setErrorText('Väärä lahjakoodi')
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setError(true)
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 0, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch ServiceEngagement to set text based on the order
  useEffect(() => {
    // For some reason getting errors unless checking metadata with all of these if-statements?
    if (seData && products) {
      if (seData.serviceEngagement) {
        if (seData.serviceEngagement.metadata) {
          if (seData.serviceEngagement.metadata.length > 0) {
            seData.serviceEngagement.metadata.forEach(
              (v: {
                key: string
                value: { id: string; type: 'Home' | 'Pickup' }
              }) => {
                // If key 'dynamicProductDetails', this means that this engagement has a dynamic product
                if (v.key === 'dynamicProductDetails') {
                  const findProduct = products.find((p) => p.id === v.value.id)
                  if (findProduct) {
                    const dynamicProduct = JSON.parse(
                      JSON.stringify(findProduct)
                    )
                    dynamicProduct.type = v.value.type
                    dispatch(addDynamicProduct({ dynamicProduct }))
                  }
                  dispatch(
                    changeRedeem({ value: true, stateKey: 'isDynamicProduct' })
                  )
                }
              }
            )
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seData, products])

  // If ServiceEngagement query gives error, (wrong id), go back
  useEffect(() => {
    if (seError) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seError])

  useEffect(() => {
    if (isDynamicProduct && dynamicProduct) {
      if (dynamicProduct.redeemValidFrom && dynamicProduct.redeemValidTo) {
        let redeemActive = true
        const firstDate = new Date(dynamicProduct.redeemValidFrom)
        const lastDate = new Date(dynamicProduct.redeemValidTo)
        let today = new Date()
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)
        if (
          isBefore(addDays(today, +1), firstDate) ||
          isAfter(today, lastDate)
        ) {
          redeemActive = false
        }
        if (!redeemActive) {
          const startDate = formatDate(dynamicProduct.redeemValidFrom)
          const endDate = formatDate(dynamicProduct.redeemValidTo)
          setOpenDialog(true)
          setDynamicProductRedeem(
            `Tuotteen ${dynamicProduct.text} lunastus on mahdollista aikavälillä ${startDate} - ${endDate}.`
          )
        }
      }
    }
  }, [isDynamicProduct, dynamicProduct])

  const handleFormResult = (
    isCodeCorrect: boolean,
    isStatusOpened: boolean,
    isQuotaLeft: boolean
  ) => {
    stopPolling()
    if (isCodeCorrect && isStatusOpened) {
      if (isQuotaLeft) {
        dispatch(changeRedeem({ value: code, stateKey: 'code' }))
        dispatch(changeRedeem({ value: id, stateKey: 'engagementId' }))
        history.push('/redeem-welcome')
      } else {
        setErrorText('Kaikki tuotteet ovat jo lunastettu tällä koodilla')
      }
    } else {
      setErrorText('Väärä lahjakoodi')
    }
  }

  // check if code is correct
  useEffect(() => {
    if (data) {
      if (
        data.formResult.result.hasOwnProperty('isCodeCorrect') &&
        data.formResult.result.hasOwnProperty('isStatusOpened') &&
        data.formResult.result.hasOwnProperty('isQuotaLeft')
      ) {
        handleFormResult(
          data.formResult.result.isCodeCorrect,
          data.formResult.result.isStatusOpened,
          data.formResult.result.isQuotaLeft
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    formCall({
      variables: {
        id: process.env.REACT_APP_CHECK_REDEEM_ID,
        form: {
          engagementId: id,
          code: code,
          email: 'none'
        }
      }
    })
  }

  if (error || queryError) {
    return (
      <ErrorPage.Status500
        text="Jokin meni palvelussamme pieleen."
        errorText="Yritä myöhemmin uudelleen."
      />
    )
  }

  return (
    <>
      <form onSubmit={submit} className={classes.contentArea}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <VennerLogo style={{ width: '130px' }} />
          <Login
            maxWidth="sm"
            headline="Syötä lahjakoodi"
            subtitle={
              isDynamicProduct && dynamicProduct
                ? `Tervetuloa kokkaamaan kanssamme! Lahjakoodillasi voit tilata tuotteen ${
                    dynamicProduct.text
                  } ${
                    dynamicProduct.type === 'Home'
                      ? 'kotiintoimituksena'
                      : dynamicProduct.type === 'Pickup'
                      ? 'lähimmälle noutopisteelle osoitteesi perusteella'
                      : ''
                  }.`
                : 'Tervetuloa kokkaamaan kanssamme! Lahjakoodillasi voit tilata Sesonkiruokalaatikon kotiintoimituksena.'
            }
            contentComponents={[
              <TextField
                fullWidth
                autoFocus
                label={dynamicProductRedeem ? '' : 'Syötä lahjakoodi'}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={dynamicProductRedeem ? true : false}
                errorText={errorText || dynamicProductRedeem}
              />
            ]}
            actionComponents={[
              <Button
                color="primary"
                label="Eteenpäin"
                fullWidth
                disabled={
                  mutationLoading ||
                  queryLoading ||
                  seLoading ||
                  dynamicProductRedeem
                    ? true
                    : false
                }
                type="submit"
              />
            ]}
          />
          {mutationLoading || queryLoading || seLoading ? (
            <SpinnerMui
              style={{
                position: 'absolute',
                bottom: '36px',
                width: '100%',
                textAlign: 'center'
              }}
            />
          ) : null}
        </div>
      </form>
      {/* Dialog if redeem is not yet opened */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Huomio lunastuksesta"
        subtitle={dynamicProductRedeem}
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenDialog(false)}
          />
        ]}
      />
    </>
  )
}

export default Redeem
