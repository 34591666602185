import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useCheckTokenExpiration } from 'components/Common/Hooks/useCheckTokenExpiration'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Container,
  Heading,
  Select,
  BottomBar,
  ListItem,
  ErrorPage,
  AppBar
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import Collapse from '@material-ui/core/Collapse'
import { initCampaigns } from 'features/campaignsAirtableData'
import { AppState } from 'store'
import { Campaigns, Product } from 'types/types'
import { changeBulkOrder } from 'features/BulkOrder'
import { setSeasonBoxTexts } from 'components/Order/OrderBoxType'
import { formatDate } from 'utils/common'
import { useLogOut } from 'components/BulkOrder/LoginPage'
import { defaultDaysAheadBuffer } from 'utils/common'

const useStyles = makeStyles(() => ({
  collapseContainer: {
    backgroundColor: '#fafafa',
    border: '1px solid #f2f2f2',
    borderRadius: '5px',
    margin: '15px',
    marginTop: '0'
  }
}))

const getCampaigns = gql`
  query fetchCampaigns($id: ID!) {
    fetchDataview(id: $id)
  }
`

const SelectCampaign: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  const { campaigns } = useSelector(
    (state: AppState) => state.campaignAirtableData
  )
  const { products } = useSelector((state: AppState) => state.airtableData)
  const { selectedCampaignID, selectedCampaign: ReduxSelectedCampaign } =
    useSelector((state: AppState) => state.bulkOrder)

  const [selectCampaign, setSelectCampaign] = useState(selectedCampaignID)
  const [selectedCampaign, setSelectedCampaign] = useState<
    Campaigns | undefined
  >(ReduxSelectedCampaign.id ? ReduxSelectedCampaign : undefined)
  const [open, setOpen] = useState(false)

  const goForward = (e: React.SyntheticEvent) => {
    e.preventDefault()
    history.push('/bulkorder-select-email-list')
  }

  const [
    getCampaings,
    { loading: campaignsLoading, data: campaignsData, error: campaignsError }
  ] = useLazyQuery(getCampaigns, {
    variables: { id: process.env.REACT_APP_FETCH_CAMPAIGN_ID },
    fetchPolicy: 'network-only'
  })
  const { loading } = useCheckTokenExpiration(getCampaings)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 1, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Save fetched campaigns data (From Airtable)
  useEffect(() => {
    if (campaignsData && campaigns.length === 0) {
      dispatch(
        initCampaigns({
          campaigns: campaignsData.fetchDataview.data.records
        })
      )
    }
    dispatch(changeBulkOrder({ value: 1, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsData])

  const getProductsFromAirtableData = (productIds: string[]) => {
    const returnProducts: Product[] = []
    productIds.forEach((id: string) => {
      products.forEach((product) => {
        if (Object.prototype.hasOwnProperty.call(product, 'types')) {
          product.types?.forEach((type) => {
            if (type.id === id) {
              returnProducts.push(type)
            }
          })
        } else if (product.id === id) {
          returnProducts.push(product)
        }
      })
    })

    return returnProducts
  }

  const campaignOptions: { label: string; value: string }[] = []

  campaigns.forEach((campaign) => {
    if (
      campaign.fields.Name &&
      campaign.fields['Redeem Expiration Date'] &&
      campaign.fields['Slug (url)'] &&
      campaign.fields['Email-logo'] &&
      campaign.fields['Email-title-FI'] &&
      campaign.fields['Email-title-EN'] &&
      campaign.fields['Email-content-FI'] &&
      campaign.fields['Email-content-EN'] &&
      campaign.fields['Foreign-delivery-date']
    ) {
      campaignOptions.push({
        label: campaign.fields.Name,
        value: campaign.id
      })
    }
  })

  const handleCampaignChange = (e: string) => {
    const camp = campaigns.find((camp) => camp.id === e)
    if (camp) {
      setSelectCampaign(e)
      setSelectedCampaign(camp)
      dispatch(
        changeBulkOrder({
          value: {
            id: camp.id,
            name: camp.fields.Name,
            expirationDate: camp.fields['Redeem Expiration Date'],
            url: camp.fields['Slug (url)'].trim(),
            emailLogoUrl: camp.fields['Email-logo'][0].url,
            emailCompanyName: camp.fields['Email-company-name'] || '',
            emailTitleFi: camp.fields['Email-title-FI'],
            emailTitleEn: camp.fields['Email-title-EN'],
            emailContentFi: camp.fields['Email-content-FI'],
            emailContentEn: camp.fields['Email-content-EN'],
            foreignDeliveryDate: camp.fields['Foreign-delivery-date'],
            deliveryValidTo: camp.fields['Delivery Valid to'] || '',
            deliveryValidToSeasonBox:
              camp.fields['Delivery Valid to-sesonki'] || '',
            daysAheadBuffer:
              camp.fields.DaysAheadBuffer || defaultDaysAheadBuffer,
            daysAheadBufferSeasonBox:
              camp.fields['DaysAheadBuffer-sesonki'] || defaultDaysAheadBuffer,
            selectableWeeksCount: camp.fields.SelectableWeeksCount || 4,
            products: getProductsFromAirtableData(camp.fields.Products)
          },
          stateKey: 'campaign'
        })
      )
      dispatch(changeBulkOrder({ value: e, stateKey: 'selectedCampaignID' }))
      dispatch(changeBulkOrder({ value: camp, stateKey: 'selectedCampaign' }))
    }
  }

  const goBack = () => {
    dispatch(changeBulkOrder({ value: 0, stateKey: 'currentView' }))
    history.push('/venner-login')
  }

  return (
    <>
      {campaignsError ? (
        <ErrorPage.Status500
          text="Jokin meni palvelussamme pieleen."
          errorText="Yritä myöhemmin uudelleen."
          errorType=" "
        />
      ) : (
        <Container>
          <AppBar
            text="Kirjautumissivulle"
            mainAction={{ icon: 'ArrowBack', onClick: goBack }}
            actions={[
              {
                icon: 'ExitToApp',
                onClick: logOut
              }
            ]}
          />
          {(campaignsLoading || loading) && !products ? (
            <SpinnerMui
              backdrop
              color="white"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          ) : null}
          <Heading title="Valitse kampanja" style={{ marginTop: '50px' }} />
          <Select
            name="selectCampaign"
            label="Valitse kampanja"
            value={selectCampaign}
            onChange={(e) => handleCampaignChange(e.target.value)}
            options={campaignOptions}
          />
          <Heading title="Kampanjan tiedot" align="left" />
          <div className={classes.collapseContainer}>
            <ListItem
              maxWidth="sm"
              text="Tuotteet"
              metaIcon={open ? 'ExpandLess' : 'ExpandMore'}
              onClick={() => setOpen(!open)}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
              {selectedCampaign &&
                selectedCampaign.fields['Name (from Products)'].map(
                  (value, index) => (
                    <ListItem
                      key={Math.random()}
                      text={value}
                      subtitles={[
                        setSeasonBoxTexts(
                          selectedCampaign.fields['Type (from Products)'][index]
                        )
                      ]}
                    />
                  )
                )}
            </Collapse>
          </div>
          {selectedCampaign && (
            <>
              <Heading
                subtitle={`Viimeinen lunastuspäivä: ${formatDate(
                  selectedCampaign?.fields['Redeem Expiration Date']
                )}`}
                align="left"
              />
            </>
          )}
          <div style={{ marginBottom: '120px' }}></div>
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                color="primary"
                disablePadding
                fullWidth
                disabled={
                  !(
                    selectedCampaign?.fields.Products &&
                    selectedCampaign.fields['Redeem Expiration Date'] &&
                    selectedCampaign.fields['Slug (url)']
                  )
                }
                label="Seuraava"
                onClick={goForward}
              />
            ]}
          />
        </Container>
      )}
    </>
  )
}

export default SelectCampaign
