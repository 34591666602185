import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  Switch,
  BottomBar
} from 'uniqore-components'
import { changeOrder } from 'features/order'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'

const OrderContactCompany: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { companyContact, deliveryContact, orderType } = useSelector(
    (state: AppState) => state.order
  )
  const [companyNameValid, setCompanyNameValid] = useState<string>('')
  const [businessIdValid, setBusinessIdValid] = useState<string>('')
  const [addressValid, setAddressValid] = useState<string>('')
  const [zipValid, setZipValid] = useState<string>('')
  const [cityValid, setCityValid] = useState<string>('')
  const [deliveryCompanyNameValid, setDeliveryCompanyNameValid] =
    useState<string>('')
  const [deliveryAddressValid, setDeliveryAddressValid] = useState<string>('')
  const [deliveryZipValid, setDeliveryZipValid] = useState<string>('')
  const [deliveryCityValid, setDeliveryCityValid] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeUtils({ value: 4, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateFields = () => {
    let valid = true
    if (companyContact.businessId.length < 7) {
      setBusinessIdValid('Tarkista antamasi Y-tunnus')
      valid = false
    } else {
      setBusinessIdValid('')
    }
    if (companyContact.companyName === '') {
      setCompanyNameValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setCompanyNameValid('')
    }
    if (companyContact.address === '') {
      setAddressValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setAddressValid('')
    }
    if (companyContact.zip.length !== 5) {
      setZipValid(
        'Postiosoitteen tulee olla 5 merkkiä pitkä, huomio tyhjät välilyönnit alussa tai lopussa'
      )
      valid = false
    } else {
      setZipValid('')
    }
    if (companyContact.city === '') {
      setCityValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setCityValid('')
    }
    if (!companyContact.deliveryAddress) {
      if (deliveryContact.companyName === '') {
        setDeliveryCompanyNameValid('Kenttä ei voi olla tyhjä')
        valid = false
      } else {
        setDeliveryCompanyNameValid('')
      }
      if (deliveryContact.address === '') {
        setDeliveryAddressValid('Kenttä ei voi olla tyhjä')
        valid = false
      } else {
        setDeliveryAddressValid('')
      }
      if (deliveryContact.zip.length !== 5) {
        setDeliveryZipValid(
          'Postiosoitteen tulee olla 5 merkkiä pitkä, huomio tyhjät välilyönnit alussa tai lopussa'
        )
        valid = false
      } else {
        setDeliveryZipValid('')
      }
      if (deliveryContact.city === '') {
        setDeliveryCityValid('Kenttä ei voi olla tyhjä')
        valid = false
      } else {
        setDeliveryCityValid('')
      }
    }
    return valid
  }

  const goBack = () => {
    dispatch(changeUtils({ value: 3, stateKey: 'currentView' }))
    history.push('/order-cart')
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      if (orderType === 'Cargo') {
        dispatch(changeUtils({ value: 5, stateKey: 'currentView' }))
        history.push('/order-delivery-date')
      } else {
        dispatch(changeUtils({ value: 5, stateKey: 'currentView' }))
        history.push('/order-contact-person')
      }
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Yrityksen yhteystiedot"
            body={
              orderType !== 'Cargo'
                ? 'Lisää tilaavan yrityksen yhteystiedot'
                : 'Lisää tilaavan yrityksen yhteystiedot ja valitse alta, mikäli toimitusosoite on eri.'
            }
          />
          <TextField
            value={companyContact.companyName}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'companyName'
                })
              )
            }
            fullWidth
            label="Yrityksen nimi"
            errorText={companyNameValid}
          />
          <TextField
            value={companyContact.businessId}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'businessId'
                })
              )
            }
            fullWidth
            label="Y-tunnus"
            errorText={businessIdValid}
          />
          <TextField
            name="ship-address"
            autoComplete="shipping street-address"
            value={companyContact.address}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'address'
                })
              )
            }
            fullWidth
            label="Osoite"
            errorText={addressValid}
          />
          <TextField
            name="ship-zip"
            autoComplete="shipping postal-code"
            value={companyContact.zip}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'zip'
                })
              )
            }
            fullWidth
            label="Postinumero"
            errorText={zipValid}
          />
          <TextField
            name="ship-city"
            autoComplete="shipping locality"
            value={companyContact.city}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'companyContact',
                  stateKeyExtra: 'city'
                })
              )
            }
            fullWidth
            label="Kaupunki"
            errorText={cityValid}
          />
          {orderType === 'Cargo' && (
            <>
              <Switch
                color="primary"
                label="Toimitusosoite on sama kuin yllä"
                checked={companyContact.deliveryAddress}
                onChange={(e) =>
                  dispatch(
                    changeOrder({
                      value: e.target.checked,
                      stateKey: 'companyContact',
                      stateKeyExtra: 'deliveryAddress'
                    })
                  )
                }
              />
              <Collapse
                in={!companyContact.deliveryAddress}
                timeout="auto"
                unmountOnExit
              >
                <Heading title="Toimitusosoite" />
                <TextField
                  value={deliveryContact.companyName}
                  onChange={(e) =>
                    dispatch(
                      changeOrder({
                        value: e.target.value,
                        stateKey: 'deliveryContact',
                        stateKeyExtra: 'companyName'
                      })
                    )
                  }
                  fullWidth
                  label="Yrityksen nimi"
                  errorText={deliveryCompanyNameValid}
                />
                <TextField
                  name="ship-address"
                  autoComplete="shipping street-address"
                  value={deliveryContact.address}
                  onChange={(e) =>
                    dispatch(
                      changeOrder({
                        value: e.target.value,
                        stateKey: 'deliveryContact',
                        stateKeyExtra: 'address'
                      })
                    )
                  }
                  fullWidth
                  label="Postiosoite"
                  errorText={deliveryAddressValid}
                />
                <TextField
                  name="ship-zip"
                  autoComplete="shipping postal-code"
                  value={deliveryContact.zip}
                  onChange={(e) =>
                    dispatch(
                      changeOrder({
                        value: e.target.value,
                        stateKey: 'deliveryContact',
                        stateKeyExtra: 'zip'
                      })
                    )
                  }
                  fullWidth
                  label="Postinumero"
                  errorText={deliveryZipValid}
                />
                <TextField
                  name="ship-city"
                  autoComplete="shipping locality"
                  value={deliveryContact.city}
                  onChange={(e) =>
                    dispatch(
                      changeOrder({
                        value: e.target.value,
                        stateKey: 'deliveryContact',
                        stateKeyExtra: 'city'
                      })
                    )
                  }
                  fullWidth
                  label="Kaupunki"
                  errorText={deliveryCityValid}
                />
              </Collapse>
            </>
          )}
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default OrderContactCompany
