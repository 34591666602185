import { onError } from '@apollo/client/link/error'
import { useLogOut } from 'components/BulkOrder/LoginPage'

export const errorLink = onError(({ networkError }) => {
  const logOut = useLogOut()
  // @ts-ignore
  if (networkError && networkError.statusCode === 401) {
    logOut()
  }
})
