import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Container, ListItem, Heading, i18n } from 'uniqore-components'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { initReactI18next, useTranslation } from 'react-i18next'
import translationFile from 'assets/translation.json'
import { Product } from 'types/types'

i18n.use(initReactI18next).init({
  resources: translationFile,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

const SelectPackage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { language, redemptionCode, products } = useSelector(
    (state: AppState) => state.campaignRedeem
  )
  const [boxes, setBoxes] = useState<Product[]>([])

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      i18n.changeLanguage(language)
      dispatch(changeCampaignRedeem({ value: 2, stateKey: 'currentView' }))
    }
  }, [language, redemptionCode, history, dispatch])

  const goBack = () => {
    history.push('/campaign-select-package')
  }

  useEffect(() => {
    const setProducts: Product[] = []
    products.forEach((product: Product) => {
      if (product.sku.startsWith('ruokalaatikko')) {
        setProducts.push(product)
      }
    })
    setBoxes(setProducts)
  }, [products])

  const getProductText = (text: string) => {
    if (text === 'Normaali') {
      return i18n.language === 'fi' ? 'Perus Sesonkiruokalaatikko' : 'Normal'
    }
    if (text === 'Vegaaninen') {
      return i18n.language === 'fi' ? 'Vegaaninen' : 'Vegan'
    }
    if (text === 'Vegaaninen ja gluteeniton') {
      // Use either nut-free or gluten-free option
      // return i18n.language === 'fi'
      //   ? 'Vegaaninen ja pähkinätön'
      //   : 'Vegan & Nut-free'
      return i18n.language === 'fi'
        ? 'Vegaaninen ja gluteeniton'
        : 'Vegan & Gluten-free'
    }
    if (text === 'Gluteeniton') {
      // Use either nut-free or gluten-free option
      //return i18n.language === 'fi' ? 'Pähkinätön' : 'Nut-free'
      return i18n.language === 'fi' ? 'Gluteeniton' : 'Gluten-free'
    }
  }

  const handleSelect = (product: Product) => {
    dispatch(changeCampaignRedeem({ value: product.text, stateKey: 'boxType' }))
    dispatch(
      changeCampaignRedeem({ value: product.id, stateKey: 'redeemedProduct' })
    )
    dispatch(
      changeCampaignRedeem({ value: product.sku, stateKey: 'productSku' })
    )
    dispatch(
      changeCampaignRedeem({
        value: product.gtin as string,
        stateKey: 'productGtin'
      })
    )
    dispatch(
      changeCampaignRedeem({
        value: getProductText(product.text) || '',
        stateKey: 'selectedProductType'
      })
    )
    // TODO: DISCONTINUED CONTRY SELECTION
    // history.push('/campaign-select-country')
    history.push('/campaign-contact-details')
  }

  return (
    <Container>
      <AppBar
        text={t('button-back')}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => goBack()
        }}
      />
      <Heading
        title={t('select-product-type')}
        subtitle={t('select-product-type-subtitle')}
      />

      {boxes
        .slice()
        .sort((a, b) => a.showOrder! - b.showOrder!)
        .map((product: Product, i: number) => (
          <ListItem
            key={i}
            image={product.image}
            text={getProductText(product.text)}
            onClick={() => handleSelect(product)}
            metaIcon="ArrowForward"
            hightlightMeta
          />
        ))}
    </Container>
  )
}

export default SelectPackage
