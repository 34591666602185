import { createSlice } from '@reduxjs/toolkit'
import { Product, Shipping } from 'types/types'
import { isBefore, isAfter, addDays } from 'date-fns'

interface ProductsData {
  id: string
  createdTime: string
  fields: ProductsTable
}

interface DeliveryProductsData {
  id: string
  createdTime: string
  fields: DeliveryProductsTable
}

interface Image {
  url: string
}

type Type =
  | 'Normaali'
  | 'Vegaaninen'
  | 'Vegaaninen ja gluteeniton'
  | 'Gluteeniton'
  | 'Toimitusmaksu'
  | 'Hyväntekeväisyys'
  | 'Lahjakortti'
  | ''

interface ProductsTable {
  SKU: string
  Name: string
  'Name-EN': string
  Type: Type
  GTIN: string
  Description: string
  'Description-EN': string
  'Long Description': string
  Image: Image[]
  Active: boolean
  'Fixed Product': boolean
  'Min Quantity': number
  Price: number
  Support: number
  'Tax %': number
  Tax: number
  'Total Price': number
  'Sales Valid from': string
  'Sales Valid to': string
  'Redeem Valid from': string
  'Redeem Valid to': string
  'Delivery Valid from': string
  'Delivery Valid to': string
  SelectableWeeksCount: number
  DaysAheadBuffer: number
  'Video url': string
  'Picture (video-fallback)': Image[]
  'Recipe url': string
  'Delivery Products': string[]
}

interface DeliveryProductsTable {
  id: string
  SKU: string
  Type: 'Cargo' | 'Home' | 'Pickup'
  Price: number
  'Tax %': number
  Tax: number
  'Total Price': number
  Notes: string
}

interface AirtableDataState {
  boxMinimumOrder: number
  shippingGiftcard: Shipping
  shippingCargo: Shipping
  products: Product[]
}

const initialState: AirtableDataState = {
  boxMinimumOrder: 10,
  shippingGiftcard: {
    id: '',
    price: 10,
    tax: 2.55,
    taxPercent: 25.5,
    priceWithTaxes: 12.55
  },
  shippingCargo: {
    id: '',
    price: 8,
    tax: 2.04,
    taxPercent: 25.5,
    priceWithTaxes: 10.04
  },
  products: []
}

interface InitProducts {
  payload: {
    products: ProductsData[]
    deliveryProducts: DeliveryProductsData[]
  }
}

const airtableDataSlice = createSlice({
  name: 'airtableData',
  initialState,
  reducers: {
    initProducts: (state, { payload }: InitProducts) => {
      let addedBox: boolean = false
      let shippingGiftcard: Shipping = state.shippingGiftcard
      let shippingCargo: Shipping = state.shippingCargo
      const products: Product[] = [] // All products are stored here
      const types: Product[] = [] // Different Seasonbox-types are stored here
      // Loop through every row in Airtables Products -table
      payload.products.forEach((v: ProductsData) => {
        const { fields: item } = v
        // prevents accidental empty rows in Airtable from crashing the app
        if (!item.SKU && !item.Name) {
          return
        }
        if (!item['Fixed Product']) {
          // is Dynamic Product
          let product: Product = {
            id: v.id,
            isActive: item.Active || false,
            quantity: 0,
            sku: item.SKU.trim() || '',
            gtin: item.GTIN || '',
            image: item.Image ? item.Image[0].url : '',
            text: item.Name || '',
            textEn: item['Name-EN'] || '',
            longText: item['Long Description'] || '',
            subtitles: [item.Description],
            subtitlesEn: [item['Description-EN']],
            minQuantity: item['Min Quantity'],
            price: item.Price ? Number(item.Price.toFixed(2)) : 0,
            priceWithTaxes: item['Total Price']
              ? Number(item['Total Price'].toFixed(2))
              : 0,
            tax: item.Tax ? Number(item.Tax.toFixed(2)) : 0,
            taxPercent: item['Tax %'] ? Math.round(item['Tax %'] * 100) : 0,
            support: item.Support ? Number(item.Support.toFixed(2)) : 0,
            type: undefined,
            isDynamic: true,
            selectableWeeksCount: item.SelectableWeeksCount,
            daysAheadBuffer: item.DaysAheadBuffer,
            videoUrl: item['Video url'] ? item['Video url'].trim() : undefined,
            picture: item['Picture (video-fallback)']
              ? item['Picture (video-fallback)'][0].url
              : undefined,
            recipeUrl: item['Recipe url']
              ? item['Recipe url'].trim()
              : undefined,
            salesValidFrom: item['Sales Valid from'],
            salesValidTo: item['Sales Valid to'],
            redeemValidFrom: item['Redeem Valid from'],
            redeemValidTo: item['Redeem Valid to'],
            deliveryValidFrom: item['Delivery Valid from'],
            deliveryValidTo: item['Delivery Valid to'],
            deliveryProducts: {}
          }
          item['Delivery Products'] &&
            item['Delivery Products'].forEach((id) => {
              payload.deliveryProducts.forEach((v) => {
                if (v.id === id) {
                  product.deliveryProducts![v.fields.Type] = {
                    id: v.id,
                    sku: v.fields.SKU,
                    type: v.fields.Type,
                    price: Number(v.fields.Price.toFixed(2)),
                    priceWithTaxes: Number(v.fields['Total Price'].toFixed(2)),
                    tax: Number(v.fields.Tax.toFixed(2)),
                    taxPercent: Math.round(v.fields['Tax %'] * 100),
                    notes: v.fields.Notes ? v.fields.Notes.trim() : ''
                  }
                }
              })
            })
          // Check sales valid from and to
          let salesActive = true
          if (product.salesValidFrom && product.salesValidTo) {
            const firstDate = new Date(product.salesValidFrom)
            const lastDate = new Date(product.salesValidTo)
            let today = new Date()
            today.setHours(0)
            today.setMinutes(0)
            today.setSeconds(0)
            if (
              isBefore(addDays(today, +1), firstDate) ||
              isAfter(today, lastDate)
            ) {
              salesActive = false
            }
          }
          // If sales is active, check if the product is active
          if (salesActive) {
            product.isActive = item.Active || false
          } else {
            product.isActive = false
          }
          products.push(product)
        } else {
          // is Sesonkiruokalaatikko
          if (
            item.SKU !== 'toimitusmaksu' &&
            item.SKU !== 'toimitusmaksu-toimipiste'
          ) {
            const product: Product = {
              id: v.id,
              isActive: item.Active || false,
              quantity: 0,
              sku: item.SKU.trim(),
              gtin: item.GTIN,
              image: item.Image ? item.Image[0].url : '',
              text: item.Name || '',
              subtitles: [item.Description],
              price: Number(item.Price.toFixed(2)),
              priceWithTaxes: Number(item['Total Price'].toFixed(2)),
              tax: Number(item.Tax.toFixed(2)),
              taxPercent: Math.round(item['Tax %'] * 100),
              support: Number(item.Support.toFixed(2)),
              isDynamic: false,
              type:
                item.SKU === 'lahjoitus'
                  ? 'Charity'
                  : item.SKU === 'lahjakortti-ruokalaatikko_kotiinkuljetuksella'
                  ? 'Giftcard'
                  : 'Cargo',
              showOrder:
                item.SKU === 'lahjoitus'
                  ? 3
                  : item.SKU === 'lahjakortti-ruokalaatikko_kotiinkuljetuksella'
                  ? 1
                  : 2
            }
            if (item.SKU.split('-')[0] === 'ruokalaatikko') {
              if (!addedBox) {
                addedBox = true
                const editTextProduct: Product = JSON.parse(
                  JSON.stringify(product)
                )
                editTextProduct.text = 'Kertatoimitus toimipisteeseen'
                products.push(editTextProduct)
              }
              product.text = item.Type || ''
              product.showOrder =
                item.SKU === 'ruokalaatikko-norm'
                  ? 1
                  : item.SKU === 'ruokalaatikko-glut'
                  ? 2
                  : item.SKU === 'ruokalaatikko-vg'
                  ? 3
                  : 4
              types.push(product)
            } else {
              products.push(product)
            }
          }
          if (item.SKU === 'toimitusmaksu') {
            shippingGiftcard = {
              id: v.id,
              price: Number(item.Price.toFixed(2)),
              tax: Number(item.Tax.toFixed(2)),
              taxPercent: Math.round(item['Tax %'] * 100),
              priceWithTaxes: Number(item['Total Price'].toFixed(2))
            }
          }
          if (item.SKU === 'toimitusmaksu-toimipiste') {
            shippingCargo = {
              id: v.id,
              price: Number(item.Price.toFixed(2)),
              tax: Number(item.Tax.toFixed(2)),
              taxPercent: Math.round(item['Tax %'] * 100),
              priceWithTaxes: Number(item['Total Price'].toFixed(2))
            }
          }
        }
      })
      // add types to 'main' product box-type, this is only used in Sesonkiruokalaatikko
      const findProduct = products.find((p) => p.type === 'Cargo')
      if (findProduct && !findProduct.isDynamic) {
        findProduct.types = types
      }
      state.shippingGiftcard = shippingGiftcard
      state.shippingCargo = shippingCargo
      state.products = products.sort((a, b) => a.showOrder! - b.showOrder!)
    }
  }
})

export const { initProducts } = airtableDataSlice.actions
export default airtableDataSlice.reducer
