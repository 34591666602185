import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Heading,
  Login,
  TextField,
  Button,
  Verification
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { ReactComponent as VennerLogo } from 'assets/venner-logo.svg'
import { changeUtils } from 'features/utils'
import { gql, useMutation } from '@apollo/client'
import { AppState } from 'store'
import { validateEmail } from 'api/cloudFunctions'

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 700px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 700px)': {
      height: 'calc(100vh - 56px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}))

const loginQuery = gql`
  mutation loginCustomer($auth: ID!, $code: String, $email: String!) {
    loginCustomer(auth: $auth, code: $code, email: $email) {
      token
      error
    }
  }
`

const LoginPage: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [errorText, setErrotText] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const { token } = useSelector((state: AppState) => state.utils)
  const [login, { loading }] = useMutation(loginQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.loginCustomer.error) {
        if (!mutationResult.loginCustomer.token) {
          setEmailSent(true)
        } else {
          window.localStorage.setItem(
            'token',
            mutationResult.loginCustomer.token
          )
          dispatch(
            changeUtils({
              value: mutationResult.loginCustomer.token,
              stateKey: 'token'
            })
          )
        }
      } else {
        setErrotText('Väärä koodi')
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setError(true)
      }
    }
  })

  // checks local storage for token
  useEffect(() => {
    const getToken = window.localStorage.getItem('token')
    if (getToken) {
      dispatch(changeUtils({ value: getToken, stateKey: 'token' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if token set, go to StatusHome page
  useEffect(() => {
    if (token) {
      history.push('/status-home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const validate = () => {
    let valid = true
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      login({
        variables: {
          auth: process.env.REACT_APP_AUTHENTICATOR_ID,
          email: email
        }
      })
    }
  }

  const otpSubmit = (otp: string) => {
    login({
      variables: {
        auth: process.env.REACT_APP_AUTHENTICATOR_ID,
        code: otp,
        email: email
      }
    })
  }

  const sendEmailAgain = () => {
    setErrotText('')
    setEmailSent(false)
  }

  return (
    <>
      {!emailSent ? (
        <>
          <AppBar
            text="Etusivu"
            maxWidth="sm"
            mainAction={{
              icon: 'ArrowBack',
              onClick: () => history.push('/')
            }}
          />
          <form onSubmit={submit} className={classes.contentArea}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <VennerLogo style={{ width: '130px' }} />
              <Login
                maxWidth="sm"
                headline="Tunnistaudu sähköpostillasi"
                subtitle="Mikäli olet tilannut palvelumme kautta aiemmin, niin lähetämme sinulle sähköpostiisi kertakirjautumiskoodin."
                contentComponents={[
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorText={emailValid}
                    fullWidth
                    label="Sähköpostiosoitteesi"
                    name="email"
                    autoComplete="email"
                  />
                ]}
                actionComponents={[
                  <Button
                    color="primary"
                    label="Eteenpäin"
                    fullWidth
                    disabled={loading}
                    type="submit"
                  />
                ]}
              />
              {error && <Heading body="Jokin meni pieleen. Yritä uudelleen." />}
              {loading && (
                <SpinnerMui
                  style={{
                    position: 'absolute',
                    bottom: '36px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              )}
            </div>
          </form>
        </>
      ) : (
        <>
          <AppBar
            text="Lähetä uudestaan"
            maxWidth="sm"
            mainAction={{
              icon: 'ArrowBack',
              onClick: sendEmailAgain
            }}
          />
          <div className={classes.contentArea}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <VennerLogo style={{ width: '130px' }} />
              <Verification
                maxWidth="sm"
                headline="Syötä tunniste"
                subtitle="Saat sähköpostiisi 6-numeroisen koodin, syötä se alle. Jos et saanut koodia, tarkista roskapostisi tai syötä
                sähköpostisi uudelleen."
                autoFocus
                showNumpad={true}
                showPassword
                onSubmit={otpSubmit}
                errorText={errorText}
              />
              {error && <Heading body="Jokin meni pieleen. Yritä uudelleen." />}
              {loading && (
                <SpinnerMui
                  style={{
                    position: 'absolute',
                    bottom: '36px',
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default LoginPage
