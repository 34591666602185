import { setContext } from '@apollo/client/link/context'

export const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Organization': process.env.REACT_APP_ORGANIZATION_ID
    }
  }
})
