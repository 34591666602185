import React, { useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ErrorPage, i18n } from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import Layout from 'components/Common/Layout'
import Welcome from 'components/Welcome'
import LoginPage from 'components/LoginPage'
import BulkOrderLogin from 'components/BulkOrder/LoginPage'
import BulkOrderSetPassword from 'components/BulkOrder/SetNewPassword'
import BulkOrderResetPassword from 'components/BulkOrder/ResetPassword'
import SelectCampaign from 'components/BulkOrder/SelectCampaign'
import SelectEmailList from 'components/BulkOrder/SelectEmailList'
import CompanyDetails from 'components/BulkOrder/CompanyDetails'
import ContactPerson from 'components/BulkOrder/ContactPerson'
import InvoiceDetails from 'components/BulkOrder/InvoiceDetails'
import Summary from 'components/BulkOrder/Summary'
import Confirmation from 'components/BulkOrder/Confirmation'
import Order from 'components/Order/Order'
import OrderDeliveryMethod from 'components/Order/OrderDeliveryMethod'
import OrderCart from 'components/Order/OrderCart'
import OrderContactCompany from 'components/Order/OrderContactCompany'
import OrderDeliveryDate from 'components/Order/OrderDeliveryDate'
import OrderContactPerson from 'components/Order/OrderContactPerson'
// import OrderSupport from 'components/Order/OrderSupport'
import OrderBilling from 'components/Order/OrderBilling'
import OrderSummary from 'components/Order/OrderSummary'
import StatusOrders from 'components/Status/StatusOrders'
import StatusHome from 'components/Status/StatusHome'
import OrdersView from 'components/Status/OrdersView'
import SingleOrderView from 'components/Status/SingleOrderView'
import Redeem from 'components/Redeem/Redeem'
import RedeemOnboarding from 'components/Redeem/RedeemOnboarding'
import RedeemProduct from 'components/Redeem/RedeemProduct'
import RedeemContact from 'components/Redeem/RedeemContact'
import RedeemDelivery from 'components/Redeem/RedeemDelivery'
import RedeemDate from 'components/Redeem/RedeemDate'
import RedeemSummary from 'components/Redeem/RedeemSummary'
import RedeemConfirm from 'components/Redeem/RedeemConfirm'
import ProgressLine from 'components/Common/ProgressLine'
import ContactUs from 'components/Common/ContactUs'
import Snackbar from 'components/Common/Snackbar'
import Share from 'components/Common/Share'
import CampaignRedeem from 'components/CampaignRedeem/CampaignRedeem'
import CampaignRedeemCode from 'components/CampaignRedeem/RedeemCode'
import CampaignRedeemAlreadyUsed from 'components/CampaignRedeem/RedeemCodeAlreadyUsed'
import SelectPackage from 'components/CampaignRedeem/SelectPackage'
import SelectPackageType from 'components/CampaignRedeem/SelectPackageType'
import CampaignContactDetails from 'components/CampaignRedeem/RedeemContact'
import CampaignDeliveryInformation from 'components/CampaignRedeem/RedeemDeliveryInformation'
import CampaignDeliveryDate from 'components/CampaignRedeem/RedeemDeliveryDate'
import CampaignSummary from 'components/CampaignRedeem/RedeemSummary'
import CampaignRedeemConfirmation from 'components/CampaignRedeem/RedeemConfirm'
import NotFound from 'components/Common/NotFound'
import { initProducts } from 'features/airtableData'
import { AppState } from 'store'
import { initReactI18next } from 'react-i18next'
import translationFile from 'assets/translation.json'
import PrivateRoute from 'components/PrivateRoute'

const getProducts = gql`
  query fetchProducts($id: ID!) {
    fetchDataview(id: $id)
  }
`

const getDeliveryProducts = gql`
  query fetchDeliveryProducts($id: ID!) {
    fetchDataview(id: $id)
  }
`

if (window.location.pathname.substring(0, 9) === '/campaign') {
  i18n.use(initReactI18next).init({
    resources: translationFile,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const redeem = useSelector((state: AppState) => state.redeem)
  const campaignRedeem = useSelector((state: AppState) => state.campaignRedeem)
  const order = useSelector((state: AppState) => state.order)
  const bulkOrder = useSelector((state: AppState) => state.bulkOrder)
  const { currentView } = useSelector((state: AppState) => state.utils)
  const { showFeedbackDialog, showShare, snackbarOpen, snackbarText } =
    useSelector((state: AppState) => state.utils)
  const [currentPhase, setCurrentPhase] = useState<string>('')
  const [orderProgressLength, setOrderProgressLength] = useState<number>(8)
  const {
    loading: productsLoading,
    data: productsData,
    error: productsError
  } = useQuery(getProducts, {
    variables: { id: process.env.REACT_APP_FETCH_PRODUCTS_ID },
    fetchPolicy: 'network-only'
  })
  const {
    loading: deliveryProductsLoading,
    data: deliveryProductsData,
    error: deliveryProductsError
  } = useQuery(getDeliveryProducts, {
    variables: { id: process.env.REACT_APP_FETCH_DELIVERY_PRODUCTS },
    fetchPolicy: 'network-only'
  })

  // Save fetched products and delivery products data (From Airtable)
  useEffect(() => {
    if (productsData && deliveryProductsData) {
      dispatch(
        initProducts({
          products: productsData.fetchDataview.data.records,
          deliveryProducts: deliveryProductsData.fetchDataview.data.records
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData, deliveryProductsData])

  // Renders ProgressLine -component based on url
  useEffect(() => {
    if (window.location.pathname.substring(0, 7) === '/redeem') {
      setCurrentPhase('redeem')
    }
    if (window.location.pathname.substring(0, 6) === '/order') {
      setCurrentPhase('order')
    }
    if (window.location.pathname.substring(0, 10) === '/bulkorder') {
      setCurrentPhase('bulkorder')
    }
    if (window.location.pathname.substring(0, 9) === '/campaign') {
      setCurrentPhase('campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  // Sets proper length for the ProgressLine on  order funnel based on order type
  useEffect(() => {
    if (order.orderType === 'Cargo') {
      setOrderProgressLength(9)
    } else {
      setOrderProgressLength(8)
    }
  }, [order.orderType])

  return (
    <>
      {productsLoading || deliveryProductsLoading ? (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      ) : null}
      <Snackbar snackbarOpen={snackbarOpen} text={snackbarText} />
      <Router>
        {currentPhase === 'redeem' && (
          <ProgressLine progress={redeem.currentView} progressLength={5} />
        )}
        {currentPhase === 'order' && (
          <ProgressLine
            progress={currentView}
            progressLength={orderProgressLength}
          />
        )}
        {currentPhase === 'bulkorder' && (
          <ProgressLine progress={bulkOrder.currentView} progressLength={6} />
        )}
        {currentPhase === 'campaign' && (
          <ProgressLine
            progress={campaignRedeem.currentView}
            progressLength={6} // TODO: DISCONTINUED CONTRY SELECTION, INCASE COUNTRYSELECTION IS RETURNED, GROW LENGTH BY 1
          />
        )}
        {showFeedbackDialog && <ContactUs />}
        {showShare && <Share />}
        <Layout>
          <Switch>
            {productsError || deliveryProductsError ? (
              <ErrorPage.Status500
                text="Jokin meni palvelussamme pieleen."
                errorText="Yritä myöhemmin uudelleen."
              />
            ) : null}
            <Route exact path="/" render={() => <Welcome />} />
            <Route exact path="/login" render={() => <LoginPage />} />
            {/* Order phase */}
            <Route exact path="/order" render={() => <Order />} />
            <Route
              exact
              path="/order-delivery-method"
              render={() => <OrderDeliveryMethod />}
            />
            <Route exact path="/order-cart" render={() => <OrderCart />} />
            <Route
              exact
              path="/order-contact-company"
              render={() => <OrderContactCompany />}
            />
            <Route
              exact
              path="/order-delivery-date"
              render={() => <OrderDeliveryDate />}
            />
            <Route
              exact
              path="/order-contact-person"
              render={() => <OrderContactPerson />}
            />
            {/* <Route
              exact
              path="/order-support"
              render={() => <OrderSupport />}
            /> */}
            <Route
              exact
              path="/order-billing"
              render={() => <OrderBilling />}
            />
            <Route
              exact
              path="/order-summary"
              render={() => <OrderSummary />}
            />
            {/* Order phase end */}
            {/* Status pages */}
            <Route
              exact
              path="/status-orders"
              render={() => <StatusOrders />}
            />
            <Route exact path="/status-home" render={() => <StatusHome />} />
            {/* Status pages end */}
            {/* Order views */}
            <Route
              exact
              path="/view-order/:id"
              render={() => <SingleOrderView />}
            />
            <Route exact path="/view-orders" render={() => <OrdersView />} />
            {/* Order views end */}
            {/* Bulk Order views */}
            <Route
              exact
              path="/bulkorder-reset-password"
              render={() => <BulkOrderResetPassword />}
            />
            <Route
              exact
              path="/bulkorder-set-password"
              render={() => <BulkOrderSetPassword />}
            />
            <Route
              exact
              path="/venner-login"
              render={() => <BulkOrderLogin />}
            />
            <PrivateRoute
              exact
              path="/bulkorder-select-campaign"
              component={SelectCampaign}
            />
            <PrivateRoute
              exact
              path="/bulkorder-select-email-list"
              component={SelectEmailList}
            />
            <PrivateRoute
              exact
              path="/bulkorder-company-details"
              component={CompanyDetails}
            />
            <PrivateRoute
              exact
              path="/bulkorder-contact-person"
              component={ContactPerson}
            />
            <PrivateRoute
              exact
              path="/bulkorder-invoice-details"
              component={InvoiceDetails}
            />
            <PrivateRoute exact path="/bulkorder-summary" component={Summary} />
            <PrivateRoute
              exact
              path="/bulkorder-confirmation"
              component={Confirmation}
            />
            {/* Bulk Order views end */}
            {/* Redeem phase */}
            <Route exact path="/redeem/:id" render={() => <Redeem />} />
            <Route path="/redeem-welcome" render={() => <RedeemOnboarding />} />
            <Route path="/redeem-product" render={() => <RedeemProduct />} />
            <Route path="/redeem-contact" render={() => <RedeemContact />} />
            <Route path="/redeem-delivery" render={() => <RedeemDelivery />} />
            <Route path="/redeem-date" render={() => <RedeemDate />} />
            <Route path="/redeem-summary" render={() => <RedeemSummary />} />
            <Route path="/redeem-confirm" render={() => <RedeemConfirm />} />
            {/* Redeem phase end */}
            {/* Redeem Campaign */}
            <Route
              exact
              path="/redeem/:slug/:id"
              render={() => <CampaignRedeemCode />}
            />
            <Route
              exact
              path="/campaign-redeem-onboarding"
              render={() => <CampaignRedeem />}
            />
            <Route
              exact
              path="/campaign-redeem-already-used"
              render={() => <CampaignRedeemAlreadyUsed />}
            />
            <Route
              exact
              path="/campaign-select-package"
              render={() => <SelectPackage />}
            />
            <Route
              exact
              path="/campaign-select-package-type"
              render={() => <SelectPackageType />}
            />
            {/* TODO: DISCONTINUED CONTRY SELECTION */}
            {/* <Route
              exact
              path="/campaign-select-country"
              render={() => <SelectCountry />}
            /> */}
            <Route
              exact
              path="/campaign-contact-details"
              render={() => <CampaignContactDetails />}
            />
            <Route
              exact
              path="/campaign-delivery-information"
              render={() => <CampaignDeliveryInformation />}
            />
            <Route
              exact
              path="/campaign-delivery-date"
              render={() => <CampaignDeliveryDate />}
            />
            <Route
              exact
              path="/campaign-redeem-summary"
              render={() => <CampaignSummary />}
            />
            <Route
              exact
              path="/campaign-redeem-confirmation"
              render={() => <CampaignRedeemConfirmation />}
            />
            {/* Redeem Campaign end*/}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Router>
    </>
  )
}

export default App
