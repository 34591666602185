import React, { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Container,
  Heading,
  CatalogItem,
  BottomBar,
  Button,
  SmallPrint
} from 'uniqore-components'
import OrderBoxType from 'components/Order/OrderBoxType'
import Cart from 'components/Common/Cart'
import { changeUtils } from 'features/utils'
import { changeOrder } from 'features/order'
import { updateProducts } from 'features/order'
import { round } from 'utils/common'
import { Product, DeliveryProducts, DynamicDeliveryTypes } from 'types/types'
import { AppState } from 'store'

function reducer(state: Product[], action: any) {
  switch (action.type) {
    case 'init':
      state = action.payload
      return state
    case 'increase':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! += 1) }
        } else {
          return item
        }
      })
    case 'decrease':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! -= 1) }
        } else {
          return item
        }
      })
    case 'remove':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          if (item.isDynamic && item.minQuantity) {
            return { ...item, quantity: (item.quantity = item.minQuantity) }
          } else if (item.type === 'Cargo' && !item.isDynamic) {
            return { ...item, quantity: (item.quantity = 10) }
          } else {
            return { ...item, quantity: (item.quantity = 0) }
          }
        } else {
          return item
        }
      })
    case 'setQuantity':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity = action.payload.value) }
        } else {
          return item
        }
      })
    default:
      return state
  }
}

const OrderCart: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { orderType, products, isDynamicProduct } = useSelector(
    (state: AppState) => state.order
  )
  const { shippingGiftcard, shippingCargo, boxMinimumOrder } = useSelector(
    (state: AppState) => state.airtableData
  )
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [items, dispatchReducer] = useReducer(reducer, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeUtils({ value: 3, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set products to useReducer, this is done because of the CatalogItem -component
  useEffect(() => {
    const setProducts = JSON.parse(JSON.stringify(products))
    dispatchReducer({
      type: 'init',
      payload: setProducts
    })
  }, [orderType, products])

  const goBack = () => {
    dispatch(changeUtils({ value: 2, stateKey: 'currentView' }))
    history.push('/order-delivery-method')
  }

  const goForward = () => {
    dispatch(updateProducts({ products: items }))
    dispatch(changeUtils({ value: 4, stateKey: 'currentView' }))
    if (orderType !== 'Cargo') {
      dispatch(
        changeOrder({
          value: true,
          stateKey: 'companyContact',
          stateKeyExtra: 'deliveryAddress'
        })
      )
      dispatch(
        changeOrder({
          value: '',
          stateKey: 'deliveryContact',
          stateKeyExtra: 'companyName'
        })
      )
      dispatch(
        changeOrder({
          value: '',
          stateKey: 'deliveryContact',
          stateKeyExtra: 'address'
        })
      )
      dispatch(
        changeOrder({
          value: '',
          stateKey: 'deliveryContact',
          stateKeyExtra: 'zip'
        })
      )
      dispatch(
        changeOrder({
          value: '',
          stateKey: 'deliveryContact',
          stateKeyExtra: 'city'
        })
      )
    }
    history.push('/order-contact-company')
  }

  const buttonDisabled = (): boolean => {
    let result = true
    if (isDynamicProduct && items.length > 0) {
      if (items[0].minQuantity) {
        result = items[0].quantity < items[0].minQuantity
      } else {
        result = items[0].quantity < 1
      }
    }
    // Sesonkiruokalaatikot
    else {
      items.forEach((item) => {
        if (orderType === 'Cargo') {
          if (item.type === 'Cargo') {
            result = item.quantity < 10
          }
        } else if (orderType === 'Giftcard') {
          if (item.type === 'Giftcard') {
            result = item.quantity < 1
          }
        } else if (item.quantity > 0) {
          result = false
        }
      })
    }
    return result
  }

  const getSubtitlesSeasonBox = (product: Product): string[] => {
    const subtitles: string[] = []
    if (product.type === 'Cargo') {
      subtitles.push(`Minimitilausmäärä ${boxMinimumOrder} kpl`)
    }
    if (product.subtitles[0]) {
      subtitles.push(product.subtitles[0])
    }
    subtitles.push(
      product.type === 'Charity'
        ? `${round(product.price)} (alv 0%)`
        : product.type === 'Cargo'
        ? `${round(product.price + shippingCargo.price)} (alv 0%)`
        : `${round(product.price + shippingGiftcard.price)} (alv 0%)`
    )
    return subtitles
  }

  const getSubtitlesDynamicProduct = (
    product: Product,
    delivery: DynamicDeliveryTypes,
    deliveryProducts?: DeliveryProducts
  ): string[] => {
    const subtitles: string[] = []
    if (delivery === 'Home' || delivery === 'Pickup') {
      subtitles.push('Lunastaminen lahjakoodilla')
    }
    if (product.minQuantity) {
      subtitles.push(`Minimitilausmäärä ${product.minQuantity} kpl`)
    }
    if (deliveryProducts) {
      Object.values(deliveryProducts).forEach((value) => {
        if (value.type === delivery) {
          subtitles.push(`${round(product.price + value.price)} (alv 0%)`)
        }
      })
    }
    return subtitles
  }

  return (
    <>
      <Container>
        <AppBar
          text="Takaisin"
          mainAction={{ icon: 'ArrowBack', onClick: goBack }}
        />
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Tilauksen sisältö"
            body={
              isDynamicProduct
                ? orderType === 'Giftcard'
                  ? 'Voit tilata tuotteita lunastettavaksi koodilla.'
                  : 'Voit tilata tuotteita toimitettavaksi toimipisteeseesi.'
                : orderType === 'Charity'
                ? 'Voit tilata Sesonkiruokalaatikoita ja auttaa vähävaraista perhettä.'
                : orderType === 'Cargo'
                ? 'Voit tilata Sesonkiruokalaatikoita toimitettavaksi toimipisteeseesi ja auttaa vähävaraista perhettä.'
                : 'Voit tilata Sesonkiruokalaatikoita lunastettavaksi koodilla ja auttaa vähävaraista perhettä.'
            }
          />
          {isDynamicProduct
            ? items.map((item) => (
                <CatalogItem
                  key={item.id}
                  itemId={item.id}
                  variant="multi"
                  dispatch={dispatchReducer}
                  selected={item.quantity > 0}
                  minQuantity={0}
                  quantity={item.quantity}
                  image={item.image}
                  text={
                    item.type === 'Cargo'
                      ? 'Kertatoimitus toimipisteeseen'
                      : item.type === 'Home'
                      ? 'Kotiintoimitus yksittäin'
                      : 'Toimitus noutopisteeseen yksittäin'
                  }
                  subtitles={getSubtitlesDynamicProduct(
                    products[0],
                    item.type as DynamicDeliveryTypes,
                    item.deliveryProducts
                  )}
                />
              ))
            : items.map((item) => {
                if (item.type === 'Cargo') {
                  return (
                    <CatalogItem
                      key={item.id}
                      itemId={item.id}
                      variant="multi"
                      onClick={() => setOpenDialog(true)}
                      selected={item.quantity > 0}
                      minQuantity={10}
                      quantity={item.quantity}
                      image={item.image}
                      text={item.text}
                      subtitles={getSubtitlesSeasonBox(item)}
                    />
                  )
                } else {
                  return (
                    <CatalogItem
                      key={item.id}
                      itemId={item.id}
                      variant="multi"
                      dispatch={dispatchReducer}
                      selected={item.quantity > 0}
                      minQuantity={0}
                      quantity={item.quantity}
                      image={item.image}
                      text={item.text}
                      subtitles={getSubtitlesSeasonBox(item)}
                    />
                  )
                }
              })}
          <div style={{ paddingTop: '16px' }}>
            <Cart items={items} />
          </div>
          <SmallPrint
            text={
              isDynamicProduct
                ? products[0].support
                  ? `Tuen osuus = Jokaisesta omille sidosryhmille tilatusta tuotteesta ohjataan ${products[0].support} € vähävaraisten perheiden ravitsevan ruuan tukemiseen.`
                  : ''
                : `Tuen osuus = Jokaisesta omille sidosryhmille tilatusta Venner-sesonkiruokalaatikosta ohjataan 6,00 € vähävaraisten perheiden ravitsevan ruuan tukemiseen. Venner-ruokalaatikot toimitetaan kokonaisuudessaan vähävaraisille perheille.`
            }
          />
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              onClick={goForward}
              disabled={buttonDisabled()}
            />
          ]}
        />
      </Container>
      <OrderBoxType open={openDialog} setOpenDialog={setOpenDialog} />
    </>
  )
}

export default OrderCart
