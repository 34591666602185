import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  Switch,
  BottomBar,
  i18n
} from 'uniqore-components'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'
import { validateEmail } from 'api/cloudFunctions'

const RedeemContact: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { firstName, lastName, phone, email, mailingList } = useSelector(
    (state: AppState) => state.campaignRedeem.contactDetails
  )
  const { selectedCountry, language, redemptionCode, forCharity } = useSelector(
    (state: AppState) => state.campaignRedeem
  )

  const { t } = useTranslation()

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      i18n.changeLanguage(language)
      dispatch(changeCampaignRedeem({ value: 3, stateKey: 'currentView' })) //TODO: DISCONTINUED CONTRY SELECTION, CHANGE VALUE TO 4 IF COUNTRY SELECTION IS RETURNED
    }
  }, [language, redemptionCode, history, dispatch])

  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid(t('empty-field'))
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid(t('empty-field'))
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid(t('empty-field'))
      valid = false
    } else {
      setPhoneValid('')
    }
    if (!validateEmail(email)) {
      setEmailValid(t('invalid-email'))
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const goBack = () => {
    if (forCharity) {
      history.push('/campaign-select-package')
    } else {
      // TODO: DISCONTINUED COUNTRY SELECTION
      //history.push('/campaign-select-country')
      history.push('/campaign-select-package')
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      if (selectedCountry === 'Viro' || forCharity) {
        history.push('/campaign-redeem-summary')
      } else {
        history.push('/campaign-delivery-information')
      }
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text={t('button-back')}
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading title={t('contact-details-title')} />
          <TextField
            name="fname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) =>
              dispatch(
                changeCampaignRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'firstName'
                })
              )
            }
            fullWidth
            label={t('firstname')}
            errorText={firstNameValid}
          />
          <TextField
            name="lname"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) =>
              dispatch(
                changeCampaignRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'lastName'
                })
              )
            }
            fullWidth
            label={t('lastname')}
            errorText={lastNameValid}
          />
          <TextField
            type="tel"
            name="phone"
            autoComplete="tel"
            value={phone}
            onChange={(e) =>
              dispatch(
                changeCampaignRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'phone'
                })
              )
            }
            fullWidth
            label={t('phonenumber')}
            errorText={phoneValid}
          />
          <TextField
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) =>
              dispatch(
                changeCampaignRedeem({
                  value: e.target.value,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'email'
                })
              )
            }
            fullWidth
            label={t('email')}
            errorText={emailValid}
          />
          <Switch
            color="primary"
            label={t('venner-email-list')}
            checked={mailingList}
            onChange={(e) =>
              dispatch(
                changeCampaignRedeem({
                  value: e.target.checked,
                  stateKey: 'contactDetails',
                  stateKeyExtra: 'mailingList'
                })
              )
            }
          />
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label={t('button-previous')}
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label={t('button-forward')}
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default RedeemContact
