import { createSlice } from '@reduxjs/toolkit'
import { Order } from 'features/order'

interface OrdersState {
  orders: Order[]
}

interface AddToOrdersAction {
  payload: {
    order: Order
  }
}

interface EditOrderAction {
  payload: {
    order: Order
  }
}

const initialState: OrdersState = {
  orders: []
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    addToOrders: (state, { payload }: AddToOrdersAction) => {
      state.orders = state.orders.concat(payload.order)
    },
    editOrder: (state, { payload }: EditOrderAction) => {
      state.orders = state.orders.map((order: Order) =>
        order.orderId === payload.order.orderId ? payload.order : order
      )
    },
    removeOrders: () => {
      return initialState
    }
  }
})

export const { addToOrders, editOrder, removeOrders } = ordersSlice.actions
export default ordersSlice.reducer
