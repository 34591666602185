import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, Button, TextField, i18n } from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const ContactUs: React.FC = () => {
  const dispatch = useDispatch()
  const { language } = useSelector((state: AppState) => state.campaignRedeem)

  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const {
    showFeedbackDialog,
    personContactFirstName,
    personContactLastName,
    personContactEmail,
    personContactPhone,
    companyName
  } = useSelector((state: AppState) => state.utils)
  const [name] = useState<string>(
    `${personContactFirstName} ${personContactLastName}`
  )
  const [company] = useState<string>(companyName)
  const [phone] = useState<string>(personContactPhone)
  const [email] = useState<string>(personContactEmail)
  const [message, setMessage] = useState<string>('')
  const [messageValid, setMessageValid] = useState<string>('')
  const [formCall, { loading }] = useMutation(formQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.ingestForm.errors) {
        dispatch(changeUtils({ value: true, stateKey: 'snackbarOpen' }))
        dispatch(
          changeUtils({
            value:
              'Kiitos yhteydenotostasi! Olemme sinuun pikimmiten yhteydessä.',
            stateKey: 'snackbarText'
          })
        )
        dispatch(changeUtils({ value: false, stateKey: 'showFeedbackDialog' }))
      }
    },
    onError(mutationError) {
      if (mutationError) {
        dispatch(changeUtils({ value: true, stateKey: 'snackbarOpen' }))
        dispatch(
          changeUtils({
            value: 'Jokin meni pieleen, yritän uudelleen.',
            stateKey: 'snackbarText'
          })
        )
      }
    }
  })

  const validateFields = () => {
    let valid = true
    if (message === '') {
      setMessageValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setMessageValid('')
    }
    return valid
  }

  const submit = () => {
    if (validateFields()) {
      const sendForm = {
        name,
        company,
        phone,
        email,
        message
      }

      formCall({
        variables: {
          id: process.env.REACT_APP_SEND_CONTACT_FORM,
          form: sendForm
        }
      })
    }
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      )}
      <Dialog
        title={t('comment-dialog-title')}
        open={showFeedbackDialog}
        onClose={() =>
          dispatch(
            changeUtils({ value: false, stateKey: 'showFeedbackDialog' })
          )
        }
        actionComponents={[
          <Button
            label={t('button-back').toUpperCase()}
            variant="text"
            border
            disablePadding
            disabled={loading}
            onClick={() =>
              dispatch(
                changeUtils({
                  value: false,
                  stateKey: 'showFeedbackDialog'
                })
              )
            }
          />,
          <Button
            label={t('button-send').toUpperCase()}
            color="primary"
            disablePadding
            disabled={loading}
            onClick={submit}
          />
        ]}
      >
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disableGutters
          rows={3}
          fullWidth
          required
          label={t('comment-dialog-field-label')}
          errorText={messageValid}
        />
      </Dialog>
    </>
  )
}

export default ContactUs
