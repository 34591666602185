import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Button,
  FeedTitle,
  StatusHero,
  StatusHeroSwipe,
  SpeedDial,
  FeedCard,
  Dialog,
  TextField,
  Switch
} from 'uniqore-components'
import FeedbackCustom from 'components/Common/FeedbackCustom'
import ProgressCircle from 'components/Common/ProgressCircle'
import Collapse from '@material-ui/core/Collapse'
import SpinnerMui from 'components/Common/SpinnerMui'
import DeliveryImage from 'assets/delivery.svg'
import FeedbackImage from 'assets/feedback.svg'
import VennerLogo from 'assets/venner-logo.svg'
import { Order, emptyOrderDetails } from 'features/order'
import { editOrder, removeOrders } from 'features/orders'
import { changeUtils, resetUtils } from 'features/utils'
import { getDate, formatDate, sortFeedByDateAndTime } from 'utils/common'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Feed, Product } from 'types/types'
import { format, parseISO } from 'date-fns'
import { AppState } from 'store'
import { ReactComponent as Emoji1 } from 'assets/emojis/1.svg'
import { ReactComponent as Emoji2 } from 'assets/emojis/2.svg'
import { ReactComponent as Emoji3 } from 'assets/emojis/3.svg'
import { ReactComponent as Emoji4 } from 'assets/emojis/4.svg'

const useStyles = makeStyles(() => ({
  emoji: {
    position: 'absolute',
    top: '16px',
    left: '160px',
    width: '32px',
    height: '32px'
  }
}))

const feedbackQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const formResultQuery = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      result
    }
  }
`

const StatusOrders: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    orderIdForStatusSwipe,
    orderIngestFormResult,
    latestOrderId,
    loggedInUserName
  } = useSelector((state: AppState) => state.utils)
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState<boolean>(false)
  const [openComment, setOpenComment] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [engagementId, setEngagementId] = useState<string>('')
  const [orderId, setOrderId] = useState<string>('')
  const [feedback, setFeedback] = useState<1 | 2 | 3 | 4 | number>()
  const { orders } = useSelector((state: AppState) => state.orders)
  const {
    personalFeed,
    newOrder,
    personContactFirstName,
    personContactLastName,
    personContactEmail,
    personContactPhone,
    companyName
  } = useSelector((state: AppState) => state.utils)
  const [resultCall, { data, stopPolling }] = useLazyQuery(formResultQuery, {
    pollInterval: 500
  })
  const [feedbackFormCall, { loading }] = useMutation(feedbackQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.ingestForm.errors) {
        const findOrder = orders.find((o: Order) => o.orderId === orderId)
        if (findOrder) {
          const addFeedback = JSON.parse(JSON.stringify(findOrder))
          addFeedback.feedback = Number(feedback)
          dispatch(editOrder({ order: addFeedback }))
        }
        setFeedback(undefined)
        setEngagementId('')
        setOrderId('')
        setComment('')
        setOpenComment(false)
        setOpenFeedbackDialog(false)
        dispatch(changeUtils({ value: true, stateKey: 'snackbarOpen' }))
        dispatch(
          changeUtils({
            value: 'Kiitos palautteestasi!',
            stateKey: 'snackbarText'
          })
        )
      } else {
        dispatch(changeUtils({ value: true, stateKey: 'snackbarOpen' }))
        dispatch(
          changeUtils({
            value: 'Jokin meni pieleen, yritän uudelleen.',
            stateKey: 'snackbarText'
          })
        )
      }
    },
    onError(mutationError) {
      if (mutationError) {
        dispatch(changeUtils({ value: true, stateKey: 'snackbarOpen' }))
        dispatch(
          changeUtils({
            value: 'Jokin meni pieleen, yritän uudelleen.',
            stateKey: 'snackbarText'
          })
        )
      }
    }
  })

  const handleLogout = () => {
    window.localStorage.removeItem('token')
    dispatch(resetUtils())
    dispatch(emptyOrderDetails())
    dispatch(removeOrders())
    history.push('/login')
  }

  useEffect(() => {
    if (orders.length === 0) {
      history.push('/status-home')
    } else {
      window.scrollTo(0, 0)
      dispatch(changeUtils({ value: 0, stateKey: 'currentView' }))
      dispatch(changeUtils({ value: false, stateKey: 'newOrder' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set id_number (uniqoreFriendlyId) for the most recently created order
  const handleFormResult = (idNumber: string) => {
    stopPolling()
    const findOrder = orders.find(
      (order: Order) => order.orderId === latestOrderId
    )
    if (findOrder) {
      const addUniqoreFriendlyId: Order = JSON.parse(JSON.stringify(findOrder))
      addUniqoreFriendlyId.uniqoreFriendlyId = idNumber

      dispatch(editOrder({ order: addUniqoreFriendlyId }))
    }
    dispatch(changeUtils({ value: '', stateKey: 'latestOrderId' }))
    dispatch(changeUtils({ value: '', stateKey: 'orderIngestFormResult' }))
  }
  useEffect(() => {
    if (data) {
      if (data.formResult.result.hasOwnProperty('idNumber')) {
        handleFormResult(String(data.formResult.result.idNumber))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // after successful order, make formResult query to fetch id-number (uniqoreFriendlyId)
  useEffect(() => {
    if (orderIngestFormResult !== '') {
      resultCall({ variables: { id: orderIngestFormResult } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderIngestFormResult])

  const handleNewOrder = () => {
    dispatch(changeUtils({ value: true, stateKey: 'newOrder' }))
    dispatch(emptyOrderDetails())
    history.push('/order')
  }

  const getHeadline = (order: Order): string => {
    if (order.status === 'Processing') {
      return 'Kiitos tilauksesta'
    }
    if (order.status === 'Opened') {
      if (order.orderType === 'Giftcard') {
        return 'Koodia lunastettu'
      }
      if (order.orderType === 'Cargo') {
        if (order.deliveryDate) {
          return `${format(
            parseISO(order.deliveryDate),
            'dd.MM.yyyy'
          )} klo 8-16`
        } else {
          return 'Toimitus sovitaan erikseen'
        }
      }
    }
    return 'Mitä mieltä olit palvelustamme?'
  }

  const getSubtitle = (order: Order): string => {
    if (order.status === 'Closed') {
      return ''
    }
    if (order.status === 'Processing') {
      return 'Tilauksen tarkistuksen jälkeen lähetämme laskun laskutusosoitteeseesi ja tilausvahvistuksen sähköpostiisi.'
    }
    if (order.orderType === 'Giftcard') {
      return order.redeemExpirationDate
        ? `Lunastus loppuu: ${order.redeemExpirationDate}`
        : ''
    }
    if (order.orderType === 'Cargo') {
      let amount = 0
      order.products.forEach((product: Product) => {
        if (product.type === 'Cargo') {
          amount += product.quantity
        }
      })
      return `${amount} kpl Sesonkiruokalaatikkoa`
    }
    return ''
  }

  const getIndexOfOrder = (): number => {
    let index = 0
    orders.forEach((o: Order) => {
      if (o.orderId) {
        index = orders.findIndex(
          (o: Order) => o.orderId === orderIdForStatusSwipe
        )
      } else {
        index = orders.findIndex(
          (o: Order) => o.engagementId === orderIdForStatusSwipe
        )
      }
    })
    if (index > -1) {
      return index
    }
    return index
  }

  const getGiftCardAmount = (order: Order): number => {
    let giftCardAmount = 0
    if (order.products) {
      order.products.forEach((product: Product) => {
        if (
          product.type === 'Giftcard' ||
          product.type === 'Home' ||
          product.type === 'Pickup'
        ) {
          giftCardAmount += product.quantity
        }
      })
    }
    return giftCardAmount
  }

  const getDynamicProductImage = (order: Order) => {
    let result = undefined
    if (
      order.status === 'Processing' &&
      order.isDynamicProduct &&
      !order.products[0].videoUrl &&
      order.products[0].picture
    ) {
      result = order.products[0].picture
    }
    return result
  }

  const getVideo = (order: Order) => {
    let result = undefined
    if (order.status === 'Processing') {
      if (order.isDynamicProduct) {
        result = order.products[0].videoUrl
      } else {
        result = 'https://player.vimeo.com/video/556264772'
      }
    }
    return result
  }

  const handleFeedback = (order: Order) => {
    if (!orderId) {
      setOrderId(order.orderId)
    }
    if (!engagementId) {
      setEngagementId(order.engagementId!)
    }
    if (!openFeedbackDialog) {
      setOpenFeedbackDialog(true)
    }
  }

  const submitFeedback = () => {
    const sendFeedback = {
      engagementId: engagementId,
      feedback: Number(feedback),
      comment: openComment ? comment : '',
      name: `${personContactFirstName} ${personContactLastName}`,
      company: companyName,
      phone: personContactPhone,
      email: personContactEmail
    }

    feedbackFormCall({
      variables: {
        id: process.env.REACT_APP_SEND_FEEDBACK_ID,
        form: sendFeedback
      }
    })
  }

  const dialogClose = () => {
    setOpenFeedbackDialog(false)
    setComment('')
    setOpenComment(false)
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      )}
      <div>
        <AppBar
          maxWidth="sm"
          backgroundColor="#CEE3DF"
          logo={VennerLogo}
          logoSize={60}
        />
        <StatusHeroSwipe
          initialIndex={newOrder ? orders.length - 1 : getIndexOfOrder()}
          showArrows={orders.length > 1}
          showIndicators={orders.length > 1}
        >
          {orders.map((order: Order, i) => (
            <StatusHero
              key={i}
              maxWidth="sm"
              backgroundColor="#CEE3DF"
              color="#525252"
              image={
                order.status === 'Closed' ||
                (order.orderType === 'Charity' && order.status !== 'Processing')
                  ? FeedbackImage
                  : order.status !== 'Processing' && order.orderType === 'Cargo'
                  ? DeliveryImage
                  : order.orderType === 'Charity' && order.status === 'Opened'
                  ? FeedbackImage
                  : getDynamicProductImage(order)
                  ? getDynamicProductImage(order)
                  : ''
              }
              contentComponent={
                order.status === 'Opened' && order.orderType === 'Giftcard' ? (
                  <ProgressCircle
                    redeemed={order.redeemers ? order.redeemers.length : 0}
                    redeemable={getGiftCardAmount(order)}
                  />
                ) : undefined
              }
              video={getVideo(order)}
              headline={getHeadline(order)}
              subtitle={getSubtitle(order)}
              body={
                order.uniqoreFriendlyId
                  ? `Tilaus #${order.uniqoreFriendlyId}`
                  : ''
              }
              actionComponent={
                order.status === 'Closed' ||
                (order.orderType === 'Charity' &&
                  order.status !== 'Processing') ? (
                  <FeedbackCustom
                    name={order.orderId}
                    feedbackValue={order.feedback || 0}
                    onChange={(e: number) => setFeedback(e)}
                    onConfirm={() => handleFeedback(order)}
                  />
                ) : (
                  <Button
                    role="status"
                    icon="Receipt"
                    label="Tarkastele tilaustani"
                    onClick={() => history.push(`/view-order/${order.orderId}`)}
                  />
                )
              }
            />
          ))}
        </StatusHeroSwipe>
        <Button
          style={{ textAlign: 'center' }}
          label="Tee uusi tilaus"
          underline
          variant="default"
          icon="Add"
          onClick={handleNewOrder}
        />
        <div style={{ marginBottom: '120px' }}>
          <FeedTitle text="Viimeiset tapahtumat" />
          {sortFeedByDateAndTime(personalFeed)
            .slice(0, 10)
            .map((feed: Feed, i: number) => (
              <FeedCard
                key={i}
                avatarBackgroundColor={feed.avatarBackgroundColor}
                user={feed.user}
                date={
                  getDate() === feed.date
                    ? `Tänään ${feed.time}`
                    : `${formatDate(feed.date)} ${feed.time}`
                }
                subtitle={feed.subtitle}
                htmlText={feed.text}
              />
            ))}
        </div>
        <SpeedDial
          style={{ position: 'fixed', bottom: '20px', right: '20px' }}
          actions={
            loggedInUserName
              ? [
                  {
                    icon: 'Receipt',
                    label: 'Tarkastele tilauksiani',
                    onClick: () => history.push('/view-orders')
                  },
                  {
                    icon: 'Add',
                    label: 'Tee uusi tilaus',
                    onClick: handleNewOrder
                  },
                  {
                    icon: 'Send',
                    label: 'Ota yhteyttä',
                    onClick: () => {
                      dispatch(
                        changeUtils({
                          value: true,
                          stateKey: 'showFeedbackDialog'
                        })
                      )
                    }
                  },
                  {
                    icon: 'Share',
                    label: 'Haasta ystäväsi',
                    onClick: () => {
                      dispatch(
                        changeUtils({ value: true, stateKey: 'showShare' })
                      )
                    }
                  },
                  {
                    icon: 'ExitToApp',
                    label: 'Kirjaudu ulos',
                    onClick: handleLogout
                  }
                ]
              : [
                  {
                    icon: 'Receipt',
                    label: 'Tarkastele tilauksiani',
                    onClick: () => history.push('/view-orders')
                  },
                  {
                    icon: 'Add',
                    label: 'Tee uusi tilaus',
                    onClick: handleNewOrder
                  },
                  {
                    icon: 'Send',
                    label: 'Ota yhteyttä',
                    onClick: () => {
                      dispatch(
                        changeUtils({
                          value: true,
                          stateKey: 'showFeedbackDialog'
                        })
                      )
                    }
                  },
                  {
                    icon: 'Share',
                    label: 'Haasta ystäväsi',
                    onClick: () => {
                      dispatch(
                        changeUtils({ value: true, stateKey: 'showShare' })
                      )
                    }
                  }
                ]
          }
        />
      </div>
      <Dialog
        open={openFeedbackDialog}
        title="Anna palaute"
        subtitle=""
        onClose={dialogClose}
        actionComponents={[
          <Button
            label="TAKAISIN"
            variant="default"
            border
            disablePadding
            disabled={loading}
            onClick={dialogClose}
          />,
          <Button
            label="LÄHETÄ"
            variant="contained"
            color="primary"
            disablePadding
            disabled={loading}
            onClick={submitFeedback}
          />
        ]}
      >
        {feedback === 1 && <Emoji1 className={classes.emoji} />}
        {feedback === 2 && <Emoji2 className={classes.emoji} />}
        {feedback === 3 && <Emoji3 className={classes.emoji} />}
        {feedback === 4 && <Emoji4 className={classes.emoji} />}
        <Switch
          disableGutters
          color="primary"
          label="Lisää kirjallinen palaute?"
          checked={openComment}
          onChange={() => setOpenComment(!openComment)}
        />
        <Collapse in={openComment} timeout="auto" unmountOnExit>
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disableGutters
            rows={3}
            fullWidth
            label="Palaute"
          />
        </Collapse>
      </Dialog>
    </>
  )
}

export default StatusOrders
