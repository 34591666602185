import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  ListItem
} from 'uniqore-components'
import { useCheckTokenExpiration } from 'components/Common/Hooks/useCheckTokenExpiration'
import SpinnerMui from 'components/Common/SpinnerMui'
import { changeBulkOrder, BulkOrderState } from 'features/BulkOrder'
import { CompanyContact, PersonContact } from 'features/order'
import { AppState } from 'store'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { formatDate } from 'utils/common'
import { Product } from 'types/types'
import { createBulkOrders } from 'api/cloudFunctions'
import { useLogOut } from 'components/BulkOrder/LoginPage'

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const formResultQuery = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      id
      state
      result
    }
  }
`

const trimCompanyContact = (companyContact: CompanyContact) => {
  const fixAddress = companyContact.address.trim()
  companyContact.address = fixAddress
  const fixZip = companyContact.zip.trim()
  companyContact.zip = fixZip
  const fixCity = companyContact.city.trim()
  companyContact.city = fixCity
}
const trimPersonContent = (personContact: PersonContact) => {
  const fixFirstName = personContact.firstName.trim()
  personContact.firstName = fixFirstName
  const fixLastName = personContact.lastName.trim()
  personContact.lastName = fixLastName
  const fixEmail = personContact.email.trim()
  personContact.email = fixEmail
  const trimPhoneNumber = personContact.phone.trim()
  const removePossibleParenthesesPhone = trimPhoneNumber.replace(/([()])/g, '')
  personContact.phone = removePossibleParenthesesPhone
}

const setProductPayloadData = (product: Product) => {
  delete product.minQuantity
  delete product.isActive
  delete product.salesValidFrom
  delete product.salesValidTo
  delete product.redeemValidFrom
  delete product.deliveryValidFrom
  delete product.deliveryValidTo
  delete product.selectableWeeksCount
  delete product.daysAheadBuffer
  delete product.deliveryProducts
  delete product.showOrder
  return product
}

const Summary: React.FC = () => {
  const { loading } = useCheckTokenExpiration()
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()
  const bulkOrder = useSelector((state: AppState) => state.bulkOrder)
  const { campaign, companyContact, personContact, billing } = useSelector(
    (state: AppState) => state.bulkOrder
  )
  const [error, setError] = useState<boolean>(false)
  const [cloudLoading, setCloudLoading] = useState<boolean>(false)
  const [resultFormId, setResultFormId] = useState<string>('')
  const [formPoll, { loading: pollLoading }] = useLazyQuery(formResultQuery, {
    fetchPolicy: 'no-cache',
    onCompleted(mutationResult) {
      if (mutationResult) {
        if (mutationResult.formResult.state === 'SUCCESS') {
          setCloudLoading(true)
          const userToken = localStorage.getItem('uq_api_token') || ''
          // First make sure that we can create the email list
          createBulkOrders({
            emails: bulkOrder.emailList,
            engagementId: mutationResult.formResult.result.id,
            // Remove 'OrgUserToken' from token, since cloud function authorizes token
            // with 'X-authorization': `OrgUserToken ${userToken}`
            userToken: userToken.replace('OrgUserToken', '').trim()
          })
            .then(() => {
              dispatch(changeBulkOrder({ value: 0, stateKey: 'currentView' }))
              history.push('/bulkorder-confirmation')
            })
            .catch(() => setError(true))
            .finally(() => setCloudLoading(false))
        } else if (mutationResult.formResult.state === 'WAITING') {
          setTimeout(() => {
            formPoll({ variables: { id: resultFormId } })
          }, 1000)
        } else {
          setError(true)
        }
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setError(true)
      }
    }
  })
  const [formCall, { loading: mutationLoading, error: mutationError }] =
    useMutation(formQuery, {
      onCompleted(result) {
        if (result && !result.ingestForm.errors) {
          setResultFormId(result.ingestForm.result)
          setCloudLoading(true)
          setTimeout(() => {
            formPoll({ variables: { id: result.ingestForm.result } })
          }, 1000)
        } else {
          setError(true)
        }
      },
      onError(mutationError) {
        if (mutationError) {
          setError(true)
        }
      }
    })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 6, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!campaign.id) {
      history.push('/bulkorder-select-campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const goBack = () => {
    history.push('/bulkorder-invoice-details')
  }

  const handleCampaignOrder = () => {
    setError(false)
    const sendCampaignOrder: BulkOrderState = JSON.parse(
      JSON.stringify(bulkOrder)
    )

    const deleteKeys = ['currentView', 'emailList']
    // @ts-ignore
    deleteKeys.forEach((v) => delete sendCampaignOrder[v])

    if (sendCampaignOrder.billing.eInvoice) {
      sendCampaignOrder.billing.email = ''
    } else {
      sendCampaignOrder.billing.ovtId = ''
      sendCampaignOrder.billing.operatorId = ''
    }
    // @ts-ignore
    sendCampaignOrder.orderType = 'Giftcard'
    // @ts-ignore
    sendCampaignOrder.deliveryDate = ''

    const setProducts: Product[] = []
    bulkOrder.campaign.products.forEach((product: Product) => {
      setProducts.push(setProductPayloadData({ ...product }))
    })
    sendCampaignOrder.campaign.products = setProducts

    // Remove whitespaces
    trimCompanyContact(sendCampaignOrder.companyContact)
    trimPersonContent(sendCampaignOrder.personContact)

    formCall({
      variables: {
        id: process.env.REACT_APP_SEND_CAMPAIGN_ORDER,
        form: sendCampaignOrder
      }
    })
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
        actions={[
          {
            icon: 'ExitToApp',
            onClick: logOut
          }
        ]}
      />
      <div>
        <Heading title="Yhteenveto" />
        <ListItem
          text={campaign.name}
          subtitles={[
            `Viimeinen lunastuspäivä: ${
              campaign.expirationDate ? formatDate(campaign.expirationDate) : ''
            }`
          ]}
          metaIcon="Edit"
          onClick={() => history.push('bulkorder-select-campaign')}
        />
        <ListItem
          text="Yhteyshenkilön tiedot"
          subtitles={[
            `${personContact.firstName} ${personContact.lastName}`,
            personContact.phone,
            personContact.email
          ]}
          metaIcon="Edit"
          onClick={() => history.push('bulkorder-contact-person')}
        />
        <ListItem
          text="Yrityksen yhteystiedot"
          subtitles={[
            companyContact.companyName,
            companyContact.address,
            `${companyContact.zip} ${companyContact.city}`
          ]}
          metaIcon="Edit"
          onClick={() => history.push('bulkorder-company-details')}
        />
        <ListItem
          text="Laskutustiedot"
          subtitles={[
            !billing.eInvoice
              ? `Lasku sähköpostiin: ${billing.email}`
              : `Verkkolasku: ${billing.ovtId} / ${billing.operatorId}`,
            billing.additionalInfoActive
              ? `Lisätiedot: "${billing.additionalInfo}"`
              : ''
          ]}
          metaIcon="Edit"
          onClick={() => history.push('bulkorder-invoice-details')}
        />
      </div>

      <Container style={{ position: 'absolute', bottom: 40, width: '100%' }}>
        {error || mutationError ? (
          <Heading body="Jokin meni pieleen. Yritä uudelleen." />
        ) : null}
        {(mutationLoading || cloudLoading || pollLoading || loading) && (
          <SpinnerMui
            size={30}
            style={{
              position: 'absolute',
              bottom: '13px',
              width: '100%',
              textAlign: 'center'
            }}
          />
        )}
        <Button
          maxWidth="sm"
          label="Tee tilaus"
          color="primary"
          fullWidth
          onClick={handleCampaignOrder}
          disabled={mutationLoading || cloudLoading || pollLoading}
        />
      </Container>
    </Container>
  )
}

export default Summary
