import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { Heading, Login, TextField, Button } from 'uniqore-components'
import { resetBulkOrder, changeBulkOrder } from 'features/BulkOrder'
import { useHistory } from 'react-router-dom'
import { validateEmail } from 'api/cloudFunctions'

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 700px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 700px)': {
      height: 'calc(100vh - 56px)'
    }
  }
}))

const LOGIN = gql`
  mutation login(
    $email: EmailAddress!
    $password: String!
    $organization: ID
    $type: LoginType
  ) {
    login(
      email: $email
      password: $password
      organization: $organization
      type: $type
    ) {
      token
      error
      uid
    }
  }
`

export const useLogOut = () => {
  const history = useHistory()

  return () => {
    window.localStorage.removeItem('uq_api_token')
    window.localStorage.removeItem('uq_user_id')
    history.push('/venner-login')
  }
}

const LoginPage: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordValid] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false)

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted(result) {
      if (result.login.error) {
        setError(true)
      } else if (result.login.token) {
        localStorage.setItem('uq_api_token', result.login.token)
        localStorage.setItem('uq_user_id', result.login.uid)
        history.push('/bulkorder-select-campaign')
      }
    },
    onError(error) {
      if (error) {
        setError(true)
      }
    }
  })

  // checks local storage for token
  useEffect(() => {
    const getToken = window.localStorage.getItem('uq_api_token')
    if (getToken) {
      dispatch(changeBulkOrder({ value: getToken, stateKey: 'token' }))
    } else {
      dispatch(resetBulkOrder())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = () => {
    let valid = true
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      login({
        variables: {
          email: email,
          password: password,
          organization: process.env.REACT_APP_ORGANIZATION_ID,
          type: 'ORGUSER'
        }
      })
    }
  }

  return (
    <div onSubmit={submit} className={classes.contentArea}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Login
          maxWidth="sm"
          headline="Kirjaudu sisään"
          subtitle=" " /* Replace the default subtitle string */
          contentComponents={[
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errorText={emailValid}
              fullWidth
              placeholder="Sähköpostiosoitteesi"
              name="email"
              autoComplete="email"
              prefix={{
                icon: 'MailOutline'
              }}
            />,
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errorText={passwordValid}
              fullWidth
              placeholder="Salasana"
              name="password"
              type={passwordVisibility ? 'text' : 'password'}
              autoComplete="password"
              prefix={{
                icon: 'MailOutline'
              }}
              suffix={{
                onClick: () => setPasswordVisibility(!passwordVisibility),
                icon: !passwordVisibility ? 'Visibility' : 'VisibilityOff'
              }}
            />
          ]}
          actionComponents={[
            <Button
              color="primary"
              label="Kirjaudu sisään"
              fullWidth
              disabled={loading}
              type="submit"
              onClick={submit}
            />,
            <Button
              variant="default"
              color="primary"
              label="Salasana unohtunut?"
              fullWidth
              disabled={loading}
              onClick={() => history.push('/bulkorder-reset-password')}
              type="submit"
            />
          ]}
        />
        {error && (
          <Heading body="Jokin meni pieleen. Yritä uudelleen ja tarkistathan antamasi sähköpostiosoitteen ja salasanan." />
        )}
      </div>
    </div>
  )
}

export default LoginPage
