import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  Switch,
  BottomBar
} from 'uniqore-components'
import { changeOrder } from 'features/order'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'
import { validateEmail } from 'api/cloudFunctions'

const OrderContactPerson: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { firstName, lastName, phone, email, mailingList } = useSelector(
    (state: AppState) => state.order.personContact
  )
  const { orderType } = useSelector((state: AppState) => state.order)
  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 6, stateKey: 'currentView' }))
    } else {
      dispatch(changeUtils({ value: 5, stateKey: 'currentView' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setPhoneValid('')
    }
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const goBack = () => {
    if (orderType === 'Cargo') {
      dispatch(changeUtils({ value: 5, stateKey: 'currentView' }))
      history.push('/order-delivery-date')
    } else {
      dispatch(changeUtils({ value: 4, stateKey: 'currentView' }))
      history.push('/order-contact-company')
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      if (orderType === 'Cargo') {
        dispatch(changeUtils({ value: 7, stateKey: 'currentView' }))
      } else {
        dispatch(changeUtils({ value: 6, stateKey: 'currentView' }))
      }
      history.push('/order-billing')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Yhteyshenkilön yhteystiedot"
            body="Lisää yrityksenne yhteyshenkilön tiedot."
          />
          <TextField
            name="fname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'firstName'
                })
              )
            }
            fullWidth
            label="Etunimi"
            errorText={firstNameValid}
          />
          <TextField
            name="lname"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'lastName'
                })
              )
            }
            fullWidth
            label="Sukunimi"
            errorText={lastNameValid}
          />
          <TextField
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'email'
                })
              )
            }
            fullWidth
            label="Sähköposti"
            errorText={emailValid}
          />
          <TextField
            type="tel"
            name="phone"
            autoComplete="tel"
            value={phone}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'phone'
                })
              )
            }
            fullWidth
            label="Puhelinnumero"
            errorText={phoneValid}
          />
          <Switch
            color="primary"
            label="Haluan liittyä Vennerin yrityspalveluiden postituslistalle."
            checked={mailingList}
            onChange={(e) =>
              dispatch(
                changeOrder({
                  value: e.target.checked,
                  stateKey: 'personContact',
                  stateKeyExtra: 'mailingList'
                })
              )
            }
          />
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default OrderContactPerson
