import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppBar, Container, Heading, ListItem } from 'uniqore-components'
import { Order } from 'features/order'
import { round } from 'utils/common'
import { format } from 'date-fns'
import { AppState } from 'store'

const OrdersView: React.FC = () => {
  const history = useHistory()
  const { orders } = useSelector((state: AppState) => state.orders)

  /**
   * Format date from yyyy-MM-dd to dd.MM.yyyy
   */
  const formatDate = (date: string) => {
    if (!date) {
      return '-'
    }
    const year: number = Number(date.split('-')[0])
    const month: number = Number(date.split('-')[1]) - 1
    const day: number = Number(date.split('-')[2])
    return format(new Date(year, month, day), 'dd.MM.yyyy')
  }

  return (
    <>
      <AppBar
        maxWidth="sm"
        text="Takaisin"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => history.goBack()
        }}
        border
      />
      <Container>
        <div style={{ marginBottom: 120 }}>
          {orders.length === 0 ? (
            <Heading align="left" title="Ei tilauksia." />
          ) : (
            <>
              <Heading align="left" title="Tilaukseni" />
              {orders.map((order: Order, i) => (
                <ListItem
                  key={i}
                  text={
                    order.uniqoreFriendlyId
                      ? `Tilaus #${order.uniqoreFriendlyId}`
                      : `Tilaus #${order.orderId}`
                  }
                  subtitles={[
                    `${formatDate(order.orderDate)}, ${
                      order.cartTotals
                        ? round(order.cartTotals.totalWithTaxes)
                        : '-'
                    }`,
                    order.redeemExpirationDate
                      ? `Viimeinen lunastusajankohta ${order.redeemExpirationDate}`
                      : ''
                  ]}
                  metaIcon="ArrowForward"
                  onClick={() =>
                    history.push(
                      order.uniqoreFriendlyId
                        ? `/view-order/${order.uniqoreFriendlyId}`
                        : `/view-order/${order.orderId}`
                    )
                  }
                />
              ))}
            </>
          )}
        </div>
      </Container>
    </>
  )
}

export default OrdersView
