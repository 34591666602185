import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  circleProgressColor: {
    color: '#525252'
  },
  progressText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: '76px',
    color: '#525252',
    marginBottom: '-15px'
  },
  percent: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#525252'
  }
}))

const ProgressCircle: React.FC<{ redeemed: number; redeemable: number }> = ({
  redeemed,
  redeemable
}) => {
  const classes = useStyles()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    let percent = (redeemed / redeemable) * 100
    if (isNaN(percent)) {
      percent = 0
    }
    const timer = setTimeout(() => {
      setProgress(Math.round(percent))
    }, 500)
    return () => clearTimeout(timer)
  }, [redeemed, redeemable])

  return (
    <Box
      position="relative"
      display="inline-flex"
      style={{ justifyContent: 'center' }}
    >
      <div style={{ display: 'grid' }}>
        <CircularProgress
          variant="determinate"
          style={{ gridColumn: '1', gridRow: '1', color: '#EEEEEE' }}
          size={160}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          style={{ gridColumn: '1', gridRow: '1' }}
          classes={{ colorPrimary: classes.circleProgressColor }}
          size={160}
          value={progress > 100 ? 100 : progress}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <Typography
              className={classes.progressText}
            >{`${redeemed}/${redeemable}`}</Typography>
            <Typography className={classes.percent}>{`${Math.round(
              progress
            )}%`}</Typography>
          </div>
        </Box>
      </div>
    </Box>
  )
}

export default ProgressCircle
