import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  BottomBar
} from 'uniqore-components'
import { changeBulkOrder } from 'features/BulkOrder'
import { AppState } from 'store'
import { useLogOut } from 'components/BulkOrder/LoginPage'
import { validateEmail } from 'api/cloudFunctions'

const ContactPerson: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const logOut = useLogOut()

  const { firstName, lastName, phone, email } = useSelector(
    (state: AppState) => state.bulkOrder.personContact
  )

  const { campaign } = useSelector((state: AppState) => state.bulkOrder)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeBulkOrder({ value: 4, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!campaign.id) {
      history.push('/bulkorder-select-campaign')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const [firstNameValid, setFirstNameValid] = useState<string>('')
  const [lastNameValid, setLastNameValid] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')

  const goBack = () => {
    history.push('/bulkorder-company-details')
  }

  const validateFields = () => {
    let valid = true
    if (firstName === '') {
      setFirstNameValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setFirstNameValid('')
    }
    if (lastName === '') {
      setLastNameValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setLastNameValid('')
    }
    if (phone === '') {
      setPhoneValid('Kenttä ei voi olla tyhjä.')
      valid = false
    } else {
      setPhoneValid('')
    }
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite.')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validateFields()) {
      history.push('/bulkorder-invoice-details')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Takaisin"
        actions={[
          {
            icon: 'ExitToApp',
            onClick: logOut
          }
        ]}
      />
      <form onSubmit={submit}>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Yhteyshenkilön yhteystiedot"
            body="Lisää yrityksen yhteyshenkilön tiedot."
          />
          <TextField
            name="fname"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'firstName'
                })
              )
            }
            fullWidth
            label="Etunimi"
            errorText={firstNameValid}
          />
          <TextField
            name="lname"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'lastName'
                })
              )
            }
            fullWidth
            label="Sukunimi"
            errorText={lastNameValid}
          />
          <TextField
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'email'
                })
              )
            }
            fullWidth
            label="Sähköposti"
            errorText={emailValid}
          />
          <TextField
            type="tel"
            name="phone"
            autoComplete="tel"
            value={phone}
            onChange={(e) =>
              dispatch(
                changeBulkOrder({
                  value: e.target.value,
                  stateKey: 'personContact',
                  stateKeyExtra: 'phone'
                })
              )
            }
            fullWidth
            label="Puhelinnumero"
            errorText={phoneValid}
          />
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              type="submit"
            />
          ]}
        />
      </form>
    </Container>
  )
}

export default ContactPerson
