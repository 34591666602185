import React, { useReducer, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import {
  Container,
  BottomBar,
  Button,
  CatalogItem,
  Heading
} from 'uniqore-components'
import { updateProduct } from 'features/order'
import { round } from 'utils/common'
import { Product } from 'types/types'
import { AppState } from 'store'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function reducer(state: Product[], action: any) {
  switch (action.type) {
    case 'init':
      state = action.payload
      return state
    case 'increase':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! += 1) }
        } else {
          return item
        }
      })
    case 'decrease':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity! -= 1) }
        } else {
          return item
        }
      })
    case 'remove':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity = 0) }
        } else {
          return item
        }
      })
    case 'setQuantity':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          return { ...item, quantity: (item.quantity = action.payload.value) }
        } else {
          return item
        }
      })
    default:
      return state
  }
}

export const setSeasonBoxTexts = (text: string): string => {
  let name = text
  if (text === 'Normaali') {
    name = 'Perus Sesonkiruokalaatikko'
  }

  // De-comment this part when using nut-free instead of gluten-free text
  // if (text === 'Gluteeniton') {
  //   name = 'Pähkinätön'
  // }
  // if (text === 'Vegaaninen ja gluteeniton') {
  //   name = 'Vegaaninen ja pähkinätön'
  // }
  return name
}

const OrderBoxType: React.FC<{
  open: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ open, setOpenDialog }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { shippingCargo, boxMinimumOrder } = useSelector(
    (state: AppState) => state.airtableData
  )
  const { products } = useSelector((state: AppState) => state.order)
  const [productWithTypes, setProductWithTypes] = useState<Product>()
  const [typesUpdated, setTypesUpdated] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [items, dispatchReducer] = useReducer(reducer, [])
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // finds Ruokalaatikko with different types (vegaaninen etc...)
  useEffect(() => {
    products.forEach((product: Product) => {
      if (Object.prototype.hasOwnProperty.call(product, 'types')) {
        setProductWithTypes(product)
      }
    })
  }, [products])

  // sets productWithTypes types to reducer
  useEffect(() => {
    if (productWithTypes) {
      const setProducts = JSON.parse(JSON.stringify(productWithTypes.types))
      dispatchReducer({
        type: 'init',
        payload: setProducts
      })
    }
  }, [productWithTypes])

  // calculate total amount
  useEffect(() => {
    if (items.length > 0) {
      setTotal(items.reduce((a, { quantity }) => a + quantity, 0))
    }
  }, [items])

  const handleCancel = () => {
    setOpenDialog(false)
  }

  const handleAdd = () => {
    const updateProductWithTypes = JSON.parse(JSON.stringify(productWithTypes))
    updateProductWithTypes!.types = items
    updateProductWithTypes!.quantity = total
    setProductWithTypes(updateProductWithTypes)
    setTypesUpdated(true)
  }

  useEffect(() => {
    if (typesUpdated) {
      setTypesUpdated(false)
      dispatch(
        updateProduct({
          id: productWithTypes!.id,
          product: productWithTypes as Product
        })
      )
      setOpenDialog(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typesUpdated])

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => setOpenDialog(false)}
      TransitionComponent={Transition}
    >
      <Container>
        <div style={{ marginBottom: 120 }}>
          <Heading
            title="Tilauksen sisältö"
            body="Valitse alta Sesonkiruokalaatikoiden määrä. Tuotteet sisältävät toimituskustannukset 10,00 € per toimitus (alv 0%). Kaikki Sesonkiruokalaatikot ovat gluteenittomia ja laktoosittomia."
          />
          {items
            .sort((a, b) => a.showOrder! - b.showOrder!)
            .map((item) => (
              <CatalogItem
                key={item.id}
                itemId={item.id}
                variant="multi"
                dispatch={dispatchReducer}
                selected={item.quantity > 0}
                quantity={item.quantity}
                image={item.image}
                text={setSeasonBoxTexts(item.text)}
                subtitles={[
                  `${round(item.price + shippingCargo.price)} (alv 0%)`
                ]}
              />
            ))}
          <Heading
            title={`Yhteensä: ${total} kpl`}
            body={`Minimitilaus: ${boxMinimumOrder} kpl`}
          />
          <BottomBar
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                variant="default"
                border
                disablePadding
                fullWidth
                label="Takaisin"
                onClick={handleCancel}
              />,
              <Button
                color="primary"
                disablePadding
                fullWidth
                label="Valitse"
                onClick={handleAdd}
                disabled={total < boxMinimumOrder}
              />
            ]}
          />
        </div>
      </Container>
    </Dialog>
  )
}

export default OrderBoxType
