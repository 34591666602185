import React, { useState, useEffect } from 'react'
import xss from 'xss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  BottomBar,
  i18n
} from 'uniqore-components'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'

const RedeemContact: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false)
  const { address, notes, zip, city, recipient } = useSelector(
    (state: AppState) => state.campaignRedeem.deliveryDetails
  )
  const { language, redemptionCode } = useSelector(
    (state: AppState) => state.campaignRedeem
  )
  const { firstName, lastName } = useSelector(
    (state: AppState) => state.campaignRedeem.contactDetails
  )

  const { t } = useTranslation()

  // When this page renders, remove deliveryDate from campaignRedeem-reducer
  // to prevent any wrong delivery dates for different products
  useEffect(() => {
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'deliveryDate'
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      i18n.changeLanguage(language)
      dispatch(changeCampaignRedeem({ value: 4, stateKey: 'currentView' })) //TODO: DISCONTINUED CONTRY SELECTION, CHANGE VALUE TO 5 IF COUNTRY SELECTION IS RETURNED
    }
  }, [language, redemptionCode, history, dispatch])

  useEffect(() => {
    if (recipient === '') {
      dispatch(
        changeCampaignRedeem({
          value: `${firstName} ${lastName}`,
          stateKey: 'deliveryDetails',
          stateKeyExtra: 'recipient'
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [recipientValid, setRecipientValid] = useState<string>('')
  const [addressValid, setAddressValid] = useState<string>('')
  const [zipCodeValid, setZipCodeValid] = useState<string>('')
  const [cityValid, setCityValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (recipient === '') {
      setRecipientValid(t('empty-field'))
      valid = false
    } else {
      setRecipientValid('')
    }
    if (city === '') {
      setCityValid(t('empty-field'))
      valid = false
    } else {
      setCityValid('')
    }
    if (zip.length !== 5) {
      setZipCodeValid(t('zip-code-invalid'))
      valid = false
    } else {
      setZipCodeValid('')
    }
    if (address === '') {
      setAddressValid(t('empty-field'))
      valid = false
    } else {
      setAddressValid('')
    }
    return valid
  }

  const goBack = () => {
    history.push('/campaign-contact-details')
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      history.push('/campaign-delivery-date')
    }
  }

  return (
    <>
      <Container>
        <AppBar
          mainAction={{
            icon: 'ArrowBack',
            onClick: goBack
          }}
          text={t('button-back')}
          actions={[
            {
              icon: 'HelpOutline',
              onClick: () => setHelpDialogOpen(true)
            }
          ]}
        />
        <form onSubmit={submit}>
          <div style={{ marginBottom: 120 }}>
            <Heading
              title={t('delivery-information-title')}
              subtitle={t('delivery-information-subtitle')}
            />
            <TextField
              value={recipient}
              onChange={(e) =>
                dispatch(
                  changeCampaignRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'recipient'
                  })
                )
              }
              fullWidth
              label={t('recipient')}
              errorText={recipientValid}
            />
            <TextField
              name="ship-address"
              autoComplete="shipping street-address"
              value={address}
              onChange={(e) =>
                dispatch(
                  changeCampaignRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'address'
                  })
                )
              }
              fullWidth
              label={t('address')}
              errorText={addressValid}
            />
            <TextField
              name="ship-zip"
              autoComplete="shipping postal-code"
              value={zip}
              onChange={(e) =>
                dispatch(
                  changeCampaignRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'zip'
                  })
                )
              }
              fullWidth
              label={t('zip-code')}
              errorText={zipCodeValid}
            />
            <TextField
              name="ship-city"
              autoComplete="shipping locality"
              value={city}
              onChange={(e) =>
                dispatch(
                  changeCampaignRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'city'
                  })
                )
              }
              fullWidth
              label={t('city')}
              errorText={cityValid}
            />
            <TextField
              value={notes}
              onChange={(e) =>
                dispatch(
                  changeCampaignRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'notes'
                  })
                )
              }
              fullWidth
              label={t('additionalInfo')}
            />
          </div>
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                variant="default"
                border
                disablePadding
                fullWidth
                label={t('button-previous')}
                onClick={goBack}
              />,
              <Button
                color="primary"
                disablePadding
                fullWidth
                label={t('button-forward')}
                type="submit"
              />
            ]}
          />
        </form>
      </Container>
      <Dialog
        onClose={() => setHelpDialogOpen(false)}
        aria-labelledby="dialog-title"
        open={helpDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent id="dialog-title">
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: xss(
                i18n.language === 'en'
                  ? 'We deliver to all parts of Finland, and the delivery time you see is based on the postal code you enter. In the metropolitan area and many large cities, we operate through Collico transportation. In this case, you will receive a text message about the exact delivery time the night before at 8 p.m. <br /><br /> If you live in a smaller city, or in a sparsely populated area, we will deliver your Venner-product to Matkahuolto. Delivery time is probably the day time of the day we indicate. Matkahuolto will only announce a more specific delivery time when the driver leaves to take the package. <br /><br /> If your postal code falls outside the basic transport route of both Matkahuolto and Collico, we will contact you separately about home delivery.'
                  : 'Toimitamme joka puolelle Suomeen, ja näkemäsi toimitusaika pohjautuu antamaasi postinumeroosi. Pääkaupunkiseudulla ja monessa suuressa kaupungissa toimimme Collicon kuljetuksen kautta. Tällöin saat tekstiviestin tarkemmasta toimitusajasta edellisenä iltana klo 20. <br /><br /> Mikäli asut pienemmässä kaupungissa, tai haja-asutusalueella, toimitamme sinulle Venner-tuotteesi Matkahuollon kuljetuksella. Toimitusaika on todennäköisesti päiväsaikaan ilmoittamanamme päivänä. Matkahuolto ilmoittaa tarkemman toimitusajankohdan vasta kun kuljettaja lähtee viemään pakettia. <br /><br /> Mikäli postinumerosi osuu sekä Matkahuollon että Collicon peruskuljetusreitin ulkopuolelle, otamme sinuun erikseen yhteyttä kotiintoimituksesta.'
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label={t('button-ok')}
            color="primary"
            disablePadding
            onClick={() => setHelpDialogOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RedeemContact
