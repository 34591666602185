import React, { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Container,
  Dialog,
  Heading,
  BottomBar,
  Button,
  SmallPrint
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import { getDeliveryDates } from 'utils/common'
import { changeOrder } from 'features/order'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'

const searchZip = gql`
  query searchZip($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

const OrderDeliveryDate: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    deliveryDate,
    companyContact,
    deliveryContact,
    isDynamicProduct,
    products
  } = useSelector((state: AppState) => state.order)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openCargoDialog, setOpenCargoDialog] = useState<boolean>(false)
  const [dates, setDates] = useState<string[]>([])
  const [zip, setZip] = useState<string>('')
  const [fetchZipData, { data, loading, error }] = useLazyQuery(searchZip, {
    variables: {
      id: process.env.REACT_APP_SEARCH_ZIP_ID,
      parameters: { zip }
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeUtils({ value: 5, stateKey: 'currentView' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set zip code
  useEffect(() => {
    if (companyContact.deliveryAddress) {
      const trimZip = companyContact.zip.trim()
      setZip(trimZip)
    } else {
      const trimZip = deliveryContact.zip.trim()
      setZip(trimZip)
    }
  }, [companyContact.deliveryAddress, companyContact.zip, deliveryContact.zip])

  // when zip is set, fetch dates
  useEffect(() => {
    if (zip) {
      fetchZipData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip])

  // Show Dialog if Dynamic Product Cargo-delivery has notes added (from Airtable)
  useEffect(() => {
    if (isDynamicProduct) {
      if (
        products[0].deliveryProducts &&
        products[0].deliveryProducts['Cargo']
      ) {
        if (products[0].deliveryProducts['Cargo'].notes) {
          setOpenCargoDialog(true)
        }
      }
    }
  }, [isDynamicProduct, products])

  // fetching dates, if no dates are found, show Dialog
  useEffect(() => {
    if (data) {
      let getDates: string[] = []
      if (data.fetchDataview.data.records.length !== 0) {
        const fields = data.fetchDataview.data.records[0].fields
        if (!isDynamicProduct) {
          // Sesonkiruokalaatikot
          getDates = getDeliveryDates(
            fields.CargoDeliveryDay,
            fields.DaysAheadBuffer,
            fields.SelectableWeeksCount
          )
        } else {
          // Dynamic Product
          getDates = getDeliveryDates(
            fields.CargoDeliveryDay,
            products[0].daysAheadBuffer || fields.DaysAheadBuffer,
            products[0].selectableWeeksCount || fields.SelectableWeeksCount,
            undefined, // no HomeDeliveryTime
            products[0]
          )
        }
      }
      setDates(getDates)
      if (getDates.length < 1) {
        setOpenDialog(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDynamicProduct])

  const goBack = () => {
    dispatch(
      changeOrder({
        value: '',
        stateKey: 'deliveryDate'
      })
    )
    dispatch(changeUtils({ value: 4, stateKey: 'currentView' }))
    history.push('/order-contact-company')
  }

  const goForward = (fromDialog: boolean) => {
    if (fromDialog) {
      dispatch(
        changeOrder({
          value: 'Olemme sinuun yhteydessä',
          stateKey: 'deliveryDate'
        })
      )
    }
    dispatch(changeUtils({ value: 6, stateKey: 'currentView' }))
    history.push('/order-contact-person')
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        />
      )}
      <Container>
        <AppBar
          mainAction={{
            icon: 'ArrowBack',
            onClick: goBack
          }}
          text="Takaisin"
        />
        <div style={{ marginBottom: 120 }}>
          <Heading
            title={
              dates.length === 0
                ? 'Toimituspäiviä haetaan'
                : 'Toimituspäivä toimipisteelle'
            }
            body={
              isDynamicProduct
                ? 'Tuotteet toimitetaan toimipisteelle kertatoimituksena.'
                : 'Venner-ruokalaatikot toimitetaan toimipisteelle kertatoimituksena.'
            }
          />
          {dates.map((date, i) => (
            <Button
              key={i}
              checked={deliveryDate === date}
              customColor={{
                color: deliveryDate === date ? 'black' : '',
                backgroundColor: deliveryDate === date ? '#CEE3DF' : ''
              }}
              role="radio"
              label={date}
              value={date}
              onClick={() =>
                dispatch(
                  changeOrder({
                    value: date,
                    stateKey: 'deliveryDate'
                  })
                )
              }
            />
          ))}
          {dates.length > 0 && (
            <SmallPrint text="Toimitukset arkipäivinä klo 8-16 välillä." />
          )}
        </div>
        <BottomBar
          style={{ position: 'absolute', bottom: 30, width: '100%' }}
          maxWidth="sm"
          components={[
            <Button
              variant="default"
              border
              disablePadding
              fullWidth
              label="Edellinen"
              onClick={goBack}
              disabled={loading}
            />,
            <Button
              color="primary"
              disablePadding
              fullWidth
              label="Seuraava"
              onClick={() => goForward(false)}
              disabled={deliveryDate === ''}
            />
          ]}
        />
      </Container>
      {/* Dialog if no delivery dates found */}
      <Dialog
        open={openDialog}
        onClose={() => {}}
        title="Huomio tilauksestasi"
        subtitle="Olemme sinuun henkilökohtaisesti yhteyksissä tarkemman toimituspäivän osalta. Voit viedä tilauksen normaalisti loppuun."
        actionComponents={[
          <Button
            border
            label="Edellinen"
            variant="text"
            disablePadding
            onClick={goBack}
          />,
          <Button
            label="Seuraava"
            color="primary"
            disablePadding
            onClick={() => goForward(true)}
          />
        ]}
      />
      {/* Dialog if dynamic product Cargo delivery has notes (From Airtable) */}
      <Dialog
        open={openCargoDialog}
        onClose={() => setOpenCargoDialog(false)}
        title="Huomio tilauksestasi"
        subtitle={
          products[0].deliveryProducts && products[0].deliveryProducts['Cargo']
            ? products[0].deliveryProducts['Cargo'].notes
            : ''
        }
        actionComponents={[
          <Button
            label="OK"
            color="primary"
            disablePadding
            onClick={() => setOpenCargoDialog(false)}
          />
        ]}
      />
      {error && (
        <Dialog
          open={true}
          onClose={() => {}}
          title="Huomio tilauksestasi"
          subtitle="Jokin meni toimituspäivän haussa pieleen. Palaa takaisin ja yritä uudelleen."
          actionComponents={[
            <Button
              label="Takaisin"
              color="primary"
              disablePadding
              onClick={goBack}
            />
          ]}
        />
      )}
    </>
  )
}

export default OrderDeliveryDate
