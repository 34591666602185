import React, { useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  ListItem,
  SmallPrint,
  i18n
} from 'uniqore-components'
import { AppState } from 'store'
import { changeCampaignRedeem, CampaignRedeem } from 'features/campaignRedeem'
import { RedeemContactDetails, RedeemDeliveryDetails } from 'features/redeem'
import SpinnerMui from 'components/Common/SpinnerMui'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { formatDate } from 'utils/common'
import { redeemBulkOrders } from 'api/cloudFunctions'

const useStyles = makeStyles(() => ({
  text: {
    '& a': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
}))

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

// Collico API does not like white spaces, remove them
const trimContactDetails = (contactDetails: RedeemContactDetails) => {
  const fixFirstName = contactDetails.firstName.trim()
  contactDetails.firstName = fixFirstName
  const fixLastName = contactDetails.lastName.trim()
  contactDetails.lastName = fixLastName
  const fixEmail = contactDetails.email.trim()
  contactDetails.email = fixEmail
  const trimPhoneNumber = contactDetails.phone.trim()
  const removePossibleParenthesesPhone = trimPhoneNumber.replace(/([()])/g, '')
  contactDetails.phone = removePossibleParenthesesPhone
}
const trimDeliveryDetails = (deliveryDetails: RedeemDeliveryDetails) => {
  const fixRecipient = deliveryDetails.recipient.trim()
  deliveryDetails.recipient = fixRecipient
  const fixAddress = deliveryDetails.address.trim()
  deliveryDetails.address = fixAddress
  const fixZip = deliveryDetails.zip.trim()
  deliveryDetails.zip = fixZip
  const fixCity = deliveryDetails.city.trim()
  deliveryDetails.city = fixCity
  const fixNotes = deliveryDetails.notes.trim()
  deliveryDetails.notes = fixNotes
}

const RedeemSummary: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    selectedCountry,
    language,
    redemptionCode,
    selectedProduct,
    selectedProductType,
    forCharity,
    foreignDeliveryDate
  } = useSelector((state: AppState) => state.campaignRedeem)
  const redeem = useSelector((state: AppState) => state.campaignRedeem)
  const [error, setError] = useState<boolean>(false)
  const [errorText] = useState<string>('')
  const { t } = useTranslation()
  const [redeemLoading, setRedeemLoading] = React.useState<boolean>(false)
  const [formCall, { loading: mutationLoading }] = useMutation(formQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.ingestForm.errors) {
        dispatch(changeCampaignRedeem({ value: 0, stateKey: 'currentView' }))
        history.push('/campaign-redeem-confirmation')
      } else {
        setError(true)
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setError(true)
      }
    }
  })

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      dispatch(changeCampaignRedeem({ value: 6, stateKey: 'currentView' })) //TODO: DISCONTINUED CONTRY SELECTION, CHANGE VALUE TO 7 IF COUNTRY SELECTION IS RETURNED
      i18n.changeLanguage(language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, redemptionCode, selectedCountry])

  const goBack = () => {
    if (selectedCountry === 'Suomi') {
      history.push('/campaign-delivery-date')
    } else {
      history.push('/campaign-contact-details')
    }
  }

  const submit = () => {
    setError(false)

    const sendCampaignRedeem: CampaignRedeem = JSON.parse(
      JSON.stringify(redeem)
    )
    sendCampaignRedeem.codeRedeemed = new Date()
    const deleteKeys = ['currentView', 'products', 'redemptionEmail']
    //@ts-ignore
    deleteKeys.forEach((v) => delete sendCampaignRedeem[v])

    // Set delivery date in English format if english is selected as language
    sendCampaignRedeem.deliveryDateForEmail = setDeliveryDateInfo(
      sendCampaignRedeem.deliveryDate
    )

    // Collico API does not like white spaces, remove them
    trimContactDetails(sendCampaignRedeem.contactDetails)
    trimDeliveryDetails(sendCampaignRedeem.deliveryDetails)

    // Makes sure that this order doesn't go to Collico API
    // (see backend rule' Send redeem to Collico v2')
    if (
      sendCampaignRedeem.selectedCountry === 'Viro' ||
      sendCampaignRedeem.selectedCountry === 'Ei valintaa'
    ) {
      // this prevents sending redeem to Collico
      sendCampaignRedeem.deliveryDate = 'Olemme sinuun yhteydessä'
      // This prevents sending 'reuna-alue' -email to Venner if country is not Finland
      sendCampaignRedeem.delivery.method = 'Collico'
    }

    // Create a unique redeem id, this is used when updating Airtable's Redeemers-table rows
    sendCampaignRedeem.redeemId = uuid()

    setRedeemLoading(true)
    redeemBulkOrders({
      email: redeem.redemptionEmail,
      engagementId: redeem.engagementId,
      // for some reason VSCode things that the code can be Date?
      giftCode: redeem.redemptionCode as string
    })
      .then((result) => {
        if (result.data.isOk) {
          formCall({
            variables: {
              id: process.env.REACT_APP_SEND_REDEEM_CAMPAIGN,
              form: sendCampaignRedeem
            }
          })
        } else {
          setError(true)
        }
      })
      .catch(() => setError(true))
      .finally(() => setRedeemLoading(false))
  }

  const setDeliveryDateInfo = (deliveryDate: string): string => {
    let result = deliveryDate
    if (deliveryDate === 'Olemme sinuun yhteydessä') {
      result =
        i18n.language === 'fi'
          ? deliveryDate
          : 'We will contact you regarding the shipment'
    } else if (i18n.language === 'en' && deliveryDate.includes('klo')) {
      result = deliveryDate.replace('klo', 'at')
    }
    return result
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text={t('button-back')}
      />
      <div style={{ marginBottom: error || errorText ? 250 : 200 }}>
        <Heading
          title={t('order-summary-title')}
          body={t('order-summary-subtitle')}
        />
        <ListItem
          text={forCharity ? t('for-charity-text') : selectedProduct}
          subtitles={[selectedProductType]}
          onClick={() => history.push('campaign-select-package')}
          metaIcon="Edit"
        />
        <ListItem
          text={t('contact-information')}
          subtitles={[
            `${redeem.contactDetails.firstName} ${redeem.contactDetails.lastName}`,
            redeem.contactDetails.phone,
            redeem.contactDetails.email,
            redeem.contactDetails.mailingList ? t('mailinglist-message') : ''
          ]}
          metaIcon="Edit"
          onClick={() => history.push('campaign-contact-details')}
        />
        {!forCharity &&
          (selectedCountry === 'Suomi' ? (
            <>
              <ListItem
                text={t('delivery-address')}
                subtitles={[
                  redeem.deliveryDetails.recipient,
                  redeem.deliveryDetails.address,
                  `${redeem.deliveryDetails.zip} ${redeem.deliveryDetails.city}`,
                  redeem.deliveryDetails.notes
                    ? `${
                        i18n.language === 'fi'
                          ? 'Lisätiedot:'
                          : 'Additional info:'
                      } ${redeem.deliveryDetails.notes}`
                    : ''
                ]}
                metaIcon="Edit"
                onClick={() => history.push('campaign-delivery-information')}
              />
              <ListItem
                text={t('delivery-date')}
                subtitles={[setDeliveryDateInfo(redeem.deliveryDate)]}
                metaIcon="Edit"
                onClick={() => history.push('campaign-delivery-date')}
              />
            </>
          ) : (
            <Heading
              align="left"
              body={
                i18n.language === 'en'
                  ? `The order will be delivered to the office on ${formatDate(
                      foreignDeliveryDate
                    )}`
                  : `Tilaus toimitetaan toimistolle ${formatDate(
                      foreignDeliveryDate
                    )}`
              }
            />
          ))}
      </div>
      <Container style={{ position: 'absolute', bottom: 40, width: '100%' }}>
        <SmallPrint className={classes.text} htmlText={t('summary-note')} />
        {error ? (
          <Heading
            body={
              i18n.language === 'fi'
                ? 'Jokin meni pieleen. Yritä uudelleen.'
                : 'Something went wrong, please try again.'
            }
          />
        ) : null}
        {mutationLoading || redeemLoading ? (
          <SpinnerMui
            size={30}
            style={{
              position: 'absolute',
              bottom: '13px',
              width: '100%',
              textAlign: 'center'
            }}
          />
        ) : null}
        <Button
          maxWidth="sm"
          label={t('button-place-order')}
          color="primary"
          fullWidth
          disabled={mutationLoading || redeemLoading}
          onClick={submit}
        />
      </Container>
    </Container>
  )
}

export default RedeemSummary
