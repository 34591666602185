import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar, Button, Onboarding } from 'uniqore-components'
import VennerLogo from 'assets/venner-logo.svg'

const Welcome: React.FC = () => {
  const history = useHistory()

  const goForward = () => {
    history.push('/order')
  }

  return (
    <>
      <Onboarding
        appbar={<AppBar maxWidth="sm" backgroundColor="transparent" />}
        screens={[
          {
            image: VennerLogo,
            // image:
            //   'https://uploads-ssl.webflow.com/5fa15e111bdcf3ea29f1fec1/60a4a7147fecb0511c73ce60_onboarding1-3.svg',
            title: 'Tervetuloa!',
            subtitle:
              'Kiitos, että olet kiinnostunut Venneristä. Jatkaessasi tilaustasi tai siirtymällä omille sivuille hyväksyt käyttämämme evästeet.'
          }
        ]}
        showIndicators={false}
        actionDirection="column"
        actionComponents={[
          <Button
            color="primary"
            label="Siirry tilaamaan ensimmäistä kertaa"
            fullWidth
            onClick={goForward}
          />,
          <Button
            variant="default"
            underline
            disablePadding
            label="Kirjaudu sisään omalle tilille"
            fullWidth
            onClick={() => history.push('/login')}
          />
        ]}
      />
    </>
  )
}

export default Welcome
