import { createSlice } from '@reduxjs/toolkit'
import { Product, Shipping, SupportCard } from 'types/types'

export interface Redeemer {
  firstName: string
  lastName: string
  email: string
  date: string
  time: string
}

export interface CompanyContact {
  companyName: string
  businessId: string
  address: string
  zip: string
  city: string
  deliveryAddress: boolean
}

export interface DeliveryContact {
  companyName: string
  address: string
  zip: string
  city: string
}

export interface PersonContact {
  firstName: string
  lastName: string
  email: string
  phone: string
  mailingList: boolean
}

export interface Order {
  orderId: string
  engagementId: string | undefined
  uniqoreFriendlyId: string | undefined
  feedback: 0 | 1 | 2 | 3 | 4
  status: 'Processing' | 'Opened' | 'Rejected' | 'Closed' | null
  orderDate: string
  orderTime: string
  orderType: 'Giftcard' | 'Cargo' | 'Charity' | ''
  giftCardDeliveryType?: 'Home' | 'Pickup'
  redeemExpirationDate?: string
  redeemers?: Redeemer[]
  isDynamicProduct: boolean
  products: Product[]
  shipping: Shipping[]
  deliveryDate: string
  collicoCargoDeliveryDate: string
  companyContact: CompanyContact
  deliveryContact: DeliveryContact
  personContact: PersonContact
  supportCard: SupportCard
  billing: {
    email: string
    eInvoice: boolean
    ovtId: string
    operatorId: string
    additionalInfoActive: boolean
    additionalInfo: string
  }
  cartTotals: {
    totalNoTaxes: number
    productTaxes: number
    shippingTaxes: number
    totalWithTaxes: number
    totalAid: number
  }
}

interface ChangeOrderAction {
  payload: {
    value: number | string | boolean
    stateKey: keyof Order
    stateKeyExtra?: string
  }
}

interface UpdateProductsAction {
  payload: {
    products: Product[]
  }
}

interface UpdateProductAction {
  payload: {
    id: string
    product: Product
  }
}

export const initialState: Order = {
  orderId: '',
  engagementId: undefined,
  uniqoreFriendlyId: undefined,
  feedback: 0,
  status: null,
  orderDate: '',
  orderTime: '',
  orderType: '',
  isDynamicProduct: false,
  products: [],
  shipping: [],
  deliveryDate: '',
  collicoCargoDeliveryDate: '',
  companyContact: {
    companyName: '',
    businessId: '',
    address: '',
    zip: '',
    city: '',
    deliveryAddress: true
  },
  deliveryContact: {
    companyName: '',
    address: '',
    zip: '',
    city: ''
  },
  personContact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mailingList: false
  },
  supportCard: {
    showCompanyName: true,
    showQuantity: true,
    text: ''
  },
  billing: {
    email: '',
    eInvoice: true,
    ovtId: '',
    operatorId: '',
    additionalInfoActive: false,
    additionalInfo: ''
  },
  cartTotals: {
    totalNoTaxes: 0,
    productTaxes: 0,
    shippingTaxes: 0,
    totalWithTaxes: 0,
    totalAid: 0
  }
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    changeOrder: (state, { payload }: ChangeOrderAction) => {
      if (payload.stateKeyExtra) {
        // @ts-ignore
        state[payload.stateKey][payload.stateKeyExtra] = payload.value
      } else {
        // @ts-ignore
        state[payload.stateKey] = payload.value
      }
    },
    emptyOrderDetails: () => {
      return initialState
    },
    updateProducts: (state, { payload }: UpdateProductsAction) => {
      state.products = payload.products
    },
    updateProduct: (state, { payload }: UpdateProductAction) => {
      state.products = state.products.map((product) =>
        payload.id === product.id ? payload.product : product
      )
    }
  }
})

export const { changeOrder, emptyOrderDetails, updateProducts, updateProduct } =
  orderSlice.actions
export default orderSlice.reducer
