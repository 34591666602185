import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Container, ListItem, Heading, i18n } from 'uniqore-components'
import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/types'

const getCharityAirtableId = (products: Product[]): string => {
  // 'rectKSBzcCEQqlNlA', // PRODUCTION Airtable Id for charity
  // 'recqIJAZYoy8ZIRbh', // PILOT Airtable Id for charity
  let result = 'rectKSBzcCEQqlNlA'
  products.forEach((product: Product) => {
    if (product.sku === 'lahjoitus') {
      result = product.id
    }
  })
  return result
}

const SelectPackage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const airtableData = useSelector((state: AppState) => state.airtableData)
  const { language, redemptionCode, products, showCharity } = useSelector(
    (state: AppState) => state.campaignRedeem
  )

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    } else {
      i18n.changeLanguage(language)
      dispatch(changeCampaignRedeem({ value: 1, stateKey: 'currentView' }))
    }
  }, [language, redemptionCode, history, dispatch])

  const goBack = () => {
    history.push('campaign-redeem-onboarding')
  }

  const handleSelect = (product?: Product) => {
    dispatch(changeCampaignRedeem({ value: '', stateKey: 'deliveryDate' }))
    // TODO: if charity not selected, change country back to default (Suomi as long as there is no country select)
    dispatch(
      changeCampaignRedeem({
        value: 'Suomi',
        stateKey: 'selectedCountry'
      })
    )
    // is not SeasonBox
    if (product) {
      dispatch(
        changeCampaignRedeem({ value: true, stateKey: 'isDynamicProduct' })
      )
      dispatch(
        changeCampaignRedeem({ value: product.text, stateKey: 'boxType' })
      )
      dispatch(
        changeCampaignRedeem({ value: product.id, stateKey: 'redeemedProduct' })
      )
      dispatch(
        changeCampaignRedeem({ value: product.sku, stateKey: 'productSku' })
      )
      dispatch(
        changeCampaignRedeem({
          value: product.gtin as string,
          stateKey: 'productGtin'
        })
      )
      const selectedValue =
        i18n.language === 'fi' ? product.text : product.textEn
      dispatch(
        changeCampaignRedeem({
          value: selectedValue || '',
          stateKey: 'selectedProduct'
        })
      )
      dispatch(changeCampaignRedeem({ value: false, stateKey: 'forCharity' }))
      // TODO: COUNTRY SELECT URL HERE
      // history.push('/campaign-select-country')
      history.push('/campaign-contact-details')
    }
    // is SeasonBox
    else {
      dispatch(
        changeCampaignRedeem({ value: false, stateKey: 'isDynamicProduct' })
      )
      dispatch(changeCampaignRedeem({ value: false, stateKey: 'forCharity' }))
      history.push('/campaign-select-package-type')
    }
    dispatch(
      changeCampaignRedeem({ value: '', stateKey: 'selectedProductType' })
    )
  }

  const charitySelected = () => {
    dispatch(changeCampaignRedeem({ value: '', stateKey: 'boxType' }))
    dispatch(
      changeCampaignRedeem({
        value: getCharityAirtableId(airtableData.products),
        stateKey: 'redeemedProduct'
      })
    )
    dispatch(
      changeCampaignRedeem({
        value: 'Ei valintaa',
        stateKey: 'selectedCountry'
      })
    )
    dispatch(changeCampaignRedeem({ value: '', stateKey: 'productSku' }))
    dispatch(
      changeCampaignRedeem({
        value: '',
        stateKey: 'productGtin'
      })
    )
    dispatch(
      changeCampaignRedeem({ value: false, stateKey: 'isDynamicProduct' })
    )
    dispatch(changeCampaignRedeem({ value: true, stateKey: 'forCharity' }))
    dispatch(
      changeCampaignRedeem({ value: '', stateKey: 'selectedProductType' })
    )
    history.push('/campaign-contact-details')
  }

  const showSeasonalBoxesProduct = (products: Product[]) => {
    let component = null
    let seasonBox = products.find((product) =>
      product.sku.startsWith('ruokalaatikko')
    )
    if (seasonBox) {
      component = (
        <ListItem
          image={
            airtableData.products.find((product) =>
              product.sku.startsWith('ruokalaatikko')
            )?.image
          }
          metaIcon="ArrowForward"
          text={
            i18n.language === 'fi' ? 'Sesonkiruokalaatikko' : 'Seasonal Box'
          }
          hightlightMeta
          onClick={() => {
            dispatch(
              changeCampaignRedeem({
                value:
                  i18n.language === 'fi'
                    ? 'Sesonkiruokalaatikko'
                    : 'Seasonal Box',
                stateKey: 'selectedProduct'
              })
            )
            handleSelect()
          }}
        />
      )
    }
    return component
  }

  return (
    <Container>
      <AppBar
        text={t('button-back')}
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => goBack()
        }}
      />
      <Heading title={t('select-product')} />
      {showSeasonalBoxesProduct(products)}
      {products.map((product) =>
        product.isDynamic ? (
          <ListItem
            key={product.id}
            image={product.image}
            metaIcon="ArrowForward"
            text={i18n.language === 'fi' ? product.text : product.textEn}
            subtitles={
              i18n.language === 'fi' ? product.subtitles : product.subtitlesEn
            }
            hightlightMeta
            onClick={() => handleSelect(product)}
          />
        ) : null
      )}
      {showCharity ? (
        <ListItem
          image={
            airtableData.products.find((product) =>
              product.sku.startsWith('lahjoitus')
            )?.image
          }
          metaIcon="ArrowForward"
          text={t('for-charity-text')}
          hightlightMeta
          onClick={charitySelected}
        />
      ) : null}
    </Container>
  )
}

export default SelectPackage
