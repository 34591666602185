import { authLink } from './authLinkPublic'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client'
import { analyticsMiddleware } from './analyticsMiddleware'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL_PUBLIC
})

const PublicClient = new ApolloClient({
  link: from([analyticsMiddleware, authLink, httpLink]),
  cache: new InMemoryCache()
})

export default PublicClient
