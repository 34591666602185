import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  FeedTitle,
  StatusHero,
  SpeedDial,
  FeedCard
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import VennerLogo from 'assets/venner-logo.svg'
import JumpingImage from 'assets/jumping.svg'
import { emptyOrderDetails, Order, Redeemer } from 'features/order'
import { addToOrders, removeOrders } from 'features/orders'
import { changeUtils, addToPersonalFeed, resetUtils } from 'features/utils'
import {
  getDate,
  formatDate,
  createOrderFeed,
  createRedeemFeed,
  sortFeedByDateAndTime,
  sortServiceEngagementsData
} from 'utils/common'
import { Feed } from 'types/types'
import { AppState } from 'store'
import { gql, useLazyQuery } from '@apollo/client'

const getDataQuery = gql`
  query serviceEngagements {
    serviceEngagements {
      id
      idNumber
      created
      stage {
        name
      }
      events {
        id
        created
        key
        description
        metadata {
          key
          visible
          value
        }
      }
      metadata {
        key
        value
      }
    }
  }
`

const StatusHome: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showPage, setShowPage] = useState<boolean>(false)
  const { token, personalFeed, serviceEngagementsFetched, loggedInUserName } =
    useSelector((state: AppState) => state.utils)
  const [getData, { data, loading, error }] = useLazyQuery(getDataQuery, {
    context: {
      headers: {
        'X-Authorization': token ? `CustomerToken ${token}` : ''
      }
    }
  })

  const handleLogout = () => {
    window.localStorage.removeItem('token')
    dispatch(resetUtils())
    dispatch(emptyOrderDetails())
    dispatch(removeOrders())
    history.push('/login')
  }

  // checks for token
  useEffect(() => {
    if (!token) {
      const getToken = window.localStorage.getItem('token')
      if (getToken) {
        dispatch(changeUtils({ value: getToken, stateKey: 'token' }))
        setShowPage(true)
      } else {
        handleLogout()
      }
    } else {
      setShowPage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if token valid, show Home page and fetch data
  useEffect(() => {
    if (showPage) {
      window.scrollTo(0, 0)
      dispatch(changeUtils({ value: 0, stateKey: 'currentView' }))
      if (!serviceEngagementsFetched) {
        getData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPage])

  // get orders from service engagements and store them in orders-state
  useEffect(() => {
    if (data && data.serviceEngagements && !serviceEngagementsFetched) {
      const sortedData = sortServiceEngagementsData(data.serviceEngagements)
      sortedData.orders.forEach((order: Order) => {
        // add orders to orders-state
        dispatch(addToOrders({ order }))
        // create personal feed based on orders-data
        dispatch(addToPersonalFeed({ value: createOrderFeed(order) }))
        if (order.redeemers) {
          order.redeemers.forEach((redeemer: Redeemer) => {
            dispatch(addToPersonalFeed({ value: createRedeemFeed(redeemer) }))
          })
        }
      })
      // set the name of the logged in user based on the first service engagement
      dispatch(
        changeUtils({
          value: sortedData.loggedInName,
          stateKey: 'loggedInUserName'
        })
      )
      // set values for the new order and for feedback and contact us (Ota yhteyttä) -dialog
      dispatch(
        changeUtils({
          value: sortedData.firstName,
          stateKey: 'personContactFirstName'
        })
      )
      dispatch(
        changeUtils({
          value: sortedData.lastName,
          stateKey: 'personContactLastName'
        })
      )
      dispatch(
        changeUtils({ value: sortedData.email, stateKey: 'personContactEmail' })
      )
      dispatch(
        changeUtils({ value: sortedData.phone, stateKey: 'personContactPhone' })
      )
      dispatch(
        changeUtils({ value: sortedData.company, stateKey: 'companyName' })
      )
      dispatch(
        changeUtils({
          value: sortedData.businessId,
          stateKey: 'companyBusinessId'
        })
      )
      dispatch(
        changeUtils({ value: sortedData.address, stateKey: 'companyAddress' })
      )
      dispatch(changeUtils({ value: sortedData.zip, stateKey: 'companyZip' }))
      dispatch(changeUtils({ value: sortedData.city, stateKey: 'companyCity' }))
      dispatch(
        changeUtils({ value: sortedData.ovtId, stateKey: 'companyOvtId' })
      )
      dispatch(
        changeUtils({
          value: sortedData.operatorId,
          stateKey: 'companyOperatorId'
        })
      )
      dispatch(
        changeUtils({ value: true, stateKey: 'serviceEngagementsFetched' })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (error) {
      handleLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleNewOrder = () => {
    dispatch(changeUtils({ value: true, stateKey: 'newOrder' }))
    dispatch(emptyOrderDetails())
    history.push('/order')
  }

  return (
    <>
      {loading && (
        <SpinnerMui
          backdrop
          color="white"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      )}
      <div>
        <AppBar
          maxWidth="sm"
          backgroundColor="#CEE3DF"
          logo={VennerLogo}
          logoSize={60}
        />
        <StatusHero
          maxWidth="sm"
          backgroundColor="#CEE3DF"
          color="#525252"
          image={JumpingImage}
          headline={`Hei ${loggedInUserName}`}
          subtitle="Tee uusi tilaus tai tarkastele aiempia tilauksiasi. Lisävalintoja löydät oikeasta alanurkasta."
          actionComponent={
            <Button
              role="status"
              icon="Add"
              label="Tee uusi tilaus"
              onClick={handleNewOrder}
            />
          }
        />
        <Button
          style={{ textAlign: 'center' }}
          label="Tarkastele tilauksiani"
          onClick={() => history.push('/view-orders')}
          underline
          variant="default"
          icon="Receipt"
        />
        <div style={{ marginBottom: '120px' }}>
          {personalFeed.length > 0 && <FeedTitle text="Viimeiset tapahtumat" />}
          {sortFeedByDateAndTime(personalFeed)
            .slice(0, 10)
            .map((feed: Feed, i: number) => (
              <FeedCard
                key={i}
                avatarBackgroundColor={feed.avatarBackgroundColor}
                user={feed.user}
                date={
                  getDate() === feed.date
                    ? `Tänään ${feed.time}`
                    : `${formatDate(feed.date)} ${feed.time}`
                }
                subtitle={feed.subtitle}
                htmlText={feed.text}
              />
            ))}
        </div>
        <SpeedDial
          style={{ position: 'fixed', bottom: '20px', right: '20px' }}
          actions={[
            {
              icon: 'Receipt',
              label: 'Tarkastele tilauksiani',
              onClick: () => history.push('/view-orders')
            },
            {
              icon: 'Add',
              label: 'Tee uusi tilaus',
              onClick: handleNewOrder
            },
            {
              icon: 'Send',
              label: 'Ota yhteyttä',
              onClick: () => {
                dispatch(
                  changeUtils({ value: true, stateKey: 'showFeedbackDialog' })
                )
              }
            },
            {
              icon: 'Share',
              label: 'Haasta ystäväsi',
              onClick: () => {
                dispatch(changeUtils({ value: true, stateKey: 'showShare' }))
              }
            },
            {
              icon: 'ExitToApp',
              label: 'Kirjaudu ulos',
              onClick: handleLogout
            }
          ]}
        />
      </div>
    </>
  )
}

export default StatusHome
