import { createSlice } from '@reduxjs/toolkit'
import { Campaigns } from 'types/types'

interface AirtableDataState {
  campaigns: Campaigns[]
}

const initialState: AirtableDataState = {
  campaigns: []
}

interface InitCampaign {
  payload: {
    campaigns: Campaigns[]
  }
}

const campaignAirtableDataSlice = createSlice({
  name: 'campaignAirtableData',
  initialState,
  reducers: {
    initCampaigns: (state, { payload }: InitCampaign) => {
      payload.campaigns.forEach((v: Campaigns) => {
        state.campaigns.push(v)
      })
    }
  }
})

export const { initCampaigns } = campaignAirtableDataSlice.actions
export default campaignAirtableDataSlice.reducer
