import { createSlice } from '@reduxjs/toolkit'
import { Feed } from 'types/types'

interface UtilsState {
  token: string
  loggedInUserName: string
  serviceEngagementsFetched: boolean
  latestOrderId: string
  orderIngestFormResult: string
  newOrder: boolean
  orderIdForStatusSwipe: string
  currentView: number
  showFeedbackDialog: boolean
  showShare: boolean
  fromSummary: boolean
  personalFeed: Feed[]
  snackbarOpen: boolean
  snackbarText: string
  personContactFirstName: string
  personContactLastName: string
  personContactEmail: string
  personContactPhone: string
  companyName: string
  companyBusinessId: string
  companyAddress: string
  companyZip: string
  companyCity: string
  companyOvtId: string
  companyOperatorId: string
}

interface ChangeUtilsAction {
  payload: {
    value: number | string | boolean
    stateKey: keyof UtilsState
    stateKeyExtra?: string
  }
}

interface AddToPersonalFeedAction {
  payload: {
    value: Feed
  }
}

const initialState: UtilsState = {
  token: '',
  loggedInUserName: '',
  serviceEngagementsFetched: false,
  latestOrderId: '',
  orderIngestFormResult: '',
  newOrder: false,
  orderIdForStatusSwipe: '',
  currentView: 0,
  showFeedbackDialog: false,
  showShare: false,
  fromSummary: false,
  personalFeed: [],
  snackbarOpen: false,
  snackbarText: '',
  personContactFirstName: '',
  personContactLastName: '',
  personContactEmail: '',
  personContactPhone: '',
  companyName: '',
  companyBusinessId: '',
  companyAddress: '',
  companyZip: '',
  companyCity: '',
  companyOvtId: '',
  companyOperatorId: ''
}

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    changeUtils: (state, { payload }: ChangeUtilsAction) => {
      if (payload.stateKeyExtra) {
        // @ts-ignore
        state[payload.stateKey][payload.stateKeyExtra] = payload.value
      } else {
        // @ts-ignore
        state[payload.stateKey] = payload.value
      }
    },
    addToPersonalFeed: (state, { payload }: AddToPersonalFeedAction) => {
      state.personalFeed = state.personalFeed.concat(payload.value)
    },
    resetUtils: () => {
      return initialState
    }
  }
})

export const { changeUtils, addToPersonalFeed, resetUtils } = utilsSlice.actions
export default utilsSlice.reducer
