import React, { useEffect, useState } from 'react'
import xss from 'xss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  TextField,
  BottomBar
} from 'uniqore-components'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { changeRedeem } from 'features/redeem'
import { AppState } from 'store'

const RedeemDelivery: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { fromSummary } = useSelector((state: AppState) => state.utils)
  const { code, isDynamicProduct } = useSelector(
    (state: AppState) => state.redeem
  )
  const { recipient, address, zip, city, notes } = useSelector(
    (state: AppState) => state.redeem.deliveryDetails
  )
  const { firstName, lastName } = useSelector(
    (state: AppState) => state.redeem.contactDetails
  )
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false)
  const [recipientValid, setRecipientValid] = useState<string>('')
  const [addressValid, setAddressValid] = useState<string>('')
  const [zipValid, setZipValid] = useState<string>('')
  const [cityValid, setCityValid] = useState<string>('')

  const validateFields = () => {
    let valid = true
    if (recipient === '') {
      setRecipientValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setRecipientValid('')
    }
    if (address === '') {
      setAddressValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setAddressValid('')
    }
    if (zip.length !== 5) {
      setZipValid(
        'Postiosoitteen tulee olla 5 merkkiä pitkä, huomio tyhjät välilyönnit alussa tai lopussa'
      )
      valid = false
    } else {
      setZipValid('')
    }
    if (city === '') {
      setCityValid('Kenttä ei voi olla tyhjä')
      valid = false
    } else {
      setCityValid('')
    }
    return valid
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 3, stateKey: 'currentView' }))
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (recipient === '') {
      dispatch(
        changeRedeem({
          value: `${firstName} ${lastName}`,
          stateKey: 'deliveryDetails',
          stateKeyExtra: 'recipient'
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    if (fromSummary) {
      dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
      history.push('/redeem-summary')
    } else {
      dispatch(changeRedeem({ value: 2, stateKey: 'currentView' }))
      history.push('/redeem-contact')
    }
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validateFields()) {
      dispatch(changeRedeem({ value: 4, stateKey: 'currentView' }))
      history.push('/redeem-date')
    }
  }

  return (
    <>
      <Container>
        <AppBar
          mainAction={{
            icon: 'ArrowBack',
            onClick: goBack
          }}
          text={fromSummary ? 'Yhteenveto' : 'Yhteystietosi'}
          actions={
            isDynamicProduct
              ? []
              : [
                  {
                    icon: 'HelpOutline',
                    onClick: () => setHelpDialogOpen(true)
                  }
                ]
          }
        />
        <form onSubmit={submit}>
          <div style={{ marginBottom: 120 }}>
            <Heading
              title="Toimitustiedot"
              body="Toimitamme joka puolelle Suomea."
            />
            <TextField
              value={recipient}
              onChange={(e) =>
                dispatch(
                  changeRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'recipient'
                  })
                )
              }
              fullWidth
              label="Vastaanottaja"
              errorText={recipientValid}
            />
            <TextField
              name="ship-address"
              autoComplete="shipping street-address"
              value={address}
              onChange={(e) =>
                dispatch(
                  changeRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'address'
                  })
                )
              }
              fullWidth
              label="Postiosoite"
              errorText={addressValid}
            />
            <TextField
              name="ship-zip"
              autoComplete="shipping postal-code"
              value={zip}
              onChange={(e) =>
                dispatch(
                  changeRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'zip'
                  })
                )
              }
              fullWidth
              label="Postinumero"
              errorText={zipValid}
            />
            <TextField
              name="ship-city"
              autoComplete="shipping locality"
              value={city}
              onChange={(e) =>
                dispatch(
                  changeRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'city'
                  })
                )
              }
              fullWidth
              label="Kaupunki"
              errorText={cityValid}
            />
            <TextField
              value={notes}
              onChange={(e) =>
                dispatch(
                  changeRedeem({
                    value: e.target.value,
                    stateKey: 'deliveryDetails',
                    stateKeyExtra: 'notes'
                  })
                )
              }
              fullWidth
              label="Lisätiedot (esim. ovikoodi)"
            />
          </div>
          <BottomBar
            style={{ position: 'absolute', bottom: 30, width: '100%' }}
            maxWidth="sm"
            components={[
              <Button
                variant="default"
                border
                disablePadding
                fullWidth
                label="Edellinen"
                onClick={goBack}
              />,
              <Button
                color="primary"
                disablePadding
                fullWidth
                label="Seuraava"
                type="submit"
              />
            ]}
          />
        </form>
      </Container>
      <Dialog
        onClose={() => setHelpDialogOpen(false)}
        aria-labelledby="dialog-title"
        open={helpDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent id="dialog-title">
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: xss(
                'Toimitamme joka puolelle Suomeen, ja näkemäsi toimitusaika pohjautuu antamaasi postinumeroosi. Pääkaupunkiseudulla ja monessa suuressa kaupungissa toimimme Collicon kuljetuksen kautta. Tällöin saat tekstiviestin tarkemmasta toimitusajasta edellisenä iltana klo 20.<br /><br />Mikäli asut pienemmässä kaupungissa, tai haja-asutusalueella, toimitamme sinulle Sesonkiruokalaatikkosi Matkahuollon kuljetuksella. Toimitusaika on todennäköisesti päiväsaikaan ilmoittamanamme päivänä. Matkahuolto ilmoittaa tarkemman toimitusajankohdan vasta kun kuljettaja lähtee viemään pakettia.<br /><br />Mikäli postinumerosi osuu sekä Matkahuollon että Collicon peruskuljetusreitin ulkopuolelle, otamme sinuun erikseen yhteyttä kotiintoimituksesta.'
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="Asia selvä"
            color="primary"
            disablePadding
            onClick={() => setHelpDialogOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RedeemDelivery
