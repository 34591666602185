import { SupportCard, Product, Feed } from 'types/types'
import { Order, Redeemer, initialState } from 'features/order'
import {
  format,
  parse,
  parseISO,
  addDays,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isBefore,
  isAfter
} from 'date-fns'

/**
 * Creates feed from redeem event
 * @returns object suitable for FeedCard component
 */
export const createRedeemFeed = (redeemer: Redeemer): Feed => {
  return {
    avatarBackgroundColor: '#FFCA0F',
    user: 'Venner',
    date: redeemer.date,
    time: redeemer.time,
    subtitle: 'Lahjakortin lunastus',
    text: `<b>${redeemer.firstName}</b> lunasti lahjakortin <b>${format(
      parseISO(redeemer.date),
      'dd.MM.yyyy'
    )} klo ${redeemer.time}</b>.`
  }
}

/**
 * Loops through service engagements and return type Order[] array to be saved on state
 * @param serviceEngagements
 * @returns array of orders and variants for utils-state
 */
export const sortServiceEngagementsData = (
  serviceEngagements: any
): {
  orders: Order[]
  loggedInName: string
  firstName: string
  lastName: string
  email: string
  phone: string
  company: string
  businessId: string
  address: string
  zip: string
  city: string
  ovtId: string
  operatorId: string
} => {
  const serviceEngagementsLength = serviceEngagements.length
  const orders: Order[] = []
  // name to show on Status-home
  let loggedInName = ''
  // these are for the feedback and details for new order to fill contact fields
  let firstName = ''
  let lastName = ''
  let email = ''
  let phone = ''
  let company = ''
  let businessId = ''
  let address = ''
  let zip = ''
  let city = ''
  let ovtId = ''
  let operatorId = ''

  // loop service engagements
  serviceEngagements.forEach((v: any, index: number) => {
    // ignore Status = 'Rejected' orders
    if (v.stage.name !== 'Rejected') {
      // set orders (metadata)
      let order: Order = JSON.parse(JSON.stringify(initialState))
      let uniqoreFriendlyId: string = ''
      let date: string = ''
      let feedback: 0 | 1 | 2 | 3 | 4 = 0
      v.metadata.forEach((metadata: any) => {
        if (metadata.key === 'order') {
          order = JSON.parse(JSON.stringify(metadata.value))

          // set the name of the logged in user and user details based on the first service engagement metadata
          if (index === serviceEngagementsLength - 1) {
            loggedInName = metadata.value.personContact.firstName
            firstName = metadata.value.personContact.firstName
            lastName = metadata.value.personContact.lastName
            email = metadata.value.personContact.email
            phone = metadata.value.personContact.phone
            company = metadata.value.companyContact.companyName
            businessId = metadata.value.companyContact.businessId
            address = metadata.value.companyContact.address
            zip = metadata.value.companyContact.zip
            city = metadata.value.companyContact.city
            ovtId = metadata.value.billing.ovtId
            operatorId = metadata.value.billing.operatorId
          }
        }
        if (metadata.key === 'idNumber') {
          uniqoreFriendlyId = String(metadata.value)
        }
        if (metadata.key === 'redeemExpirationDate') {
          const year: number = Number(metadata.value.split('-')[0])
          const month: number = Number(metadata.value.split('-')[1]) - 1
          const day: number = Number(metadata.value.split('-')[2])
          date = format(new Date(year, month, day), 'dd.MM.yyyy')
        }
        if (metadata.key === 'feedback') {
          feedback = metadata.value.rating
        }
      }) // metadata forEach ends
      order.engagementId = v.id
      order.uniqoreFriendlyId = uniqoreFriendlyId
      order.redeemExpirationDate = date
      order.status = v.stage.name
      order.feedback = feedback

      // set events
      const redeemers: Redeemer[] = []
      v.events.forEach((event: any) => {
        const redeemer: Redeemer = {
          firstName: '',
          lastName: '',
          email: '',
          date: '',
          time: ''
        }
        if (event.key === 'redeemedGiftcard') {
          event.metadata.forEach((metadata: any) => {
            if (metadata.key === 'form') {
              redeemer.firstName = metadata.value.contactDetails.firstName
              redeemer.lastName = metadata.value.contactDetails.lastName
              redeemer.email = metadata.value.contactDetails.email
              redeemer.date = format(
                parseISO(metadata.value.codeRedeemed),
                'yyyy-MM-dd'
              )
              redeemer.time = new Date(
                metadata.value.codeRedeemed
              ).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })
            }
          })
        }
        redeemers.push(redeemer)
      }) // events forEach ends
      order.redeemers = redeemers

      orders.push(order)
    } // end Status = 'Rejected' order
  })

  return {
    orders,
    loggedInName,
    firstName,
    lastName,
    email,
    phone,
    company,
    businessId,
    address,
    zip,
    city,
    ovtId,
    operatorId
  }
}

export const getDaysBetweenDates = (date1: string, date2: string) => {
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24
  const date1Milliseconds = Date.parse(date1)
  const date2Milliseconds = Date.parse(date2)

  const differenceInMilliseconds = Math.abs(
    date1Milliseconds - date2Milliseconds
  )

  return Math.round(differenceInMilliseconds / oneDayInMilliseconds)
}

export const defaultDaysAheadBuffer = 16
/**
 * Returns delivery dates.
 * @param weekDay What week day is the delivery
 * @param daysAheadBuffer How many days from today is the first delivery day
 * @param selectableWeeksCount How many delivery days are returned
 * @param homeDeliveryTime Adds time to delivery date
 * @param dynamicProduct Dynamic product
 */
export const getDeliveryDates = (
  weekDay: 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'TBD' | string,
  daysAheadBuffer: number = defaultDaysAheadBuffer,
  selectableWeeksCount: number = 4,
  homeDeliveryTime?: string,
  dynamicProduct?: Product
): string[] => {
  const selectedDates = [] // dates that are returned
  let buffer = daysAheadBuffer // buffer increases by one in every loop
  const deliveryFromDate = dynamicProduct?.deliveryValidFrom
  const deliveryToDate = dynamicProduct?.deliveryValidTo

  if (deliveryFromDate) {
    // if today + daysAheadBuffer is before than delivery valid from date:
    // set buffer to 0
    const result = isBefore(
      addDays(new Date(), daysAheadBuffer),
      new Date(deliveryFromDate)
    )
    if (result) {
      buffer = getDaysBetweenDates(new Date().toString(), deliveryFromDate)
    }
  }

  while (selectedDates.length < selectableWeeksCount) {
    if (
      weekDay === 'Mon' ||
      weekDay === 'Tue' ||
      weekDay === 'Wed' ||
      weekDay === 'Thu' ||
      weekDay === 'Fri'
    ) {
      let date = addDays(new Date(), buffer)
      // If looped 'date' is after the 'deliveryValidTo' -date:
      // break out from the loop
      if (
        deliveryToDate &&
        isAfter(
          // parse & format looped 'date' to match time
          parse(format(date, 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date()),
          // parse & format 'deliveryToDate' to match time
          parse(
            format(new Date(deliveryToDate), 'yyyy-MM-dd'),
            'yyyy-MM-dd',
            new Date()
          )
        )
      ) {
        break
      }
      switch (weekDay) {
        case 'Mon':
          if (isMonday(date)) {
            if (homeDeliveryTime) {
              selectedDates.push(
                `${format(date, 'dd.MM.yyyy')} klo ${homeDeliveryTime}`
              )
            } else {
              selectedDates.push(format(date, 'dd.MM.yyyy'))
            }
          }
          break
        case 'Tue':
          if (isTuesday(date)) {
            if (homeDeliveryTime) {
              selectedDates.push(
                `${format(date, 'dd.MM.yyyy')} klo ${homeDeliveryTime}`
              )
            } else {
              selectedDates.push(format(date, 'dd.MM.yyyy'))
            }
          }
          break
        case 'Wed':
          if (isWednesday(date)) {
            if (homeDeliveryTime) {
              selectedDates.push(
                `${format(date, 'dd.MM.yyyy')} klo ${homeDeliveryTime}`
              )
            } else {
              selectedDates.push(format(date, 'dd.MM.yyyy'))
            }
          }
          break
        case 'Thu':
          if (isThursday(date)) {
            if (homeDeliveryTime) {
              selectedDates.push(
                `${format(date, 'dd.MM.yyyy')} klo ${homeDeliveryTime}`
              )
            } else {
              selectedDates.push(format(date, 'dd.MM.yyyy'))
            }
          }
          break
        case 'Fri':
          if (isFriday(date)) {
            if (homeDeliveryTime) {
              selectedDates.push(
                `${format(date, 'dd.MM.yyyy')} klo ${homeDeliveryTime}`
              )
            } else {
              selectedDates.push(format(date, 'dd.MM.yyyy'))
            }
          }
          break
        default:
          break
      }
      buffer++
    } else {
      break
    }
  }
  return selectedDates
}

/**
 * Format date from yyyy-MM-dd to dd.MM.yyyy
 */
export const formatDate = (date: string) => {
  const year: number = Number(date.split('-')[0])
  const month: number = Number(date.split('-')[1]) - 1
  const day: number = Number(date.split('-')[2])
  return format(new Date(year, month, day), 'dd.MM.yyyy')
}

/**
 * @returns Returns current date in yyyy-mm-dd format
 */
export const getDate = () => {
  const today = new Date()
  let dd = String(today.getDate())
  let mm = String(today.getMonth() + 1)
  const yyyy = today.getFullYear()
  if (Number(dd) < 10) {
    dd = `0${dd}`
  }
  if (Number(mm) < 10) {
    mm = `0${mm}`
  }
  return `${yyyy}-${mm}-${dd}`
}

/**
 * @returns Returns current time in hh:mm format
 */
export const getTime = () => {
  return new Date().toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
}

const parseDateAndTime = (date: string, time: string) => {
  const year = date.substring(0, 4)
  const month = date.substring(5, 7)
  const day = date.substring(8, 10)
  const hours = time.substring(0, 2)
  const minutes = time.substring(3, 5)
  const parsedDate = year + month + day
  const parsedTime = hours + minutes
  const result = parsedDate + parsedTime
  return Number(result)
}

export const sortFeedByDateAndTime = (feed: Feed[]) => {
  const sortedFeed = JSON.parse(JSON.stringify(feed))
  sortedFeed.forEach((f: Feed) => {
    if (f.date && f.time) {
      f.dateNumber = parseDateAndTime(f.date, f.time)
    }
  })
  sortedFeed.sort((a: { dateNumber: number }, b: { dateNumber: number }) => {
    if (a.dateNumber < b.dateNumber) return 1
    if (a.dateNumber > b.dateNumber) return -1
    return 0
  })
  return sortedFeed
}

/**
 * Creates order feed
 * @param order : Order you want create feed from;
 * @returns object suitable for FeedCard component
 */
export const createOrderFeed = (order: Order): Feed => {
  const { orderType, products, orderDate, orderTime, isDynamicProduct } = order
  let text = ''

  // Sesonkiruokalaatikko
  if (!isDynamicProduct) {
    let foodBoxAmount = 0
    let supportBoxAmount = 0

    if (products) {
      products.forEach((product: Product) => {
        if (product.type === 'Charity') {
          supportBoxAmount = product.quantity
        } else {
          foodBoxAmount += product.quantity
        }
      })
    }

    if (supportBoxAmount === 0 && foodBoxAmount > 0) {
      if (orderType === 'Giftcard') {
        text = `Tilasit <b>${foodBoxAmount} kpl yksittäin kotiin toimitettavia Sesonkiruokalaatikoita</b> ja mahdollisit tämän avulla <b>${Math.round(
          foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      } else {
        text = `Tilasit <b>${foodBoxAmount} kpl yrityksen tiloihin toimitettavia Sesonkiruokalaatikoita</b> ja mahdollisit tämän avulla <b>${Math.round(
          foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
    }
    if (foodBoxAmount === 0 && supportBoxAmount > 0) {
      text = `Tilasit <b>${supportBoxAmount} kpl Venner-ruokalaatikoita</b> ja mahdollisit tämän avulla <b>${Math.round(
        supportBoxAmount * 42
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
    if (foodBoxAmount > 0 && supportBoxAmount > 0) {
      if (orderType === 'Giftcard') {
        text = `Tilasit <b>${foodBoxAmount} kpl yksittäin kotiin toimitettavia Sesonkiruokalaatikoita</b> ja <b>${supportBoxAmount} kpl Venner-ruokalaatikoita</b>. Mahdollisit tämän avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      } else {
        text = `Tilasit <b>${foodBoxAmount} kpl yrityksen tiloihin toimitettavia Sesonkiruokalaatikoita</b> ja <b>${supportBoxAmount} kpl Venner-ruokalaatikoita</b>. Mahdollisit tämän avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
    }
  }
  // Dynamic Product
  else {
    if (products[0].type === 'Cargo') {
      text = `Tilasit <b>${products[0].quantity} kpl</b> tuotetta <b>${
        products[0].text
      }</b> yrityksen toimitiloihin ja mahdollistit tämä avulla <b>${Math.round(
        (products[0].quantity * products[0].support) / 2
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
    if (products[0].type === 'Home') {
      text = `Tilasit <b>${
        products[0].quantity
      } kpl</b> yksittäin kotiin toimitettavaa tuotetta <b>${
        products[0].text
      }</b> ja mahdollistit tämä avulla <b>${Math.round(
        (products[0].quantity * products[0].support) / 2
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
    if (products[0].type === 'Pickup') {
      text = `Tilasit <b>${
        products[0].quantity
      } kpl</b> yksittäin noutopisteeseen toimitettavaa tuotetta <b>${
        products[0].text
      }</b> ja mahdollistit tämä avulla <b>${Math.round(
        (products[0].quantity * products[0].support) / 2
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
  }

  return {
    avatarBackgroundColor: '#BAE4ED',
    user: 'Sinä',
    date: orderDate,
    time: orderTime,
    subtitle: 'Uusi tilaus',
    text
  }
}

/**
 * Creates feed text from how many and what products are currently ordered
 * @param foodBoxAmount
 * @param supportBoxAmount
 * @param supportCard
 * @returns feed text
 */
export const setSupportFeedText = (
  foodBoxAmount: number,
  supportBoxAmount: number,
  supportCard: SupportCard
) => {
  let model = ''
  if (foodBoxAmount === 0 && supportBoxAmount > 0) {
    if (!supportCard.showQuantity) {
      model = `Tilasimme <b>Venner-ruokalaatikoita</b> ja mahdollistimme ravitsevia ruoka-annoksia apua tarvitseville perheille.`
    } else if (supportBoxAmount === 1) {
      model = `Tilasimme <b>Venner-ruokalaatikon</b> ja mahdollistimme <b>${Math.round(
        supportBoxAmount * 42
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    } else {
      model = `Tilasimme <b>${supportBoxAmount} Venner-ruokalaatikkoa</b> ja mahdollistimme <b>${Math.round(
        supportBoxAmount * 42
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
  }
  if (foodBoxAmount > 0 && supportBoxAmount === 0) {
    if (!supportCard.showQuantity) {
      model = `Tilasimme <b>Venner-sesonkiruokalaatikoita</b> ja mahdollistimme ravitsevia ruoka-annoksia apua tarvitseville perheille.`
    } else if (foodBoxAmount === 1) {
      model = `Tilasimme <b>Venner-sesonkiruokalaatikon</b> ja mahdollistimme <b>${Math.round(
        foodBoxAmount * 3
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    } else {
      model = `Tilasimme <b>${foodBoxAmount} Venner-sesonkiruokalaatikkoa</b> ja mahdollistimme <b>${Math.round(
        foodBoxAmount * 3
      )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
    }
  }
  if (foodBoxAmount > 0 && supportBoxAmount > 0) {
    if (!supportCard.showQuantity) {
      model = `Tilasimme <b>Venner-sesonkiruokalaatikoita</b> ja <b>Venner-ruokalaatikoita.</b> Mahdollistimme näiden avulla ravitsevia ruoka-annoksia apua tarvitseville perheille.`
    } else {
      if (foodBoxAmount === 1 && supportBoxAmount > 1) {
        model = `Tilasimme <b>Venner-sesonkiruokalaatikon</b> ja <b>${supportBoxAmount} Venner-ruokalaatikkoa.</b> Mahdollistimme näiden avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
      if (foodBoxAmount > 1 && supportBoxAmount === 1) {
        model = `Tilasimme <b>${foodBoxAmount} Venner-sesonkiruokalaatikkoa</b> ja <b>Venner-ruokalaatikon.</b> Mahdollistimme näiden avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
      if (foodBoxAmount === 1 && supportBoxAmount === 1) {
        model = `Tilasimme <b>Venner-sesonkiruokalaatikon</b> ja <b>Venner-ruokalaatikon.</b> Mahdollistimme näiden avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
      if (foodBoxAmount > 1 && supportBoxAmount > 1) {
        model = `Tilasimme <b>${foodBoxAmount} Venner-sesonkiruokalaatikkoa</b> ja <b>${supportBoxAmount} Venner-ruokalaatikkoa.</b> Mahdollistimme näiden avulla <b>${Math.round(
          supportBoxAmount * 42 + foodBoxAmount * 3
        )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
      }
    }
  }
  return model
}

/**
 * Creates feed text from how many dynamic products are currently ordered
 * @param product
 * @param supportCard
 * @returns feed text
 */
export const setSupportFeedTextDynamicProduct = (
  product: Product,
  supportCard: SupportCard
) => {
  let model = ''
  if (supportCard.showQuantity) {
    model = `Tilasimme <b>${product.quantity} kpl</b> tuotetta <b>${
      product.text
    }</b> ja mahdollistimme <b>${Math.round(
      (product.quantity * product.support) / 2
    )}</b> ravitsevaa ruoka-annosta apua tarvitseville perheille.`
  } else {
    model = `Tilasimme tuotetta <b>${product.text}</b> ja mahdollistimme ravitsevia ruoka-annoksia apua tarvitseville perheille.`
  }
  return model
}

export const round = (value: number) => {
  return Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR'
  }).format(value)
}
