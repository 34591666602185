import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  BottomBar,
  Button,
  Container,
  Heading
} from 'uniqore-components'

const RedeemCodeAlreadyUsed: React.FC = () => {
  const history = useHistory()

  const goBack = () => {
    history.push('/campaign-redeem-code')
  }

  return (
    <Container>
      <AppBar
        text="This code has been redeemed"
        maxWidth="sm"
        mainAction={{
          icon: 'ArrowBack',
          onClick: () => goBack()
        }}
        actions={[
          {
            icon: 'HelpOutline'
          }
        ]}
      />
      <Heading align="center" body="Snackbar." />
      <BottomBar
        style={{ position: 'absolute', bottom: 30, width: '100%' }}
        maxWidth="sm"
        components={[
          <Button
            variant="default"
            disablePadding
            fullWidth
            label="Previous"
            onClick={goBack}
          />,
          <Button
            color="primary"
            disablePadding
            fullWidth
            label="Forward"
            type="submit"
          />
        ]}
      />
    </Container>
  )
}

export default RedeemCodeAlreadyUsed
