import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Container } from 'uniqore-components'

const useStyles = makeStyles(() => ({
  linearProgress: {
    position: 'absolute',
    top: '52px',
    zIndex: 100,
    width: '100%',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  progressSpeed: {
    transition: 'transform 0.2s linear'
  }
}))

const ProgressLine: React.FC<{
  progress: number
  progressLength: number
}> = ({ progress, progressLength }) => {
  const classes = useStyles()
  const [currentProgress, setCurrentProgress] = useState(0)

  useEffect(() => {
    if (progress && progressLength) {
      setCurrentProgress((progress / progressLength) * 100)
    }
  }, [progress, progressLength])

  const checkVisibility = () => {
    if (progress > 0) {
      return ''
    }
    return 'none'
  }

  return (
    <Container
      style={{ display: checkVisibility() }}
      className={classes.linearProgress}
    >
      <LinearProgress
        classes={{
          bar1Determinate: classes.progressSpeed
        }}
        color="secondary"
        variant="determinate"
        value={currentProgress}
      />
    </Container>
  )
}

export default ProgressLine
