import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import { Heading, ListItem } from 'uniqore-components'
import { Product, DynamicDeliveryTypes } from 'types/types'
import { changeOrder } from 'features/order'
import IconButton from '@material-ui/core/IconButton'
import { ExpandMore } from '@material-ui/icons'
import { round } from 'utils/common'
import { AppState } from 'store'

const useStyles = makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 44, 56, 0.12)',
    boxSizing: 'border-box',
    backgroundColor: '#FAFAFA'
  },
  iconButton: {
    marginRight: 0,
    '&:hover': {
      backgroundColor: '#FAFAFA'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  divider: {
    marginLeft: '16px',
    marginRight: '16px',
    height: '1px',
    border: 'none',
    background: 'rgba(0, 44, 56, 0.08)'
  }
}))

const Cart: React.FC<{ items: Product[] }> = ({ items }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { shippingGiftcard, shippingCargo } = useSelector(
    (state: AppState) => state.airtableData
  )
  const { orderType, isDynamicProduct } = useSelector(
    (state: AppState) => state.order
  )
  const [expanded, setExpanded] = useState<boolean>(false)
  const [shippingNoTaxes, setShippingNoTaxes] = useState<number>(0)
  const [shippingTaxes, setShippingTaxes] = useState<number>(0)
  const [productTaxes, setProductTaxes] = useState<number>(0)
  const [totalNoTaxes, setTotalNoTaxes] = useState<number>(0)
  const [taxesTotal, setTaxesTotal] = useState<number>(0)
  const [totalWithTaxes, setTotalWithTaxes] = useState<number>(0)
  const [totalAid, setTotalAid] = useState<number>(0)

  // calculate total, no taxes = product prices and shipping without taxes
  useEffect(() => {
    const productPrices = items.map((item) => item.price * item.quantity)

    let shippingAmount = 0

    // Dynamic Product
    if (isDynamicProduct && items.length > 0 && items[0].deliveryProducts) {
      shippingAmount =
        items[0].quantity *
        items[0].deliveryProducts[items[0].type as DynamicDeliveryTypes].price
    }
    // Sesonkiruokalaatikko
    else {
      items.forEach((item) => {
        if (item.type === 'Cargo') {
          shippingAmount = item.quantity * shippingCargo.price
        }
        if (item.type === 'Giftcard') {
          shippingAmount = item.quantity * shippingGiftcard.price
        }
      })
    }
    setShippingNoTaxes(shippingAmount)

    const productsTotalNoTaxes = productPrices.reduce((a, b) => a + b, 0)
    setTotalNoTaxes(productsTotalNoTaxes + shippingAmount)
  }, [items, shippingGiftcard.price, shippingCargo.price, isDynamicProduct])

  // calculate tax amount
  useEffect(() => {
    const productTaxes = items.map((item) => {
      const priceAmount = item.price * item.quantity
      return priceAmount > 0
        ? priceAmount * (item.taxPercent / 100 + 1) - item.price * item.quantity
        : 0
    })
    const taxesProducts = productTaxes.reduce((a, b) => a + b, 0)
    setProductTaxes(taxesProducts)

    // Find delivery info for dynamic product
    let delivery = undefined
    if (isDynamicProduct && items.length > 0 && items[0].deliveryProducts) {
      delivery =
        items[0].deliveryProducts[items[0].type as DynamicDeliveryTypes]
    }

    let taxesShipping = 0
    if (shippingNoTaxes > 0) {
      if (orderType === 'Cargo') {
        if (isDynamicProduct && delivery) {
          taxesShipping =
            shippingNoTaxes * (delivery.taxPercent / 100 + 1) - shippingNoTaxes
        }
        if (!isDynamicProduct) {
          taxesShipping =
            shippingNoTaxes * (shippingCargo.taxPercent / 100 + 1) -
            shippingNoTaxes
        }
      }
      if (orderType === 'Giftcard') {
        if (isDynamicProduct && delivery) {
          taxesShipping =
            shippingNoTaxes * (delivery.taxPercent / 100 + 1) - shippingNoTaxes
        }
        if (!isDynamicProduct) {
          taxesShipping =
            shippingNoTaxes * (shippingGiftcard.taxPercent / 100 + 1) -
            shippingNoTaxes
        }
      }
    }
    setShippingTaxes(taxesShipping)

    setTaxesTotal(taxesProducts + taxesShipping)
  }, [
    items,
    orderType,
    shippingNoTaxes,
    shippingGiftcard.taxPercent,
    shippingCargo.taxPercent,
    isDynamicProduct
  ])

  // calculate total, including taxes
  useEffect(() => {
    setTotalWithTaxes(totalNoTaxes + taxesTotal)
  }, [totalNoTaxes, taxesTotal])

  // calculate aid
  useEffect(() => {
    const productsAid = items.map((item) => item.support * item.quantity)
    setTotalAid(productsAid.reduce((a, b) => a + b, 0))
  }, [items])

  // update state
  useEffect(() => {
    dispatch(
      changeOrder({
        value: totalNoTaxes,
        stateKey: 'cartTotals',
        stateKeyExtra: 'totalNoTaxes'
      })
    )
    dispatch(
      changeOrder({
        value: productTaxes,
        stateKey: 'cartTotals',
        stateKeyExtra: 'productTaxes'
      })
    )
    dispatch(
      changeOrder({
        value: shippingTaxes,
        stateKey: 'cartTotals',
        stateKeyExtra: 'shippingTaxes'
      })
    )
    dispatch(
      changeOrder({
        value: totalWithTaxes,
        stateKey: 'cartTotals',
        stateKeyExtra: 'totalWithTaxes'
      })
    )
    dispatch(
      changeOrder({
        value: totalAid,
        stateKey: 'cartTotals',
        stateKeyExtra: 'totalAid'
      })
    )
  }, [
    dispatch,
    totalNoTaxes,
    productTaxes,
    shippingTaxes,
    totalWithTaxes,
    totalAid
  ])

  return (
    <div className={classes.card} onClick={() => setExpanded(!expanded)}>
      <div style={{ display: 'flex' }}>
        <Heading title="Kustannus- ja tukilaskelma" />
        <IconButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          classes={{ root: classes.iconButton }}
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </IconButton>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {items.map((item, i) => (
          <Collapse key={i} in={item.quantity > 0} timeout="auto" unmountOnExit>
            <ListItem
              dense
              text={`${item.text} x ${item.quantity}`}
              metaText={round(item.price * item.quantity)}
            />
            {item.type !== 'Charity' && (
              <ListItem
                dense
                subtitles={[
                  `Toimitus${
                    item.type === 'Giftcard' ? ` x ${item.quantity}` : ''
                  }`
                ]}
                metaText={round(shippingNoTaxes)}
              />
            )}
          </Collapse>
        ))}
        <hr className={classes.divider} />
        <ListItem
          dense
          text="Välisumma (alv 0%)"
          metaText={round(totalNoTaxes)}
        />
        <ListItem dense text="Verot yhteensä" metaText={round(taxesTotal)} />
        <Collapse in={productTaxes > 0} timeout="auto" unmountOnExit>
          <ListItem
            dense
            subtitles={['Tuotteet (alv 14%)']}
            metaText={round(productTaxes)}
          />
        </Collapse>
        <Collapse in={shippingTaxes > 0} timeout="auto" unmountOnExit>
          <ListItem
            dense
            subtitles={['Toimitus (alv 25,5%)']}
            metaText={round(shippingTaxes)}
          />
        </Collapse>
        <hr className={classes.divider} />
      </Collapse>
      <ListItem
        style={{ backgroundColor: expanded ? 'rgba(255,202,15, 0.2)' : '' }}
        dense
        text="Yhteensä (sis. alv)"
        metaText={round(totalWithTaxes)}
      />
      <ListItem
        style={{ backgroundColor: expanded ? 'rgba(255,202,15, 0.2)' : '' }}
        dense
        text="Tuen osuus"
        metaText={round(totalAid)}
      />
    </div>
  )
}

export default Cart
