import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { Heading, Login, TextField, Button } from 'uniqore-components'
import { useHistory } from 'react-router-dom'
import { validateEmail } from 'api/cloudFunctions'

const useStyles = makeStyles(() => ({
  contentArea: {
    '@media (max-height: 700px)': {
      overflow: 'auto',
      marginBottom: '33px'
    },
    '@media (min-height: 700px)': {
      height: 'calc(100vh - 56px)'
    }
  }
}))

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset(
    $email: EmailAddress!
    $type: LoginType
    $organization: ID
    $lang: String
  ) {
    requestPasswordReset(
      email: $email
      type: $type
      organization: $organization
      lang: $lang
    ) {
      error
    }
  }
`

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onCompleted(result) {
        if (result.requestPasswordReset.error) {
          setError(true)
        } else {
          history.push('/venner-login')
        }
      },
      onError(error) {
        if (error) {
          setError(true)
        }
      }
    }
  )

  const validate = () => {
    let valid = true
    if (!validateEmail(email)) {
      setEmailValid('Tarkista antamasi sähköpostiosoite')
      valid = false
    } else {
      setEmailValid('')
    }
    return valid
  }

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      requestPasswordReset({
        variables: {
          email: email.trim(),
          type: 'ORGUSER',
          organization: process.env.REACT_APP_ORGANIZATION_ID,
          lang: 'fi'
        }
      })
    }
  }

  return (
    <div className={classes.contentArea}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Login
          maxWidth="sm"
          headline="Palauta salasana"
          subtitle=" " /* Replace the default subtitle string */
          contentComponents={[
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errorText={emailValid}
              fullWidth
              placeholder="Sähköpostiosoitteesi"
              name="email"
              autoComplete="email"
              prefix={{
                icon: 'MailOutline'
              }}
            />
          ]}
          actionComponents={[
            <Button
              color="primary"
              label="Palauta salasana"
              fullWidth
              disabled={loading}
              type="submit"
              onClick={submit}
            />,
            <Button
              variant="default"
              color="primary"
              label="Takaisin"
              fullWidth
              onClick={() => history.push('/venner-login')}
              type="submit"
            />
          ]}
        />
        {error && <Heading body="Jokin meni pieleen. Yritä uudelleen." />}
      </div>
    </div>
  )
}

export default ResetPassword
