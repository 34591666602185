import axios from 'axios'

export interface BulkEmailData {
  emails: string
  engagementId: string
  userToken: string
}

export interface BulkEmailResponse {
  message: string
  emailCount: number
}

export interface GiftCodeData {
  email: string
  engagementId: string
  giftCode: string
}

export interface GiftCodeResponse {
  isOk: boolean
  message: string
}

export const createBulkOrders = (data: BulkEmailData) => {
  return axios.post<BulkEmailResponse>(
    process.env.REACT_APP_CLOUD_BULK_ORDER_URL as string,
    data
  )
}

export const checkBulkOrders = (data: GiftCodeData) => {
  return axios.post<GiftCodeResponse>(
    process.env.REACT_APP_CLOUD_CHECK_ORDER_URL as string,
    data
  )
}

export const redeemBulkOrders = (data: GiftCodeData) => {
  return axios.post<GiftCodeResponse>(
    process.env.REACT_APP_CLOUD_REDEEM_ORDER_URL as string,
    data
  )
}

/** Heavy but accurate email validation */
export function validateEmail(email: string): boolean {
  /* eslint-disable no-useless-escape */
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return res.test(email.toLowerCase())
}

/**
 * Parse string containing all the emails and return list of separated strings.
 * Strings are separated with regex /[^\s,;]+/g so the separation can be
 * any combination of whitespace ',' and ';'
 */
export function parseEmailString(emailData: string): string[] {
  if (!emailData) {
    throw new Error('Kenttä ei voi olla tyhjä.')
  }

  const emails = emailData.match(/[^\s,;]+/g)

  if (!emails || emails.length === 0) {
    throw new Error('Sähköposteja ei löydetty')
  }

  for (const email of emails) {
    if (!validateEmail(email)) {
      throw new Error(`Vääränlainen sähköposti: '${email}'`)
    }
  }

  return emails
}
