import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  Heading,
  ListItem,
  SmallPrint
} from 'uniqore-components'
import SpinnerMui from 'components/Common/SpinnerMui'
import {
  changeRedeem,
  Redeem,
  RedeemContactDetails,
  RedeemDeliveryDetails
} from 'features/redeem'
import { changeUtils } from 'features/utils'
import { AppState } from 'store'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { setSeasonBoxTexts } from 'components/Order/OrderBoxType'

const formQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

const formResultQuery = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      result
    }
  }
`

const useStyles = makeStyles(() => ({
  text: {
    '& a': {
      color: 'inherit',
      textDecoration: 'underline'
    }
  }
}))

// Collico API does not like white spaces, remove them
const trimContactDetails = (contactDetails: RedeemContactDetails) => {
  const fixFirstName = contactDetails.firstName.trim()
  contactDetails.firstName = fixFirstName
  const fixLastName = contactDetails.lastName.trim()
  contactDetails.lastName = fixLastName
  const fixEmail = contactDetails.email.trim()
  contactDetails.email = fixEmail
  const trimPhoneNumber = contactDetails.phone.trim()
  const removePossibleParenthesesPhone = trimPhoneNumber.replace(/([()])/g, '')
  contactDetails.phone = removePossibleParenthesesPhone
}
const trimDeliveryDetails = (deliveryDetails: RedeemDeliveryDetails) => {
  const fixRecipient = deliveryDetails.recipient.trim()
  deliveryDetails.recipient = fixRecipient
  const fixAddress = deliveryDetails.address.trim()
  deliveryDetails.address = fixAddress
  const fixZip = deliveryDetails.zip.trim()
  deliveryDetails.zip = fixZip
  const fixCity = deliveryDetails.city.trim()
  deliveryDetails.city = fixCity
  const fixNotes = deliveryDetails.notes.trim()
  deliveryDetails.notes = fixNotes
}

const RedeemSummary: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const redeem = useSelector((state: AppState) => state.redeem)
  const [error, setError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [resultCall, { data, loading: queryLoading, stopPolling }] =
    useLazyQuery(formResultQuery, {
      pollInterval: 500
    })
  const [checkFormCall, { loading: checkLoading, error: mutationError }] =
    useMutation(formQuery, {
      onCompleted(mutationResult) {
        if (mutationResult && !mutationResult.ingestForm.errors) {
          resultCall({ variables: { id: mutationResult.ingestForm.result } })
        } else {
          setError(true)
        }
      },
      onError(mutationError) {
        if (mutationError) {
          setError(true)
        }
      }
    })
  const [formCall, { loading: mutationLoading }] = useMutation(formQuery, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.ingestForm.errors) {
        dispatch(changeRedeem({ value: 0, stateKey: 'currentView' }))
        history.push('/redeem-confirm')
      } else {
        setError(true)
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setError(true)
      }
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
    dispatch(changeUtils({ value: false, stateKey: 'fromSummary' }))
    if (!redeem.code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFormResult = (
    isCodeCorrect: boolean,
    isQuotaLeft: boolean,
    isStatusOpened: boolean,
    isEmailRedeemed: boolean
  ) => {
    stopPolling()
    if (isCodeCorrect && isQuotaLeft && isStatusOpened && !isEmailRedeemed) {
      const sendRedeem: Redeem = JSON.parse(JSON.stringify(redeem))
      sendRedeem.codeRedeemed = new Date()
      const deleteKeys = ['currentView', 'isDynamicProduct', 'dynamicProduct']
      //@ts-ignore
      deleteKeys.forEach((v) => delete sendRedeem[v])

      // Collico API does not like white spaces, remove them
      trimContactDetails(sendRedeem.contactDetails)
      trimDeliveryDetails(sendRedeem.deliveryDetails)

      // Create a unique redeem id, this is used when updating Airtable's Redeemers-table rows
      sendRedeem.redeemId = uuid()

      formCall({
        variables: {
          id: process.env.REACT_APP_SEND_REDEEM_KEY,
          form: sendRedeem
        }
      })
    } else {
      setErrorText('Lahjakoodi on jo käytetty')
    }
  }

  // check quota, email and code before sending redeem
  useEffect(() => {
    if (data) {
      if (
        data.formResult.result.hasOwnProperty('isCodeCorrect') &&
        data.formResult.result.hasOwnProperty('isQuotaLeft') &&
        data.formResult.result.hasOwnProperty('isStatusOpened') &&
        data.formResult.result.hasOwnProperty('isEmailRedeemed')
      ) {
        handleFormResult(
          data.formResult.result.isCodeCorrect,
          data.formResult.result.isQuotaLeft,
          data.formResult.result.isStatusOpened,
          data.formResult.result.isEmailRedeemed
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const goBack = () => {
    dispatch(changeRedeem({ value: 4, stateKey: 'currentView' }))
    history.push('/redeem-date')
  }

  const goToView = (route: string, view: number) => {
    dispatch(changeUtils({ value: true, stateKey: 'fromSummary' }))
    dispatch(changeRedeem({ value: view, stateKey: 'currentView' }))
    history.push(route)
  }

  const submit = () => {
    setError(false)

    checkFormCall({
      variables: {
        id: process.env.REACT_APP_CHECK_REDEEM_ID,
        form: {
          engagementId: redeem.engagementId,
          code: redeem.code,
          email: redeem.contactDetails.email
        }
      }
    })
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text="Toimituspäivä"
      />
      <div style={{ marginBottom: error || errorText ? 250 : 200 }}>
        <Heading
          title="Yhteenveto"
          body={
            redeem.isDynamicProduct
              ? `Yhteenveto antamista tiedoistasi ja tuotteen ${redeem.dynamicProduct?.text} toimituksesta.`
              : 'Yhteenveto antamista tiedoistasi ja Sesonkiruokalaatikon toimituksesta.'
          }
        />
        <ListItem
          text="Lahjakoodi"
          subtitles={
            redeem.isDynamicProduct && redeem.dynamicProduct
              ? [
                  `${redeem.dynamicProduct.text} ${
                    redeem.dynamicProduct.type === 'Home'
                      ? 'kotiin toimitettuna'
                      : 'lähimpään noutopisteeseen toimitettuna'
                  }`
                ]
              : ['Venner Sesonkiruokalaatikko kotiin toimitettuna']
          }
        />
        <ListItem
          text={redeem.isDynamicProduct ? 'Tuote' : 'Sesonkiruokalaatikko'}
          subtitles={
            redeem.isDynamicProduct
              ? [redeem.dynamicProduct?.text as string]
              : [setSeasonBoxTexts(redeem.boxType)]
          }
          onClick={() => goToView('/redeem-product', 1)}
          metaIcon="Edit"
        />
        <ListItem
          text="Yhteystiedot"
          subtitles={[
            `${redeem.contactDetails.firstName} ${redeem.contactDetails.lastName}`,
            redeem.contactDetails.email,
            redeem.contactDetails.phone,
            redeem.contactDetails.mailingList ? 'Liityn postituslistalle' : ''
          ]}
          onClick={() => goToView('/redeem-contact', 2)}
          metaIcon="Edit"
        />
        <ListItem
          text="Toimitusosoite"
          subtitles={[
            redeem.deliveryDetails.recipient,
            redeem.deliveryDetails.address,
            `${redeem.deliveryDetails.zip} ${redeem.deliveryDetails.city}`,
            redeem.deliveryDetails.notes
              ? `Lisätiedot: ${redeem.deliveryDetails.notes}`
              : ''
          ]}
          onClick={() => goToView('/redeem-delivery', 3)}
          metaIcon="Edit"
        />
        <ListItem
          text="Toimitusaika"
          subtitles={[redeem.deliveryDate]}
          onClick={() => goToView('/redeem-date', 4)}
          metaIcon="Edit"
        />
      </div>
      <Container style={{ position: 'absolute', bottom: 40, width: '100%' }}>
        <SmallPrint
          className={classes.text}
          align="left"
          htmlText={`Lunastuksen yhteydessä hyväksyt Vennerin <a href="https://www.venner.fi/pages/kayttoehdot" target="_blank" rel="noopener noreferrer"> yleiset käyttöehdot</a>. Saat tilauksen jälkeen tilausvahvistuksen sähköpostiisi ja toimituksen lähestyessä lisätietoja toimituksen tarkemmasta ajasta.`}
        />
        {errorText && <Heading body={errorText} />}
        {error || mutationError ? (
          <Heading body="Jokin meni pieleen. Yritä uudelleen." />
        ) : null}
        {checkLoading || mutationLoading || queryLoading ? (
          <SpinnerMui
            size={30}
            style={{
              position: 'absolute',
              bottom: '13px',
              width: '100%',
              textAlign: 'center'
            }}
          />
        ) : null}
        <Button
          maxWidth="sm"
          label="Tilaa"
          color="primary"
          fullWidth
          disabled={checkLoading || mutationLoading || queryLoading}
          onClick={submit}
        />
      </Container>
    </Container>
  )
}

export default RedeemSummary
