import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Onboarding, Button, i18n } from 'uniqore-components'

import VennerLogo from 'assets/venner-logo.svg'
import Receipt from 'assets/receipt.svg'
import Nature from 'assets/nature.svg'

import { changeCampaignRedeem } from 'features/campaignRedeem'
import { AppState } from 'store'
import { useTranslation } from 'react-i18next'

const CampaignRedeem: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { language, redemptionCode } = useSelector(
    (state: AppState) => state.campaignRedeem
  )

  useEffect(() => {
    if (!redemptionCode) {
      history.push('/')
    }
    i18n.changeLanguage(language)
  }, [language, redemptionCode, history])

  const goForward = () => {
    dispatch(changeCampaignRedeem({ value: 1, stateKey: 'currentView' }))
    history.push('/campaign-select-package')
  }

  return (
    <Container>
      <Onboarding
        actionComponents={[
          <Button
            color="primary"
            disablePadding
            fullWidth
            label={t('button-proceed')}
            onClick={goForward}
          />
        ]}
        actionDirection="row"
        screens={[
          {
            image: VennerLogo,
            title: t('onboarding-title'),
            subtitle: t('onboarding-subtitle')
          },
          {
            image: Receipt,
            title: t('onboarding-title2'),
            subtitle: t('onboarding-subtitle2')
          },
          {
            image: Nature,
            title: t('onboarding-title3'),
            subtitle: t('onboarding-subtitle3')
          }
        ]}
      />
    </Container>
  )
}

export default CampaignRedeem
