import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  AppBar,
  BottomNavigation,
  Container,
  Heading,
  ListItem,
  BottomBar,
  Button
} from 'uniqore-components'
import { Order, Redeemer } from 'features/order'
import { Product } from 'types/types'
import { round } from 'utils/common'
import { AppState } from 'store'
import { changeUtils } from 'features/utils'
import { format, parseISO } from 'date-fns'

const SingleOrderView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { shippingGiftcard, shippingCargo } = useSelector(
    (state: AppState) => state.airtableData
  )
  const { orders } = useSelector((state: AppState) => state.orders)
  const [order, setOrder] = useState<Order>()
  const [activeTab, setActiveTab] = useState<'OrderDetailsTab' | 'CodesTab'>(
    'OrderDetailsTab'
  )

  useEffect(() => {
    if (id) {
      const findOrder = orders.find((o: Order) => o.uniqoreFriendlyId === id)
      if (findOrder) {
        setOrder(findOrder)
      } else {
        const findOrderByOrderId = orders.find((o: Order) => o.orderId === id)
        if (findOrderByOrderId) {
          setOrder(findOrderByOrderId)
        } else {
          history.push('/status-orders')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getProductSummaryDetails = (order: Order) => {
    let result: string[] = []
    // Sesonkiruokalaatikko
    if (!order.isDynamicProduct) {
      if (order.products) {
        order.products.forEach((product: Product) => {
          if (product.quantity > 0) {
            result.push(
              product.type === 'Charity'
                ? `${product.quantity} x ${product.text} (á ${product.price} € / kpl)`
                : product.type === 'Cargo'
                ? `${product.quantity} x ${product.text} (á ${
                    product.price + shippingCargo.price
                  } € / kpl)`
                : `${product.quantity} x ${product.text} (á ${
                    product.price + shippingGiftcard.price
                  } € / kpl)`
            )
          }
        })
      }
    }
    // Dynamic Product
    else {
      result.push(
        `${order.products[0].quantity} x ${order.products[0].text} (á ${order.products[0].price} € / kpl)`
      )
      if (order.products[0].type === 'Cargo') {
        result.push('Kertatoimitus toimipisteeseen')
      }
      if (order.products[0].type === 'Home') {
        result.push('Kotiintoimitus yksittäin')
        result.push('Lunastaminen lahjakortilla')
      }
      if (order.products[0].type === 'Pickup') {
        result.push('Toimitus noutopisteeseen yksittäin')
        result.push('Lunastaminen lahjakortilla')
      }
    }

    return result
  }

  const getSupportText = (order: Order) => {
    let result: string[] = []
    if (!order.supportCard) {
      return ['-']
    }
    const { showCompanyName, showQuantity } = order.supportCard
    if (showCompanyName && showQuantity) {
      result.push('Yrityksen nimi, tilausmäärä')
    }
    if (!showCompanyName && showQuantity) {
      result.push('Ei yrityksen nimeä, vain tilausmäärä')
    }
    if (showCompanyName && !showQuantity) {
      result.push('Yrityksen nimi, ei tilausmäärää')
    }
    if (!showCompanyName && !showQuantity) {
      result.push('Ei yrityksen nimeä, ei tilausmäärää')
    }
    return result
  }

  const goToOrderStatusView = () => {
    dispatch(
      changeUtils({ value: order!.orderId, stateKey: 'orderIdForStatusSwipe' })
    )
    history.push('/status-orders')
  }

  const getRedeemersAmount = (): string => {
    let redeemedAmount = 0
    let giftCardAmount = 0
    if (order) {
      order.products.forEach((product: Product) => {
        if (
          product.type === 'Giftcard' ||
          product.type === 'Home' ||
          product.type === 'Pickup'
        ) {
          giftCardAmount += product.quantity
        }
      })
      if (Object.prototype.hasOwnProperty.call(order, 'redeemers')) {
        redeemedAmount = order.redeemers!.length
      }
    }
    return `${redeemedAmount}/${giftCardAmount}`
  }

  return (
    <>
      {!order ? null : (
        <>
          <AppBar
            maxWidth="sm"
            text="Takaisin"
            mainAction={{
              icon: 'ArrowBack',
              onClick: () => history.goBack()
            }}
            border
          />
          <Container>
            <div
              style={{
                marginBottom: order.redeemExpirationDate ? 200 : 120
              }}
            >
              {activeTab === 'OrderDetailsTab' ? (
                <>
                  <Heading
                    align="left"
                    title={
                      order.uniqoreFriendlyId
                        ? `Tilaus #${order.uniqoreFriendlyId}`
                        : `Tilaus #${order.orderId}`
                    }
                    body={
                      order.redeemExpirationDate
                        ? `Viimeinen lunastusajankohta ${order.redeemExpirationDate}`
                        : ''
                    }
                  />
                  <ListItem
                    text="Yhteenveto tilattavista palveluista"
                    subtitles={getProductSummaryDetails(order)}
                  />
                  <ListItem
                    text="Kustannus- ja tukijakauma"
                    subtitles={
                      order.cartTotals
                        ? [
                            `Kustannukset (alv 0%) ${round(
                              order.cartTotals.totalNoTaxes
                            )}`,
                            `Verot (14 %) ${round(
                              order.cartTotals.productTaxes
                            )}`,
                            order.cartTotals.shippingTaxes === 0
                              ? ''
                              : `Verot (25,5 %) ${round(
                                  order.cartTotals.shippingTaxes
                                )}`,
                            `Kustannukset yhteensä ${round(
                              order.cartTotals.totalWithTaxes
                            )}`,
                            `Tuen osuus ${round(order.cartTotals.totalAid)}`
                          ]
                        : []
                    }
                  />
                  <ListItem
                    text="Yhteyshenkilön tiedot"
                    subtitles={[
                      `${order.personContact.firstName} ${order.personContact.lastName}`,
                      order.personContact.phone,
                      order.personContact.email,
                      order.personContact.mailingList
                        ? 'Liityn Vennerin yrityspalveluiden postituslistalle'
                        : ''
                    ]}
                  />
                  <ListItem
                    text="Yrityksen yhteystiedot"
                    subtitles={[
                      order.companyContact.companyName,
                      order.companyContact.address,
                      `${order.companyContact.zip} ${order.companyContact.city}`
                    ]}
                  />
                  {!order.companyContact.deliveryAddress && (
                    <ListItem
                      text="Toimitusosoite"
                      subtitles={[
                        order.deliveryContact.companyName,
                        order.deliveryContact.address,
                        `${order.deliveryContact.zip} ${order.deliveryContact.city}`
                      ]}
                    />
                  )}
                  {order.orderType === 'Cargo' && (
                    <ListItem
                      text="Toimituspäivä toimipisteelle"
                      subtitles={
                        order.deliveryDate
                          ? [
                              `${format(
                                parseISO(order.deliveryDate),
                                'dd.MM.yyyy'
                              )} klo 8-16`
                            ]
                          : ['Toimitus sovitaan erikseen']
                      }
                    />
                  )}
                  <ListItem
                    text="Sivustonäkyvyys"
                    subtitles={getSupportText(order)}
                  />
                  <ListItem
                    text="Laskutustiedot"
                    subtitles={[
                      !order.billing.eInvoice
                        ? `Lasku sähköpostiin ${order.billing.email}`
                        : `Verkkolasku ${order.billing.ovtId} / ${order.billing.operatorId}`,
                      order.billing.additionalInfoActive
                        ? `Lisätiedot: "${order.billing.additionalInfo}"`
                        : ''
                    ]}
                  />
                </>
              ) : (
                <>
                  <Heading
                    align="left"
                    title={`Lunastetut (${getRedeemersAmount()}) kpl`}
                  />
                  {order.redeemers &&
                    order.redeemers.map((redeemer: Redeemer, i: number) => (
                      <ListItem
                        key={i}
                        dense
                        text={`${redeemer.firstName} ${redeemer.lastName}`}
                        subtitles={[redeemer.email]}
                        actions={[{ icon: 'CheckCircle', color: '#96BEB4' }]}
                      />
                    ))}
                </>
              )}
            </div>
            <BottomBar
              style={{
                position: 'absolute',
                bottom: order.redeemExpirationDate ? 90 : 30,
                width: '100%'
              }}
              maxWidth="sm"
              components={[
                <Button
                  border
                  variant="default"
                  disablePadding
                  fullWidth
                  label="Tilausnäkymään"
                  onClick={goToOrderStatusView}
                />
              ]}
            />
          </Container>
          {order.redeemExpirationDate && (
            <BottomNavigation
              maxWidth="sm"
              style={{ position: 'absolute', bottom: 33, width: '100%' }}
              color="primary"
              actions={[
                {
                  label: 'Tilaustietoni',
                  value: 'OrderDetailsTab',
                  icon: 'ListAlt'
                },
                {
                  label: 'Lahjakortit',
                  value: 'CodesTab',
                  icon: 'SupervisorAccount'
                }
              ]}
              value={activeTab}
              onChange={(_event, newValue) => {
                setActiveTab(newValue)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default SingleOrderView
