import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppBar, Container, Heading, ListItem } from 'uniqore-components'
import { changeRedeem } from 'features/redeem'
import { Product } from 'types/types'
import { AppState } from 'store'
import { setSeasonBoxTexts } from 'components/Order/OrderBoxType'

const RedeemProduct: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { code, isDynamicProduct, dynamicProduct } = useSelector(
    (state: AppState) => state.redeem
  )
  const { products } = useSelector((state: AppState) => state.airtableData)
  const { fromSummary } = useSelector((state: AppState) => state.utils)
  const [boxes, setBoxes] = useState<Product[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 1, stateKey: 'currentView' }))
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Set Sesonkiruokalaatikot if this engagement has no dynamic product
    if (!isDynamicProduct) {
      products.forEach((product: Product) => {
        if (Object.prototype.hasOwnProperty.call(product, 'types')) {
          setBoxes(product.types!)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDynamicProduct, products])

  const goBack = () => {
    if (fromSummary) {
      dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
      history.push('/redeem-summary')
    } else {
      dispatch(changeRedeem({ value: 0, stateKey: 'currentView' }))
      history.push('/redeem-welcome')
    }
  }

  const handleSelect = (product: Product) => {
    dispatch(
      changeRedeem({
        value: setSeasonBoxTexts(product.text),
        stateKey: 'boxTypeForEmail'
      })
    )
    dispatch(changeRedeem({ value: product.text, stateKey: 'boxType' }))
    dispatch(changeRedeem({ value: product.id, stateKey: 'redeemedProduct' }))
    dispatch(changeRedeem({ value: product.sku, stateKey: 'productSku' }))
    dispatch(
      changeRedeem({ value: product.gtin as string, stateKey: 'productGtin' })
    )
    if (fromSummary) {
      dispatch(changeRedeem({ value: 5, stateKey: 'currentView' }))
      history.push('/redeem-summary')
    } else {
      dispatch(changeRedeem({ value: 2, stateKey: 'currentView' }))
      history.push('/redeem-contact')
    }
  }

  return (
    <Container>
      <AppBar
        mainAction={{
          icon: 'ArrowBack',
          onClick: goBack
        }}
        text={fromSummary ? 'Yhteenveto' : 'Takaisin'}
      />
      <Heading
        title={
          isDynamicProduct
            ? 'Valitse lunastettava tuote'
            : 'Valitse ruokalaatikon sisältö'
        }
        body={
          isDynamicProduct
            ? ''
            : 'Sesonkiruokalaatikot ovat laktoosittomia, kasvispohjaisia ja gluteenittomia.'
        }
      />
      {!isDynamicProduct ? (
        boxes
          .slice()
          .sort((a, b) => a.showOrder! - b.showOrder!)
          .map((product: Product, i: number) => (
            <ListItem
              key={i}
              image={product.image}
              text={setSeasonBoxTexts(product.text)}
              subtitles={['Kotiintoimitus']}
              onClick={() => handleSelect(product)}
              metaIcon="ArrowForward"
              hightlightMeta
            />
          ))
      ) : dynamicProduct ? (
        <ListItem
          image={dynamicProduct.image}
          text={dynamicProduct.text}
          subtitles={[
            ...dynamicProduct.subtitles,
            dynamicProduct.type === 'Home'
              ? 'Kotiintoimitus'
              : dynamicProduct.type === 'Pickup'
              ? 'Toimitus noutopisteelle'
              : ''
          ]}
          onClick={() => handleSelect(dynamicProduct)}
          metaIcon="ArrowForward"
          hightlightMeta
        />
      ) : null}
    </Container>
  )
}

export default RedeemProduct
