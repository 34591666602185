import React, { useState, useEffect } from 'react'
import xss from 'xss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Container,
  Heading,
  ListItem,
  SmallPrint,
  Button
} from 'uniqore-components'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import { changeOrder, updateProducts } from 'features/order'
import { changeUtils } from 'features/utils'
import { round } from 'utils/common'
import {
  Product,
  DeliveryProduct,
  DeliveryProducts,
  DynamicDeliveryTypes,
  Shipping
} from 'types/types'
import { AppState } from 'store'

const getGiftCardPrice = (
  products: Product[],
  shippingGiftcard: Shipping
): string => {
  let price = '66€'
  for (const product of products) {
    if (product.type === 'Giftcard') {
      price = `${product.price + shippingGiftcard.price}€`
      break
    }
  }
  return price
}

const getCargoPrice = (
  products: Product[],
  shippingCargo: Shipping
): string => {
  let price = '64€'
  for (const product of products) {
    if (product.type === 'Cargo') {
      price = `${product.price + shippingCargo.price}€`
      break
    }
  }
  return price
}

const getCharityPrice = (products: Product[]): string => {
  let price = '90€'
  for (const product of products) {
    if (product.type === 'Charity') {
      price = `${product.priceWithTaxes}€`
      break
    }
  }
  return price
}

const getSupportAmount = (products: Product[]): string => {
  let price = '6€'
  for (const product of products) {
    if (product.type === 'Cargo' || product.type === 'Giftcard') {
      price = `${product.support}€`
      break
    }
  }
  return price
}

const createHeadingAndSmallPrintTextForDynamicProduct = (
  deliveryProducts?: DeliveryProducts
) => {
  let headingText: string = ''
  let smallPrint: string = ''
  if (deliveryProducts) {
    if (Object.keys(deliveryProducts).length === 1) {
      if (Object.keys(deliveryProducts)[0] === 'Cargo') {
        headingText = 'Voit tilata tuotteita toimitettavaksi toimipisteeseen.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset toimipisteeseen ${round(
          deliveryProducts['Cargo'].price
        )} per toimitus (alv 0%)`
      }
      if (Object.keys(deliveryProducts)[0] === 'Home') {
        headingText = 'Voit tilata tuotteita lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset kotiintoimitukseen ${round(
          deliveryProducts['Home'].price
        )} per toimitus (alv 0%)`
      }
      if (Object.keys(deliveryProducts)[0] === 'Pickup') {
        headingText = 'Voit tilata tuotteita lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset noutopisteeseen ${round(
          deliveryProducts['Pickup'].price
        )} per toimitus (alv 0%)`
      }
    } else {
      let cargo = {
        found: false,
        index: 0
      }
      let home = {
        found: false,
        index: 0
      }
      let pickup = {
        found: false,
        index: 0
      }
      Object.keys(deliveryProducts).forEach((type, index) => {
        if (type === 'Cargo') {
          cargo.found = true
          cargo.index = index
        }
        if (type === 'Home') {
          home.found = true
          home.index = index
        }
        if (type === 'Pickup') {
          pickup.found = true
          pickup.index = index
        }
      })
      if (cargo.found && home.found && !pickup.found) {
        headingText =
          'Voit tilata tuotteita toimitettavaksi toimipisteeseen tai lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset toimipisteeseen ${round(
          deliveryProducts['Cargo'].price
        )} tai kotiintoimituksen ${round(
          deliveryProducts['Home'].price
        )} per toimitus (alv 0%)`
      }
      if (cargo.found && pickup.found && !home.found) {
        headingText =
          'Voit tilata tuotteita toimitettavaksi toimipisteeseen tai lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset toimipisteeseen ${round(
          deliveryProducts['Cargo'].price
        )} tai noutopisteeseen ${round(
          deliveryProducts['Pickup'].price
        )} per toimitus (alv 0%)`
      }
      if (home.found && pickup.found) {
        headingText = 'Voit tilata tuotteita lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset kotiintoimitukseen ${round(
          deliveryProducts['Home'].price
        )} tai noutopisteeseen ${round(
          deliveryProducts['Pickup'].price
        )} per toimitus (alv 0%)`
      }
      if (cargo.found && home.found && pickup.found) {
        headingText =
          'Voit tilata tuotteita toimitettavaksi toimipisteeseen tai lunastettavaksi koodilla.'
        smallPrint = `Tuotteet sisältävät toimituskustannukset toimipisteeseen ${round(
          deliveryProducts['Cargo'].price
        )}, kotiintoimituksen ${round(
          deliveryProducts['Home'].price
        )} tai noutopisteeseen ${round(
          deliveryProducts['Pickup'].price
        )} per toimitus (alv 0%)`
      }
    }
  }
  return { headingText, smallPrint }
}

const OrderDeliveryMethod: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const order = useSelector((state: AppState) => state.order)
  const { products, shippingGiftcard, shippingCargo, boxMinimumOrder } =
    useSelector((state: AppState) => state.airtableData)
  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeUtils({ value: 2, stateKey: 'currentView' }))
    // Go back if no Delivery Products added to dynamic product in Airtable
    if (order.isDynamicProduct) {
      if (order.products[0].deliveryProducts) {
        if (Object.keys(order.products[0].deliveryProducts).length === 0) {
          goBack()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    dispatch(changeUtils({ value: 1, stateKey: 'currentView' }))
    history.push('/order')
  }

  const goForward = () => {
    dispatch(changeUtils({ value: 3, stateKey: 'currentView' }))
    history.push('/order-cart')
  }

  const handleSelectSeasonBox = (
    deliveryType: 'Giftcard' | 'Cargo' | 'Charity'
  ) => {
    dispatch(changeOrder({ value: deliveryType, stateKey: 'orderType' }))
    let setProducts = JSON.parse(
      JSON.stringify(products.filter((p) => !p.isDynamic))
    )
    if (deliveryType === 'Giftcard') {
      setProducts = setProducts.filter((v: Product) => v.type !== 'Cargo')
    }
    if (deliveryType === 'Cargo') {
      setProducts = setProducts.filter((v: Product) => v.type !== 'Giftcard')
    }
    if (deliveryType === 'Charity') {
      setProducts = setProducts.filter((v: Product) => v.type === 'Charity')
    }
    // Update products array in order-reducer
    dispatch(updateProducts({ products: setProducts }))
    goForward()
  }

  const getSubtitlesSeasonBox = (product: Product): string[] => {
    const subtitles: string[] = []
    if (product.type === 'Cargo') {
      subtitles.push(`Minimitilausmäärä ${boxMinimumOrder} kpl`)
    }
    if (product.subtitles[0]) {
      subtitles.push(product.subtitles[0])
    }
    subtitles.push(
      product.type === 'Charity'
        ? `${round(product.price)} (alv 0%)`
        : product.type === 'Cargo'
        ? `${round(product.price + shippingCargo.price)} (alv 0%)`
        : `${round(product.price + shippingGiftcard.price)} (alv 0%)`
    )
    return subtitles
  }

  const handleSelectDynamicProduct = (
    product: Product,
    deliveryType: DynamicDeliveryTypes
  ) => {
    const findProduct = products.find((p) => p.sku === product.sku)
    if (findProduct) {
      // Set orderType for order-reducer,
      // must be either 'Cargo' or 'Giftcard' if dynamic product selected
      let orderType = 'Cargo'
      if (deliveryType === 'Home' || deliveryType === 'Pickup') {
        orderType = 'Giftcard'
      }
      dispatch(changeOrder({ value: orderType, stateKey: 'orderType' }))
      // Update products array in order-reducer
      const updateProduct: Product = JSON.parse(JSON.stringify(findProduct))
      updateProduct.type = deliveryType
      dispatch(updateProducts({ products: [updateProduct] }))
      goForward()
    }
  }

  const getSubtitlesDynamicProduct = (
    product: Product,
    delivery: DeliveryProduct
  ): string[] => {
    const subtitles: string[] = []
    if (delivery.type === 'Home' || delivery.type === 'Pickup') {
      subtitles.push('Lunastaminen lahjakoodilla')
    }
    if (product.minQuantity) {
      subtitles.push(`Minimitilausmäärä ${product.minQuantity} kpl`)
    }
    subtitles.push(`${round(product.price + delivery.price)} (alv 0%)`)
    return subtitles
  }

  return (
    <>
      <Container>
        <AppBar
          text="Takaisin"
          mainAction={{ icon: 'ArrowBack', onClick: goBack }}
          actions={
            order.isDynamicProduct
              ? order.products[0].longText?.trim()
                ? [
                    {
                      icon: 'HelpOutline',
                      onClick: () => setHelpDialogOpen(true)
                    }
                  ]
                : []
              : [
                  {
                    icon: 'HelpOutline',
                    onClick: () => setHelpDialogOpen(true)
                  }
                ]
          }
        />
        <Heading
          title="Valitse toimitustapa"
          body={
            order.isDynamicProduct
              ? createHeadingAndSmallPrintTextForDynamicProduct(
                  order.products[0].deliveryProducts
                ).headingText
              : 'Voit tilata Sesonkiruokalaatikoita tai auttaa vähävaraista perhettä.'
          }
        />
        {!order.isDynamicProduct
          ? products
              .filter((product: Product) => !product.isDynamic)
              .map((product: Product) => (
                <ListItem
                  key={product.id}
                  image={product.image}
                  text={product.text}
                  subtitles={getSubtitlesSeasonBox(product)}
                  onClick={() =>
                    handleSelectSeasonBox(
                      product.type as 'Giftcard' | 'Cargo' | 'Charity'
                    )
                  }
                  metaIcon="ArrowForward"
                  hightlightMeta
                  dense
                />
              ))
          : order.products[0].deliveryProducts
          ? Object.values(order.products[0].deliveryProducts).map(
              (deliveryProduct: DeliveryProduct) => (
                <ListItem
                  key={deliveryProduct.id}
                  image={order.products[0].image}
                  text={
                    deliveryProduct.type === 'Home'
                      ? 'Kotiintoimitus yksittäin'
                      : deliveryProduct.type === 'Pickup'
                      ? 'Toimitus noutopisteeseen yksittäin'
                      : 'Kertatoimitus toimipisteeseen'
                  }
                  subtitles={getSubtitlesDynamicProduct(
                    order.products[0],
                    deliveryProduct
                  )}
                  onClick={() =>
                    handleSelectDynamicProduct(
                      order.products[0],
                      deliveryProduct.type
                    )
                  }
                  metaIcon="ArrowForward"
                  hightlightMeta
                  dense
                />
              )
            )
          : null}
        <SmallPrint
          text={
            !order.isDynamicProduct
              ? `Tuotteet sisältävät toimituskustannukset toimituspisteeseen ${round(
                  shippingCargo.price
                )} tai kotiintoimituksen ${round(
                  shippingGiftcard.price
                )} per toimitus (alv 0%).`
              : createHeadingAndSmallPrintTextForDynamicProduct(
                  order.products[0].deliveryProducts
                ).smallPrint
          }
        />
      </Container>
      <Dialog
        onClose={() => setHelpDialogOpen(false)}
        aria-labelledby="dialog-title"
        open={helpDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent id="dialog-title">
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: xss(
                order.isDynamicProduct
                  ? (order.products[0].longText as string) || ''
                  : `<b>Sesonkiruokalaatikko ja kokkikurssi</b> raaka-aineineen ja resepteineen – inspiroidu ja opi kokkaamaan ravitsevaa, kauden mukaista ruokaa - samalla auttaen vähävaraisia perheitä. Yksi Venner-sesonkiruokalaatikko (toimitustavasta riippuen ${getCargoPrice(
                      products,
                      shippingCargo
                    )} - ${getGiftCardPrice(
                      products,
                      shippingGiftcard
                    )} + alv) sisältää reseptit ja raaka-aineet 3 ateriaan 4 henkilölle (pienemmille talouksille löytyy left over reseptit, joten kaikki raaka-aineet hyödynnetään). Jokaisesta tilatusta Venner-sesonkiruokalaatikosta tilitämme ${getSupportAmount(
                      products
                    )} vähävaraisen perheen Venner-ruokalaatikoihin.<br /><br />Sesonkiruokalaatikon tilaus etenee järjestelmässämme sujuvasti. Voit valita <b>kertatoimituksen toimipisteelle</b>, tai <b>hankkia lahjakoodeja</b> Sesonkiruokalaatikon kotiin tilaamiseksi. Lahjakoodin jaettuasi sesonkiruokalaatikon saaja pääsee järjestelmään lunastamaan koodinsa sekä valitsemaan Sesonkiruokalaatikkonsa ruokarajoitteidensa perusteella sekä valitsemaan sille kotiintoimitusajan Suomen alueella. Lahjakoodin hintaan sisältyy yksi toimituskerta, ja mikäli tilaus joudutaan toimittamaan uudelleen asiakkaasta johtuvasta syystä, Venner laskuttaa ylimääräisen toimituskulun tilaajalta.<br /><br />Jos haluat tilata <b>Sesonkiruokalaatikoita eri toimipisteille</b>, niin muodosta aluksi yhdellä toimitusalueella koko tilaus kasaan. Tilaamisen jälkeen laskeudut vahvistussivulle, josta pääset tekemään uuden tilauksen ("Tee uusi tilaus"). Uuden tilauksen yhteydessä voit valita esitäytetyt tiedot tai luoda tilauksen uusilla tiedoilla.<br /><br /><b>Venner-ruokalaatikko</b> on puolestaan vähävaraisille perheille kotiin toimitettava ruokalaatikko, joka sisältää jopa 42 ateria-annosta ja uusia oppeja! Tämä voimauttava ruokakonsepti koostuu 7 pääaterian raaka-aineista jopa 6 henkilölle, ja sisältää myös ainekset 2 välipalaan, 2-3kg hedelmiä, Sana-sol D-vitamiinipurkin, oliiviöljyn, kotiin toimituksen, reseptit, ja pääsyn Vennerin Keittiössä -palveluun. Tutkitusti vaikuttava Venner-ruokakonsepti yhdelle perheelle maksaa ${getCharityPrice(
                      products
                    )} (sis. alv 14%). Jokaista ostettua Venner-ruokalaatikkoa kohden auttaja saa myös pääsyn Vennerin Keittiössä-palveluun. Nämä lähetetään yhteyshenkilölle sähköpostitse.`
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="Asia selvä"
            color="primary"
            disablePadding
            onClick={() => setHelpDialogOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrderDeliveryMethod
