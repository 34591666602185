import { createSlice } from '@reduxjs/toolkit'
import { Product } from 'types/types'
import { RedeemContactDetails, RedeemDeliveryDetails } from 'features/redeem'

export interface CampaignRedeemDeliveryDetails extends RedeemDeliveryDetails {
  country: string
}

export interface CampaignRedeem {
  redemptionCode: Date | string
  engagementId: string
  redeemId: string
  boxType: string
  redeemedProduct: string
  productGtin: string
  productSku: string
  contactDetails: RedeemContactDetails
  deliveryDetails: CampaignRedeemDeliveryDetails
  deliveryDate: string
  deliveryDateForEmail: string
  delivery: {
    method: string
    startTime: string
    endTime: string
    schenkerDeliveryDate: string
  }
  codeRedeemed: Date | string
  forCharity: boolean
  currentView: number
  deliveryValidTo?: string
  deliveryValidToSeasonBox?: string
  daysAheadBuffer?: number
  daysAheadBufferSeasonBox?: number
  redeemSelectableWeeksCount?: number
  foreignDeliveryDate: string
  language: string
  isDynamicProduct: boolean
  products: Product[]
  redemptionEmail: string
  selectedCountry: 'Suomi' | 'Viro' | 'Ei valintaa'
  selectedProduct: string
  selectedProductType: string
  showCharity: boolean
}

interface ChangeRedeemAction {
  payload: {
    value: number | string | boolean | Product[]
    stateKey: keyof CampaignRedeem
    stateKeyExtra?: string
  }
}

const initialState: CampaignRedeem = {
  redemptionCode: '',
  engagementId: '',
  redeemId: '',
  boxType: '',
  redeemedProduct: '',
  productGtin: '',
  productSku: '',
  contactDetails: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    mailingList: false
  },
  deliveryDetails: {
    recipient: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    notes: ''
  },
  deliveryDate: '',
  deliveryDateForEmail: '',
  delivery: {
    method: '',
    startTime: '',
    endTime: '',
    schenkerDeliveryDate: ''
  },
  codeRedeemed: '',
  isDynamicProduct: false,
  forCharity: false,
  currentView: 0,
  foreignDeliveryDate: '',
  language: '',
  products: [],
  redemptionEmail: '',
  selectedCountry: 'Suomi',
  selectedProduct: '',
  selectedProductType: '',
  showCharity: false
}

const campaignRedeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    changeCampaignRedeem: (state, { payload }: ChangeRedeemAction) => {
      if (payload.stateKeyExtra) {
        // @ts-ignore
        state[payload.stateKey][payload.stateKeyExtra] = payload.value
      } else {
        // @ts-ignore
        state[payload.stateKey] = payload.value
      }
    }
  }
})

export const { changeCampaignRedeem } = campaignRedeemSlice.actions
export default campaignRedeemSlice.reducer
