// @ts-ignore
import React from 'react'
import ReactDOM from 'react-dom'
import PublicClient from 'api/publicClient'
import './App.css'
import App from './App'
import theme from './theme/theme'
import store from 'store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={PublicClient}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
