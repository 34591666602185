import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core'
import {
  AppBar,
  Button,
  FeedTitle,
  StatusHero,
  SpeedDial,
  FeedCard
} from 'uniqore-components'
import VennerLogo from 'assets/venner-logo.svg'
import { changeUtils } from 'features/utils'
import { getTime } from 'utils/common'
import { changeRedeem } from 'features/redeem'
import { AppState } from 'store'

const RedeemConfirm: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const {
    deliveryDate,
    delivery,
    code,
    contactDetails,
    isDynamicProduct,
    dynamicProduct
  } = useSelector((state: AppState) => state.redeem)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeRedeem({ value: 0, stateKey: 'currentView' }))
    dispatch(changeRedeem({ value: '', stateKey: 'code' }))
    dispatch(
      changeUtils({
        value: contactDetails.firstName,
        stateKey: 'personContactFirstName'
      })
    )
    dispatch(
      changeUtils({
        value: contactDetails.lastName,
        stateKey: 'personContactLastName'
      })
    )
    dispatch(
      changeUtils({
        value: contactDetails.phone,
        stateKey: 'personContactPhone'
      })
    )
    dispatch(
      changeUtils({
        value: contactDetails.email,
        stateKey: 'personContactEmail'
      })
    )
    if (!code) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImage = () => {
    let result = undefined
    if (isDynamicProduct && dynamicProduct) {
      if (!dynamicProduct.videoUrl) {
        result = dynamicProduct.picture
      }
    }
    return result
  }

  return (
    <div>
      <AppBar
        maxWidth="sm"
        backgroundColor="#CEE3DF"
        logo={VennerLogo}
        logoSize={60}
      />
      <StatusHero
        maxWidth="sm"
        backgroundColor="#CEE3DF"
        color="#525252"
        image={getImage()}
        video={
          isDynamicProduct && dynamicProduct
            ? dynamicProduct.videoUrl
            : 'https://player.vimeo.com/video/556264772'
        }
        headline={
          deliveryDate === 'Olemme sinuun yhteydessä'
            ? 'Kiitos lunastuksesta!'
            : deliveryDate
        }
        subtitle="Tilaus vahvistettu ja vahvistusviesti lähetetty antamaasi sähköpostiosoitteeseen."
        actionComponent={
          isDynamicProduct && dynamicProduct && dynamicProduct.recipeUrl ? (
            <Button
              role="status"
              icon="ArrowForward"
              label="Tutustu tuotteeseen"
              onClick={() => {
                window.open(dynamicProduct.recipeUrl)
              }}
            />
          ) : (
            <Button
              role="status"
              icon="ArrowForward"
              label="Tutustu resepteihin"
              onClick={() => {
                window.open(
                  'https://app.seidat.com/presentation/shared/zAFTYFiWCWTFpnELL/0/0'
                )
              }}
            />
          )
        }
      />
      <Button
        style={{ textAlign: 'center' }}
        label="Kerro ystävällesi"
        underline
        variant="default"
        icon="Share"
        onClick={() =>
          dispatch(changeUtils({ value: true, stateKey: 'showShare' }))
        }
      />
      <div style={{ marginBottom: '120px' }}>
        <FeedTitle text="Viimeiset tapahtumat" />
        <FeedCard
          avatarBackgroundColor={theme.palette.primary.main}
          avatarText=""
          user="VENNER OY"
          date={`Tänään ${getTime()}`}
          subtitle="Tilaus vahvistettu"
          htmlText={
            deliveryDate === 'Olemme sinuun yhteydessä'
              ? 'Olemme sinuun yhteydessä toimitukseen liittyen. Tilausvahvistus on lähetetty sähköpostiisi.'
              : isDynamicProduct && dynamicProduct
              ? `Tilauksesi toimitetaan <b>${
                  delivery.method === 'Matkahuolto'
                    ? deliveryDate.split('klo')[0].trim()
                    : deliveryDate
                }</b>${
                  dynamicProduct.type === 'Pickup'
                    ? ' lähimpään noutopisteeseen'
                    : ''
                }. ${
                  delivery.method === 'Matkahuolto'
                    ? 'Tulet saamaan Matkahuollolta tekstiviestin joko valitsemanasi toimituspäivänä, tai viimeistään sitä seuraavana päivänä.'
                    : ''
                } Tilausvahvistus on lähetetty sähköpostiisi.`
              : `Tilauksesi toimitetaan <b>${
                  delivery.method === 'Matkahuolto'
                    ? deliveryDate.split('klo')[0].trim()
                    : deliveryDate
                }</b>. ${
                  delivery.method === 'Matkahuolto'
                    ? 'Tulet saamaan Matkahuollolta tekstiviestin joko valitsemanasi toimituspäivänä, tai viimeistään sitä seuraavana päivänä.'
                    : ''
                } Tilausvahvistus on lähetetty sähköpostiisi.`
          }
        />
        <FeedCard
          avatarBackgroundColor={theme.palette.secondary.main}
          avatarImage=""
          user="Sinä"
          date={`Tänään ${getTime()}`}
          subtitle="Uusi tilaus"
          htmlText={
            isDynamicProduct && dynamicProduct
              ? `Tilasit tuotteen <b>${
                  dynamicProduct.text
                }</b>, jonka myötä mahdollistit <b>${
                  dynamicProduct.support / 2
                } ravitsevaa ruoka-annosta</b> apua tarvitseville perheille.`
              : 'Tilasit <b>Venner Sesonkiruokalaatikon</b>, jonka myötä mahdollistit <b>kolme ravitsevaa ruoka-annosta</b> apua tarvitseville perheille.'
          }
        />
      </div>
      <SpeedDial
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        actions={[
          {
            icon: 'ArrowForward',
            label:
              isDynamicProduct && dynamicProduct && dynamicProduct.recipeUrl
                ? 'Tutustu tuotteeseen'
                : 'Tutustu resepteihin',
            onClick: () => {
              window.open(
                isDynamicProduct && dynamicProduct && dynamicProduct.recipeUrl
                  ? dynamicProduct.recipeUrl
                  : 'https://app.seidat.com/presentation/shared/zAFTYFiWCWTFpnELL/0/0'
              )
            }
          },
          {
            icon: 'Send',
            label: 'Ota yhteyttä',
            onClick: () =>
              dispatch(
                changeUtils({ value: true, stateKey: 'showFeedbackDialog' })
              )
          },
          {
            icon: 'Share',
            label: 'Kerro ystävälle',
            onClick: () =>
              dispatch(changeUtils({ value: true, stateKey: 'showShare' }))
          }
        ]}
      />
    </div>
  )
}

export default RedeemConfirm
